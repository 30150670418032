import React from "react";
import {
  Modal,
  Box,
  Typography,
  IconButton,
  useTheme,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import cx from "classnames";

const StyledModal = styled(Modal)(({ theme, bodyTransparent }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "& .MuiBox-root": {
    backgroundColor: bodyTransparent
      ? "transparent"
      : theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    position: "relative",
    width: "100%",
    maxWidth: "600px", // You can adjust the maxWidth as needed
  },
}));

const CustomModal = ({
  hasClose = true,
  hasHeader = true,
  header = "",
  isOpen,
  handleClose,
  bgColor = "white",
  backdrop = true,
  customStyle = "",
  size = "md",
  customClassName = "",
  bodyTransparent = false,
  children,
}) => {
  const theme = useTheme();

  return (
    <StyledModal
      open={isOpen}
      onClose={backdrop === "static" ? () => {} : handleClose}
      aria-labelledby="custom-modal-title"
      aria-describedby="custom-modal-description"
      bodyTransparent={bodyTransparent}
    >
      <Box
        className={cx(customClassName, customStyle)}
        sx={{ width: size === "" ? "md" : size, backgroundColor: bgColor }}
      >
        {hasClose && (
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
        {hasHeader && (
          <Box
            sx={{
              borderBottom: `1px solid ${theme.palette.divider}`,
              paddingBottom: theme.spacing(2),
              marginBottom: theme.spacing(2),
              backgroundColor: bgColor,
            }}
          >
            <Typography variant="h6" id="custom-modal-title">
              {header}
            </Typography>
          </Box>
        )}
        <Box>{children}</Box>
      </Box>
    </StyledModal>
  );
};

export default CustomModal;
