import React from "react";
import { Box, Typography, List, ListItem, ListItemText } from "@mui/material";

const OrderLists = ({
  guestName,
  statusColor,
  table,
  partySize,
  selectedFloor,
  bookingTime,
  modalOrderListProducts,
  getSum,
}) => {
  return (
    <Box width="300px" p={2} className="pos-modal-orderList bg-grayDark">
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h6">{guestName}</Typography>
        <Typography
          variant="h6"
          style={{
            color: statusColor,
            border: "1px solid " + statusColor,
            padding: "2px 8px",
          }}
        >
          {table.name}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="body1">{`${partySize} guests, ${selectedFloor.floor_name}`}</Typography>
        <Typography variant="body2">{bookingTime}</Typography>
      </Box>
      <Box sx={{ height: "500px", overflow: "auto" }}>
        <List>
          {modalOrderListProducts.length &&
            modalOrderListProducts.map((product, index) => (
              <ListItem key={index} sx={{ padding: 0, marginTop: "16px" }}>
                <ListItemText
                  sx={{ margin: 0 }}
                  primary={
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography className="text-sm">
                        {product.title}
                      </Typography>
                      <Typography className="text-sm">
                        {product.quantity > 1 ? `${product.quantity}x` : ""}
                        {product.price > 0
                          ? ` $${(product.price * product.quantity).toFixed(2)}`
                          : ""}
                      </Typography>
                    </Box>
                  }
                  secondary={
                    <>
                      {product?.addOns?.length ? (
                        <div>
                          {product.addOns.map((addOn, idx) => (
                            <div
                              key={idx}
                              style={{
                                paddingLeft: "8px",
                                marginTop: "4px",
                                color: "#fff",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                              className="text-sm"
                            >
                              <Box>
                                <span
                                  style={{
                                    color: "#bfbfbf",
                                  }}
                                >
                                  {addOn.name}
                                </span>
                              </Box>
                              <Box sx={{ display: "flex" }}>
                                <span>
                                  {addOn.quantity > 1 && `${addOn.quantity}x`}
                                </span>
                                <span
                                  style={{
                                    width: "56px",
                                    textAlign: "right",
                                  }}
                                >
                                  {addOn.price !== 0 &&
                                    `$${(addOn.price * addOn.quantity).toFixed(
                                      2
                                    )}`}
                                </span>
                              </Box>
                            </div>
                          ))}
                        </div>
                      ) : null}
                    </>
                  }
                />
              </ListItem>
            ))}
        </List>
      </Box>
      {modalOrderListProducts.length > 0 && (
        <Typography>Total: ${getSum().toFixed(2)}</Typography>
      )}
    </Box>
  );
};

export default OrderLists;
