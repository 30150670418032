import React, { useEffect, useState, useCallback } from "react";
import AddEmployeeModal from "./AddEmployeeModal/AddEmployeeModal";
import EmployeeDetailModal from "./EmployeeDetailModal";
import ProfileAvatar from "../common/ProfileAvatar";
import { Box, Typography, Grid, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch } from "react-redux";
import { getEmployeesRequest } from "../../../../actions/employeeActions.js";
import "../../styles/style.scss";
import colors from "../StyledComponents/ColorStyled.jsx";

const Team = () => {
  const dispatch = useDispatch();
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editEmployee, setEditEmployee] = useState(null);

  const fetchEmployees = useCallback(async () => {
    setLoading(true);
    setError(null);
    dispatch(
      getEmployeesRequest((err, data) => {
        if (err) {
          setError(err);
        } else {
          setEmployees(data);
        }
        setLoading(false);
      })
    );
  }, [dispatch]);

  const handleAddEmployee = useCallback(async () => {
    try {
      await fetchEmployees();
    } catch (err) {
      setError(err.message);
    }
  }, [fetchEmployees]);

  const handleOpenAddEmployeeModal = () => {
    setEditEmployee(null);
    setEditModalOpen(true);
  };

  const handleEmployeeClick = (employee) => {
    setSelectedEmployee(employee);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedEmployee(null);
    setModalOpen(false);
  };

  const handleEditEmployee = (employee) => {
    setEditEmployee(employee);
    setEditModalOpen(true);
    setModalOpen(false);
  };

  const handleCloseEditModal = () => {
    setEditEmployee(null);
    setEditModalOpen(false);
  };

  useEffect(() => {
    fetchEmployees();
  }, [fetchEmployees]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <>
      <Box display="flex" justifyContent="flex-end" alignItems="center" mb={2}>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          className="bg-primary neo-outer-glow"
          style={{
            textTransform: "none",
            fontWeight: "bold",
            borderRadius: "20px",
          }}
          onClick={handleOpenAddEmployeeModal}
        >
          Add Employee
        </Button>
      </Box>
      <Grid container spacing={2}>
        {employees.length > 0 ? (
          employees.map((employee) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              key={employee.id}
              onClick={() => handleEmployeeClick(employee)}
            >
              <Box>
                <Box
                  className="bg-primary neo-outer-glow"
                  p={2}
                  borderRadius={2}
                  textAlign="center"
                  color={colors.white}
                >
                  <ProfileAvatar
                    profilePicUrl={employee.photo}
                    firstName={employee.first_name}
                    lastName={employee.last_name}
                    color={employee.color}
                  />
                  <Typography
                    variant="subtitle1"
                    mt={1}
                    style={{
                      color: colors.white,
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    {employee.first_name} {employee.last_name}
                  </Typography>
                </Box>
                <Box
                  className="neo-outer-glow"
                  bgcolor={employee.color}
                  p={1}
                  mt={0.8}
                  borderRadius={2}
                  textAlign="center"
                >
                  <Typography
                    variant="h6"
                    style={{
                      color: colors.primary,
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    {employee.role}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          ))
        ) : (
          <Typography>No employees found</Typography>
        )}
      </Grid>
      <AddEmployeeModal
        onAddEmployee={handleAddEmployee}
        employee={editEmployee}
        open={editModalOpen}
        onClose={handleCloseEditModal}
        fetchEmployees={fetchEmployees}
        employees={employees}
      />
      <EmployeeDetailModal
        employee={selectedEmployee}
        open={modalOpen}
        onClose={handleCloseModal}
        onEdit={handleEditEmployee}
      />
    </>
  );
};

export default Team;
