const colors = {
  white: "#ecf0f1",
  primary: "#101827",
  secondary: "#1f2938",
  tertiary: "#34495e",
  darkPrimary: "#191818",
  darkSecondary: "#2d2d2d",
  darkTertiary: "#565F71",
  darkQuaternary: "#505050",
  limeGreen: "#BBF8A9",
  blue: "blue",
};

export default colors;
