import React from "react";
import { TextFieldStyled } from "../../StyledComponents";

const DetailsInfo = ({ newEmployee, setNewEmployee }) => (
  <>
    <TextFieldStyled
      label="Home Address"
      value={newEmployee.address}
      onChange={(e) =>
        setNewEmployee({ ...newEmployee, address: e.target.value })
      }
      fullWidth
      margin="normal"
      variant="filled"
    />
    <TextFieldStyled
      label="Contact Number"
      value={newEmployee.mobile_no}
      onChange={(e) =>
        setNewEmployee({ ...newEmployee, mobile_no: e.target.value })
      }
      fullWidth
      margin="normal"
      variant="filled"
    />
    <TextFieldStyled
      label="Email Address"
      value={newEmployee.emailAddress}
      onChange={(e) =>
        setNewEmployee({ ...newEmployee, emailAddress: e.target.value })
      }
      fullWidth
      margin="normal"
      variant="filled"
    />
    <TextFieldStyled
      label="Date Hired"
      type="date"
      value={newEmployee.date_hired}
      onChange={(e) =>
        setNewEmployee({ ...newEmployee, date_hired: e.target.value })
      }
      fullWidth
      margin="normal"
      variant="filled"
      InputLabelProps={{ shrink: true }}
    />
  </>
);

export default DetailsInfo;
