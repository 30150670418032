import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  IconButton,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { v4 as uuid } from "uuid";
import CloseIcon from "@mui/icons-material/Close";

const currentTimestamp = String(Date.now() * 1000);

const AddonModal = ({
  open,
  setOpenAddonModal,
  addOns,
  productOnAddOnModal,
  setModalOrderListProducts,
}) => {
  const [quantity, setQuantity] = useState(1);
  const [selectedAddOns, setSelectedAddOns] = useState([]);
  const [note, setNote] = useState("");
  const { title, price } = productOnAddOnModal || {};
  const handleClose = () => {
    setOpenAddonModal(false);
  };
  const onDelete = (addOn) => {
    setSelectedAddOns((prev) => {
      return prev.filter((item) => item.id !== addOn.id);
    });
  };
  const manageCount = (addOn, number) => {
    setSelectedAddOns((prev) => {
      const updatedAddOns = prev.map((item) => {
        if (item.id === addOn.id) {
          return {
            ...item,
            quantity: Math.max(1, item.quantity + number),
          };
        }
        return item;
      });
      return updatedAddOns;
    });
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setNote(value);
  };
  const handleAddOnClick = (addOn) => {
    const newAddOn = {
      category_id: addOn.pos_product_category_id,
      id: addOn.id,
      name: addOn.name,
      price: addOn.price,
      quantity: 1,
      status: addOn.status,
      type: addOn.type,
    };
    setSelectedAddOns((prev) => {
      if (prev.find((item) => item.id === addOn.id)) {
        return prev.filter((item) => item.id !== addOn.id);
      } else {
        return [...prev, newAddOn];
      }
    });
  };

  const handleQuantityChange = (amount) => {
    setQuantity((prev) => Math.max(1, prev + amount));
  };

  const handleAddOrder = () => {
    setModalOrderListProducts((prevProducts) => {
      const productItem = {
        addOns: selectedAddOns,
        category_id: productOnAddOnModal.pos_product_category_id,
        code: productOnAddOnModal.code,
        created_at: productOnAddOnModal.created_at,
        currentTimestamp: currentTimestamp,
        description: productOnAddOnModal.description,
        id: productOnAddOnModal.id,
        is_printed: 0,
        note: note,
        order: productOnAddOnModal.order,
        price: productOnAddOnModal.price,
        quantity: quantity,
        isCancelled: false,
        is_deleted: false,
        is_pop_up: true,
        status: productOnAddOnModal.status,
        stock: productOnAddOnModal.stock,
        title: productOnAddOnModal.title,
        updated_at: productOnAddOnModal.updated_at,
        uuid: uuid(),
      };
      return [...prevProducts, productItem];
    });
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <Box
        display="flex"
        flexDirection="column"
        bgcolor="#25292d"
        color="#fff"
        width="90%"
        maxHeight="90vh"
        margin="auto"
        borderRadius={1}
        overflow="auto"
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          className="p-4 border-b border-solid border-gray"
        >
          <Box display="flex" alignItems="center">
            <Typography className="text-base font-bold">{title}</Typography>
            <Typography
              sx={{
                fontSize: "16px",
                color: "#fff",
                padding: "4px 16px",
                borderRadius: "8px",
                marginLeft: "16px",
              }}
              className="bg-purple"
            >
              ${(quantity * price).toFixed(2)}
            </Typography>
          </Box>
          <Box>
            <IconButton
              className="p-2"
              aria-label="close"
              onClick={handleClose}
            >
              <CloseIcon className="text-xl text-white" />
            </IconButton>
          </Box>
        </Box>

        <Box display="flex" height="100%">
          <Box
            width="50%"
            className="addon-modal border-r border-solid border-gray p-2"
          >
            <Box display="flex" justifyContent="space-between" mt={2} mb={2}>
              <Box display="flex" className="gap-x-2" alignItems="center">
                <Typography className="text-base font-bold">
                  Quantity
                </Typography>
                <IconButton
                  onClick={() => handleQuantityChange(-1)}
                  sx={{
                    backgroundColor: "#fff",
                    width: "24px",
                    height: "24px",
                  }}
                  className="rounded-full"
                >
                  <RemoveIcon className="text-sm" />
                </IconButton>
                <Typography>{quantity}</Typography>
                <IconButton
                  onClick={() => handleQuantityChange(1)}
                  sx={{
                    backgroundColor: "#fff",
                    width: "24px",
                    height: "24px",
                  }}
                  className="rounded-full"
                >
                  <AddIcon className="text-sm" />
                </IconButton>
              </Box>
            </Box>
            <Box>
              <Typography className="text-base font-bold">Note</Typography>

              <TextField
                fullWidth
                multiline
                rows={4}
                onChange={handleChange}
                className="text-white mt-4 rounded border border-solid border-gray"
                value={note}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    color: "#fff",
                    "& fieldset": {
                      border: "none",
                    },
                    "& input": {
                      color: "#fff",
                    },
                    "& ::placeholder": {
                      color: "#a9a9a9",
                    },
                  },
                }}
              />
            </Box>
            <Typography className="mt-4 text-base font-bold">
              Select Add-Ons
            </Typography>
            <Box sx={{ height: "250px", overflow: "auto", mt: 2 }}>
              <Box display="flex" flexWrap="wrap" gap={"8px 2%"}>
                {addOns &&
                  addOns.map((addOn, index) => (
                    <Button
                      key={index}
                      sx={{
                        minWidth: "32%",
                        borderRadius: "8px",
                        fontSize: "12px",
                        flexDirection: "column",
                        lineHeight: "1.2",
                        justifyContent: "center",
                        height: "40px",
                      }}
                      className="text-white border border-solid border-gray"
                      onClick={() => handleAddOnClick(addOn)}
                    >
                      {addOn.name}
                      <span
                        style={{
                          fontSize: "10px",
                        }}
                        className="text-purple"
                      >
                        {addOn.price > 0 ? `+ $${addOn.price}` : ""}
                      </span>
                    </Button>
                  ))}
              </Box>
            </Box>
          </Box>
          <Box
            width="50%"
            sx={{
              height: "480px",
              overflow: "auto",
              padding: "8px",
            }}
            className="addon-modal-content"
          >
            <Typography className="text-base font-bold">
              Selected Add-Ons
            </Typography>
            <Box sx={{ mt: 2 }}>
              {selectedAddOns.length > 0 ? (
                <ul style={{ padding: 0, listStyleType: "none" }}>
                  {selectedAddOns.map((addOn, index) => (
                    <Box
                      key={index}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      className="mb-1 p-1"
                    >
                      <IconButton
                        onClick={() => onDelete(addOn)}
                        sx={{
                          backgroundColor: "#fff",
                          width: "30px",
                          height: "30px",
                        }}
                        className="rounded-full"
                      >
                        <RemoveIcon sx={{ fontSize: "14px" }} />
                      </IconButton>

                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent={"space-between"}
                        border="1px solid"
                        borderRadius={1}
                        p={1}
                        width="calc( 100% - 46px)"
                        className="border-purple"
                      >
                        <Typography color="white">{addOn.name}</Typography>
                        <Box display="flex" alignItems="center" gap="8px">
                          <IconButton
                            onClick={() => manageCount(addOn, -1)}
                            sx={{
                              backgroundColor: "#fff",
                              width: "24px",
                              height: "24px",
                            }}
                            className="rounded-full"
                          >
                            <RemoveIcon sx={{ fontSize: "14px" }} />
                          </IconButton>
                          <Typography color="white" mx={2}>
                            {addOn.quantity}
                          </Typography>
                          <IconButton
                            onClick={() => manageCount(addOn, 1)}
                            sx={{
                              backgroundColor: "#fff",
                              width: "24px",
                              height: "24px",
                            }}
                            className="rounded-full"
                          >
                            <AddIcon sx={{ fontSize: "14px" }} />
                          </IconButton>
                          <Typography color="white">
                            ${addOn.price.toFixed(2)}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  ))}
                </ul>
              ) : (
                <Typography color="white">No add-ons selected</Typography>
              )}
            </Box>
          </Box>
        </Box>
        <Box
          display="flex"
          justifyContent="flex-end"
          className="p-4 border-t border-solid border-gray"
        >
          <Button
            onClick={() => handleAddOrder()}
            className="mr-2 bg-purple text-white "
          >
            Save
          </Button>
          <Button className="bg-red text-white" onClick={() => handleClose()}>
            Delete
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddonModal;
