import React, { useEffect, useState } from "react";
import { Typography, Button, Box, Modal, TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import {
  getEmployeementContractRequest,
  addEmployeeContractRequest,
  getEmployeeRolesRequest,
  addEmployeeRoleRequest,
} from "../../../../../actions/employeeActions.js";

const styles = {
  button: {
    background: "#0f1828",
    color: "#fff",
    borderRadius: "20px",
    padding: "8px",
    margin: "4px",
    fontSize: "10px",
  },
  buttonActive: {
    background: "linear-gradient(90deg,#fa5186,#f3d6a0)",
    color: "#fff",
    borderRadius: "20px",
    padding: "8px",
    margin: "4px",
    fontSize: "10px",
  },
  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "10px",
  },
};

const EmploymentInfo = ({ newEmployee, setNewEmployee }) => {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [activeEmployeeType, setActiveEmployeeType] = useState(null);
  const [activeContract, setActiveContract] = useState(null);
  const [contracts, setContracts] = useState([]);
  const [roles, setRoles] = useState([]);
  const [activeRole, setActiveRole] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getEmployeementContractRequest((error, fetchedContracts) => {
        if (!error) {
          setContracts(fetchedContracts);
        } else {
          console.error("error message:", error);
        }
      })
    );

    dispatch(
      getEmployeeRolesRequest((error, fetchedRoles) => {
        if (!error) {
          setRoles(fetchedRoles);
        } else {
          console.error("error message:", error);
        }
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (newEmployee.employment_contract_id) {
      const contractIndex = contracts.findIndex(
        (contract) => contract.id === newEmployee.employment_contract_id
      );
      if (contractIndex !== -1) {
        setActiveContract(`contract-${contractIndex}`);
      }
    }
    if (newEmployee.employment_role_id) {
      const roleIndex = roles.findIndex(
        (role) => role.id === newEmployee.employment_role_id
      );
      if (roleIndex !== -1) {
        setActiveRole(`role-${roleIndex}`);
      }
    }
  }, [
    contracts,
    roles,
    newEmployee.employment_contract_id,
    newEmployee.employment_role_id,
  ]);

  useEffect(() => {
    if (newEmployee.role) {
      const roleIndex = ["owner", "manager", "staff"].indexOf(newEmployee.role);
      if (roleIndex !== -1) {
        setActiveEmployeeType(`role-${roleIndex}`);
      }
    }
  }, [newEmployee.role]);

  const handleOpen = (title) => {
    setModalTitle(title);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleSave = () => {
    if (name) {
      if (modalTitle === "Contracts") {
        dispatch(
          addEmployeeContractRequest(
            { contract: name, description: "" },
            (error, data) => {
              if (!error) {
                setContracts([...contracts, data.data.employment_contract]);
                setNewEmployee({
                  ...newEmployee,
                  employment_contract_id: data.data.employment_contract.id,
                });
              } else {
                console.error("error message:", error);
              }
            }
          )
        );
      } else if (modalTitle === "Roles") {
        dispatch(
          addEmployeeRoleRequest({ title: name }, (error, data) => {
            if (!error) {
              setRoles([...roles, data.data.employment_role]);
              setNewEmployee({
                ...newEmployee,
                employment_role_id: data.data.employment_role.id,
              });
            } else {
              console.error("Error message:", error || "Invalid response data");
            }
          })
        );
      }
      setName("");
      handleClose();
    }
  };

  const handleButtonClick = (key, category, id = null) => {
    if (category === "employeeType") {
      setActiveEmployeeType((prev) => (prev === key ? null : key));
      if (id) {
        setNewEmployee({ ...newEmployee, role: id });
      }
    } else if (category === "contract") {
      setActiveContract((prev) => (prev === key ? null : key));
      if (id) {
        setNewEmployee({ ...newEmployee, employment_contract_id: id });
      }
    } else if (category === "role") {
      setActiveRole((prev) => (prev === key ? null : key));
      if (id) {
        setNewEmployee({ ...newEmployee, employment_role_id: id });
      }
    }
  };

  return (
    <>
      <Box>
        <Typography variant="body1" mb={1}>
          Employee Type
        </Typography>
        <div style={{ display: "flex", gap: "8px", marginBottom: "16px" }}>
          {["owner", "manager", "staff"].map((role, index) => (
            <Button
              key={index}
              onClick={() => {
                setNewEmployee({ ...newEmployee, role });
                handleButtonClick(`role-${index}`, "employeeType", role);
              }}
              style={
                activeEmployeeType === `role-${index}`
                  ? styles.buttonActive
                  : styles.button
              }
            >
              {role.charAt(0).toUpperCase() + role.slice(1)}
            </Button>
          ))}
        </div>
      </Box>
      <Box>
        <Typography variant="body1" mt={2} mb={1}>
          Contracts
        </Typography>

        {(contracts || []).map((contract, index) =>
          contract ? (
            <Button
              key={index}
              onClick={() => {
                handleButtonClick(`contract-${index}`, "contract", contract.id);
              }}
              style={
                activeContract === `contract-${index}`
                  ? styles.buttonActive
                  : styles.button
              }
            >
              {contract.contract}
            </Button>
          ) : null
        )}

        <Button style={styles.button} onClick={() => handleOpen("Contracts")}>
          +
        </Button>
      </Box>
      <Box>
        <Typography variant="body1" mt={2} mb={1}>
          Roles
        </Typography>

        {(roles || []).map((role, index) => (
          <Button
            key={index}
            onClick={() => {
              handleButtonClick(`role-${index}`, "role", role.id);
            }}
            style={
              activeRole === `role-${index}`
                ? styles.buttonActive
                : styles.button
            }
          >
            {role?.title}
          </Button>
        ))}
        <Button style={styles.button} onClick={() => handleOpen("Roles")}>
          +
        </Button>
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="add-item-modal-title"
        aria-describedby="add-item-modal-description"
      >
        <Box sx={styles.modalStyle}>
          <Typography id="add-item-modal-title" variant="h6" component="h2">
            Add New {modalTitle}
          </Typography>
          <TextField
            fullWidth
            label={`${modalTitle} Title`}
            value={name}
            onChange={(e) => setName(e.target.value)}
            margin="normal"
          />
          <Button onClick={handleSave} style={styles.button}>
            Save
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default EmploymentInfo;
