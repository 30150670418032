import React from "react";
import { TextFieldStyled } from "../../StyledComponents";

const AccountInfo = ({ newEmployee, setNewEmployee }) => (
  <>
    <TextFieldStyled
      label="Tax Number"
      value={newEmployee.tax_no}
      onChange={(e) =>
        setNewEmployee({ ...newEmployee, tax_no: e.target.value })
      }
      fullWidth
      margin="normal"
      variant="filled"
    />
    <TextFieldStyled
      label="Bank Account"
      value={newEmployee.bank_account}
      onChange={(e) =>
        setNewEmployee({ ...newEmployee, bank_account: e.target.value })
      }
      fullWidth
      margin="normal"
      variant="filled"
    />
    <TextFieldStyled
      label="Bank Name"
      value={newEmployee.bank_name}
      onChange={(e) =>
        setNewEmployee({ ...newEmployee, bank_name: e.target.value })
      }
      fullWidth
      margin="normal"
      variant="filled"
    />
  </>
);

export default AccountInfo;
