import React, { useState, useEffect } from "react";
import styled from "styled-components";
import arrowDownIcon from "../../images/booking/arrow-down-icon.png";
import TableItem from "./TableItem";
import moment from "moment";

const FloorsRow = styled.div`
  display: inline-flex;
  height: 40px;
  width: 100%;
  background: #394044;
  border-top: 1px solid #222a2f;
  border-bottom: 1px solid #222a2f;
  cursor: pointer;

  #cluster-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 10px;
  }

  h4 {
    font-size: 18px;
    font-weight: 500;
    margin-right: 10px;
  }
`;

const ImgContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 12px;
  height: 12px;
  transform: ${(props) => (props.isOpen ? "rotate(180deg)" : "rotate(0deg)")};
  transition: transform 0.2s ease-out;

  img {
    position: absolute;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
  }
`;

const HeaderText = styled.p`
  position: absolute;
  right: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #9d9d9d;
`;

const CollapsableContainer = styled.div`
  position: relative;
  display: block;
  width: fit-content;
  background: #394044;
`;

const TableRow = styled.div`
  display: inline-flex;
  flex-direction: column;
  height: 40px;
  width: 100%;
  border-top: 1px solid #222a2f;
  border-bottom: 1px solid #222a2f;

  span {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 40px;
    align-items: center;
    max-width: 150px;
    border-right: 1px solid #9a9a9a;
    background: #394044;
  }

  h4 {
    font-size: 18px;
    font-weight: 500;
    margin-right: 10px;
  }
`;

const TimeBlock = styled.div`
  display: inline-block;
  height: 40px;
  min-width: 40px;
  background: #394044;
  border-left: 1px solid #222a2f;
  border-bottom: 2px solid #222a2f;
`;

const TimeBubble = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  left: 309px;
  height: 100%;
  width: 2px;
  background: #2f8fff;
  z-index: 4;

  #bubble {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 30px;
    background: #2f8fff;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 400;
    top: -38px;
  }

  #small-line {
    position: absolute;
    display: flex;
    justify-content: center;
    height: 15px;
    width: 2px;
    background: #2f8fff;
    top: -14px;
  }
`;

const TableNameContainer = styled.div`
  position: absolute;
  top: 40px;
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  // background: blue;
`;

function CollapsableFloor(props) {
  const GridColumn = [];
  const [time, setTime] = useState(moment(new Date()).format("LT"));
  const [isOpen, setIsOpen] = useState(true);
  const [computedHeight, setComputedHeight] = useState(
    40 * props.floor.tables.length
  );

  for (let i = 1; i <= 100; i++) {
    if (i % 4 === 0) {
      GridColumn.push(
        <TimeBlock style={{ borderRight: "1px solid #9a9a9a" }} key={i} />
      );
    } else {
      GridColumn.push(<TimeBlock key={i} />);
    }
  }

  let initPx = 149;
  let hourToPx = 160;
  let computedHour = initPx;
  let currentHour = moment(new Date()).format("H");
  let currentMinute = moment(new Date()).format("mm");
  let multiplier = currentHour - 6;
  let computedMinutes = 2.67 * currentMinute;
  computedHour = hourToPx * multiplier + initPx + computedMinutes;

  const toggling = (floor) => {
    if (isOpen) {
      setIsOpen(!isOpen);
      setComputedHeight(40);
    } else {
      setIsOpen(!isOpen);
      setComputedHeight(40 * floor.tables.length);
    }
  };

  const toggleTableDetails = (booking) => {
    props.toggleTableDetails(booking);
  };

  const handleBookingClick = (booking, table) => {
    props.bookingClickHandler(booking, table);
  };

  const handleChangeTable = (newTable, prevTable, booking) => {
    // console.log('new:', newTable, 'prev:', prevTable, 'booking:', booking);
    if (booking.table_ids === undefined) {
      booking["table_ids"] = booking.table.map((e) => e.id);
    }
    let tables = booking.table;
    let idx = tables.findIndex((e) => e.id === prevTable);
    tables.splice(idx, 1, newTable);
    booking = { ...booking, table_ids: tables.map((e) => e.id) };
    props.changeTable(booking);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(moment(new Date()).format("LT"));
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  return (
    <CollapsableContainer>
      {props.index === 0 ? (
        <TimeBubble style={{ left: computedHour }}>
          <div id="bubble">{time}</div> <div id="small-line" />
        </TimeBubble>
      ) : (
        <TimeBubble style={{ left: computedHour }} />
      )}
      <FloorsRow key={Math.random()} onClick={() => toggling(props.floor)}>
        <div
          id="cluster-row"
          style={{
            position: "-webkit-sticky",
            position: "sticky",
            left: "0px",
            zIndex: 2,
          }}
        >
          <h4>{props.floor.floor_name}</h4>
          <ImgContainer>
            <img
              src={arrowDownIcon}
              width={12}
              height={7}
              style={
                isOpen
                  ? { transition: "200ms", transform: "rotate(180deg)" }
                  : { transition: "200ms", transform: "rotate(360deg)" }
              }
            />
          </ImgContainer>
        </div>
      </FloorsRow>

      <TableNameContainer>
        {props.floor.tables.length > 0
          ? props.floor.tables.map((table) => {
              if (isOpen) {
                return (
                  <TableRow key={table.id}>
                    <span
                      style={{
                        position: "-webkit-sticky",
                        position: "sticky",
                        left: "0px",
                        zIndex: 3,
                      }}
                    >
                      <h4>{table.name}</h4>
                      <HeaderText>{table.capacity_max}</HeaderText>
                    </span>
                  </TableRow>
                );
              }
            })
          : ""}
      </TableNameContainer>

      <div
        style={{
          position: "relative",
          overflow: "hidden",
          width: "fit-content",
          height: `${isOpen ? computedHeight : 0}px`,
          transition: "ease 200ms",
          // zIndex: 1,
        }}
      >
        {props.floor.tables.length > 0
          ? props.floor.tables.map((table) => {
              return (
                <TableItem
                  key={table.id}
                  table={table}
                  bookings={props.bookings}
                  floorId={props.floor.id}
                  toggleTableDetails={toggleTableDetails}
                  bookingClickHandler={handleBookingClick}
                  handleChangeTable={handleChangeTable}
                />
              );
            })
          : ""}
      </div>
    </CollapsableContainer>
  );
}

export default CollapsableFloor;
