export const getBookingsRequest = (selectedDate, shiftId, callback) => ({
  type: "GET_BOOKINGS_REQUEST",
  selectedDate,
  shiftId,
  callback,
});

export const getBookingHistoryRequest = (bookingId, callback) => ({
  type: "GET_BOOKING_HISTORY_REQUEST",
  bookingId,
  callback,
});

export const bookingStoreRequest = (params, callback) => ({
  type: "BOOKING_STORE_REQUEST",
  params,
  callback,
});

export const updateBookingRequest = (booking, callback) => ({
  type: "BOOKING_UPDATE_REQUEST",
  booking,
  callback,
});
