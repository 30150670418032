import React, { useEffect, useState } from "react";
import { Box, Typography, Grid, Button, Modal, TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import "../../styles/style.scss";
import colors from "../StyledComponents/ColorStyled.jsx";
import { ButtonStyled } from "../StyledComponents";
import dayjs from "dayjs";
import {
  getGroupScheduleRequest,
  createGroupScheduleRequest,
  getScheduleDateRequest,
  createScheduleDateRequest,
} from "../../../../actions/rosterActions";
import RosterGroupDetails from "./RosterGroupDetails";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const Roster = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [openCreateScheduleModal, setOpenCreateScheduleModal] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [rosterGroups, setRosterGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [schedules, setSchedules] = useState([]);
  const [scheduleName, setScheduleName] = useState("");
  const [startDate, setStartDate] = useState(dayjs());
  const [noOfWeeks, setNoOfWeeks] = useState("");

  const [copiedShift, setCopiedShift] = useState(null);
  const [copiedShiftCalendar, setCopiedShiftCalendar] = useState(null);
  const handleCopyShift = (shift) => {
    setCopiedShift(shift);
    setCopiedShiftCalendar(shift);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleOpenCreateScheduleModal = () => setOpenCreateScheduleModal(true);
  const handleCloseCreateScheduleModal = () =>
    setOpenCreateScheduleModal(false);
  const [copiedShifts, setCopiedShifts] = useState([]);

  const handleCreateGroup = () => {
    if (groupName) {
      const payload = {
        group_name: groupName,
      };

      dispatch(
        createGroupScheduleRequest(payload, (err, response) => {
          if (err) {
            console.error("Group creation error:", err);
          } else {
            setRosterGroups([...rosterGroups, response]);
            setGroupName("");
            handleClose();
          }
        })
      );
    }
  };

  const handleGroupClick = (group) => {
    setSelectedGroup(group);
    setShowDetails(true);

    const params = {
      employee_group_schedule_id: group.id,
    };

    dispatch(
      getScheduleDateRequest(params, (err, data) => {
        if (err) {
          console.log("Error", err);
        } else {
          const formattedSchedules = data.data
            .filter(
              (schedule) => schedule.employee_group_schedule_id === group.id
            )
            .map((schedule) => ({
              id: schedule.id,
              name: schedule.name || "",
              dateRange: `${schedule.start_date} - ${schedule.end_date}`,
              employees: schedule.no_of_employees,
              start_date: schedule.start_date,
              end_date: schedule.end_date,
              no_of_weeks: schedule.no_of_weeks,
            }));
          setSchedules(formattedSchedules);
        }
      })
    );
  };

  const handleCloseDetails = () => {
    setShowDetails(false);
  };

  const handleDeleteSchedule = () => {
    setRosterGroups(rosterGroups.filter((group) => group !== selectedGroup));
    handleCloseDetails();
  };

  const handleSaveSchedule = () => {
    const data = {
      start_date: startDate.format("YYYY-MM-DD"),
      name: scheduleName,
      no_of_weeks: parseInt(noOfWeeks, 10),
      employee_group_schedule_id: selectedGroup?.id,
    };

    dispatch(
      createScheduleDateRequest(data, (err, response) => {
        if (err) {
          console.error("Error:", err);
        } else {
          console.log("Success:", response);
          setOpenCreateScheduleModal(false);
          setScheduleName("");
          setNoOfWeeks("");
          setStartDate(dayjs());
          handleGroupClick(selectedGroup);
        }
      })
    );
  };

  useEffect(() => {
    dispatch(
      getGroupScheduleRequest((err, data) => {
        if (err) {
          console.log("Error", err);
        } else {
          setRosterGroups(
            data.map((group) => ({
              id: group.id,
              group_name: group.group_name,
            }))
          );
        }
      })
    );
  }, [dispatch]);

  return (
    <>
      <Grid textAlign={"right"} mb={4}>
        <ButtonStyled
          variant="contained"
          color="primary"
          className="bg-primary neo-outer-glow"
          onClick={handleOpen}
        >
          Add New Roster Group
        </ButtonStyled>
      </Grid>

      <Grid container spacing={2}>
        {rosterGroups.map((group, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <Box
              className="bg-primary neo-outer-glow"
              p={2}
              borderRadius={2}
              color={colors.white}
              minHeight={150}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              onClick={() => handleGroupClick(group)}
              sx={{ cursor: "pointer" }}
            >
              {group.group_name}
            </Box>
          </Grid>
        ))}
      </Grid>

      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: colors.secondary,
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            color: colors.white,
          }}
        >
          <Typography variant="h6" mb={2}>
            Please enter group name
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
            placeholder="Create new roster group"
            inputProps={{
              style: {
                color: colors.white,
                background: colors.primary,
                borderRadius: "8px",
              },
            }}
            InputLabelProps={{
              style: { color: colors.white },
            }}
          />
          <Box mt={2} display="flex" justifyContent="space-between">
            <Button
              className="bg-primary neo-outer-glow"
              sx={{ color: colors.white }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              className="bg-primary neo-outer-glow"
              sx={{ color: colors.white }}
              onClick={handleCreateGroup}
            >
              Create
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={openCreateScheduleModal}
        onClose={handleCloseCreateScheduleModal}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: colors.secondary,
            color: colors.white,
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" mb={4} fontWeight="bold">
            Create schedules
          </Typography>
          <Box
            sx={{
              mb: 2,
              width: "100%",
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              {/* TODO:カレンダーアイコンの色を白に */}
              <DatePicker
                label="Start Date"
                value={startDate}
                onChange={(newValue) => setStartDate(newValue)}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    sx: {
                      width: "100%",
                      backgroundColor: colors.primary,
                      borderRadius: "8px",
                    },
                    inputProps: {
                      style: {
                        color: colors.white,
                      },
                    },
                    InputLabelProps: {
                      style: { color: colors.white },
                    },
                  },
                }}
                sx={{
                  "& .MuiSvgIcon-root": {
                    color: "white!important",
                  },
                }}
              />
            </LocalizationProvider>
          </Box>

          <TextField
            fullWidth
            variant="outlined"
            value={scheduleName}
            onChange={(e) => setScheduleName(e.target.value)}
            placeholder="Type Schedule Name"
            sx={{ mb: 2 }}
            inputProps={{
              style: {
                color: colors.white,
                background: colors.primary,
                borderRadius: "8px",
              },
            }}
            InputLabelProps={{
              style: { color: colors.white },
            }}
            InputProps={{
              style: {
                "::placeholder": { color: colors.white },
              },
            }}
          />
          <TextField
            fullWidth
            variant="outlined"
            value={noOfWeeks}
            onChange={(e) => setNoOfWeeks(e.target.value)}
            placeholder="Enter how many weeks"
            type="number"
            sx={{ mb: 2 }}
            inputProps={{
              style: {
                color: colors.white,
                background: colors.primary,
                borderRadius: "8px",
              },
            }}
            InputLabelProps={{
              style: { color: colors.white },
            }}
            InputProps={{
              style: {
                "::placeholder": { color: colors.white },
              },
            }}
          />

          <Box display="flex" mt={2} justifyContent="center">
            <Button
              variant="contained"
              onClick={handleSaveSchedule}
              className="bg-primary neo-outer-glow"
              sx={{ color: colors.white }}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>

      {showDetails && (
        <RosterGroupDetails
          selectedGroup={selectedGroup}
          schedules={schedules}
          setSchedules={setSchedules}
          handleCloseDetails={handleCloseDetails}
          handleDeleteSchedule={handleDeleteSchedule}
          handleOpenCreateScheduleModal={handleOpenCreateScheduleModal}
          handleCloseCreateScheduleModal={handleCloseCreateScheduleModal}
          employeeGroupScheduleId={selectedGroup?.id}
          copiedShift={copiedShift}
          handleCopyShift={handleCopyShift}
          setCopiedShift={setCopiedShift}
          copiedShiftCalendar={copiedShiftCalendar}
          setCopiedShiftCalendar={setCopiedShiftCalendar}
        />
      )}
    </>
  );
};

export default Roster;
