import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from "@redux-devtools/extension";

import rootReducer from "../reducers";
import rootSaga from "../actions/sagas";
// import { reactNode } from "../Modules/FrontOfHouse/utils/Utility";

const initialState = {};
const sagaMiddleware = createSagaMiddleware();
const middleware = composeWithDevTools(applyMiddleware(sagaMiddleware));

const store = createStore(rootReducer, initialState, compose(middleware));
sagaMiddleware.run(rootSaga);

export default store;
