import React, { useEffect, useState } from "react";
import { getBusinessProfileRequest } from "../../../../actions/businessProfileActions.js";
import { Box, Typography } from "@mui/material";
import SideMenu from "../../../../Shared/components/SideMenu.jsx";
import logoWabi from "../../../../Shared/images/logo-wabi.png";
import colors from "../StyledComponents/ColorStyled.jsx";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";

const linkStyles = {
  textDecoration: "none",
  "&:hover, &.active": { backgroundColor: colors.primary },
  padding: "20px 0 20px 40px",
  color: "inherit",
  display: "block",
};

const SideBar = () => {
  const [businessName, setBusinessName] = useState("");
  const [address, setAddress] = useState("");
  const [profilePic, setProfilePic] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const callback = (error, data) => {
      if (error) {
        console.error("Error fetching business profile:", error);
      } else {
        setBusinessName(data.business_name);
        setAddress(data.address);
        setProfilePic(data.profile_pic || null);
      }
    };
    dispatch(getBusinessProfileRequest(callback));
  }, [dispatch]);

  return (
    <Box
      width="300px"
      height="100vh"
      padding="5px 0 20px"
      color={colors.white}
      borderRight="1px solid"
      borderColor={colors.white}
      position="fixed"
      className="bg-secondary"
    >
      <Box textAlign="right" mr={2}>
        <img
          src={logoWabi}
          alt="Wabi"
          style={{ width: "45px", display: "inline" }}
        />
      </Box>
      <Box display="flex" flexDirection="column" mb={4}>
        <Box
          display="flex"
          alignItems="center"
          mb={4}
          pl={2}
          pb={2}
          borderBottom="1px solid"
          borderColor={colors.white}
        >
          <SideMenu profileIcon={profilePic} />
          <Box ml={2}>
            <Typography
              variant="h6"
              style={{
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              {businessName}
            </Typography>
            <Typography
              variant="subtitle2"
              style={{
                fontSize: "10px",
                color: colors.white,
              }}
            >
              {address}
            </Typography>
          </Box>
        </Box>
        <Box>
          <NavLink
            to="/team"
            style={linkStyles}
            className={({ isActive }) => (isActive ? "active" : "")}
          >
            Team
          </NavLink>
          <NavLink
            to="/team/roster"
            style={linkStyles}
            className={({ isActive }) => (isActive ? "active" : "")}
          >
            Roster
          </NavLink>
          <NavLink
            to="/team/timesheet"
            style={linkStyles}
            className={({ isActive }) => (isActive ? "active" : "")}
          >
            Timesheet
          </NavLink>
          <NavLink
            to="/team/settings"
            style={linkStyles}
            className={({ isActive }) => (isActive ? "active" : "")}
          >
            Settings
          </NavLink>
        </Box>
      </Box>
    </Box>
  );
};

export default SideBar;
