import React, { useEffect, useState, useRef } from "react";
import {
  Grid,
  Modal,
  Backdrop,
  Fade,
  Alert,
  AlertTitle,
  Box,
  Typography,
  TextField,
} from "@mui/material";
import axios from "axios";
import {
  apiUrl,
  filterTableByFloor,
  getEmployeeInitials,
  getShiftByTime,
  createGuest,
  getStringTime,
  buildRequestHeader,
  getDisabledDays,
  filterShifts,
  getFullname,
} from "../../utils/Utility";
import { createBookingParameters } from "../../utils/RequestBuilder";
import { BOOKINGSTATUS, BOOKINGTYPE } from "../../constants/enum";

// Components
import CalendarMonth from "./calendar-month-navigation/CalendarMonth";
import BookingCalendar from "./booking-calendar/BookingCalendar";
import PartySizeSelection from "./party-size-selection/PartySizeSelection";
import TableFilter from "./table-filter/TableFilter";
import BookingButton from "./booking-button/BookingButton";
import TableList from "./table-list/TableList";
import TimeTable from "./time-table/TimeTable";
import BookingNotes from "./booking-notes/BookingNotes";
import BookingEmployeeList from "./booking-employee-list/BookingEmployeeList";
import BookingExperience from "./booking-experience/BookingExperience";
import TicketQuantity from "./booking-experience/TicketQuantity";
import AlertDialog from "./booking-experience/AlertDialog";

import phoneIcon from "../../images/booking/create-booking/phone-icon.png";
import closeButton from "../../images/booking/create-booking/close-button.png";
import personIcon from "../../images/booking/create-booking/person-icon.png";
import partySizeIcon from "../../images/booking/create-booking/part-size-icon.png";
import noteIcon from "../../images/booking/create-booking/note-icon.png";
import tableLock from "../../images/booking/create-booking/table-lock.png";
import bookingStatusPhone from "../../images/booking/create-booking/booking-status-phone.png";
import addIcon from "../../images/booking/create-booking/icon-add-white.png";
import checkIcon from "../../images/booking/create-booking/check-icon.png";
import bookingStatusNetwork from "../../images/booking/create-booking/booking-status-network.png";
import bookingStatusWalkIn from "../../images/booking/create-booking/booking-status-walk-in.png";
import experienceIcon from "../../images/booking/create-booking/booking-option-experience-icon.png";
import moment from "moment";
import { isEmpty } from "lodash";
import { useDispatch } from "react-redux";
import { getTablesRequest } from "../../../../actions/serachTableActions";
import { bookingStoreRequest } from "../../../../actions/bookingActions";
import { getExperienceAssignRequest } from "../../../../actions/experienceActions";

const styles = {
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    width: "100%",
    height: "100%",
    backdropFilter: "blur(10px)",
    outline: "none",
    position: "relative",
  },
  inputContainer: {
    width: 420,
    height: 60,
    background: "rgba(0, 0, 0, 0.6)",
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    padding: "0 15px",
  },

  label: {
    fontSize: 24,
    fontWeight: "bold",
    color: "white",
    textAlign: "center",
    marginTop: "40px",
    cursor: "pointer",
  },
};

function CreateBooking(props) {
  const reducers = props.reducers;
  const [selectedShift, setSelectedShift] = useState(props.selectedShift);
  const [employeeData, setEmployeeData] = useState(props.employeesData[0]);
  const [createBookingNote, setCreateBookingNote] = useState(false);
  const [createBookingEmployee, setCreateBookingEmployee] = useState(false);
  const [month, setMonth] = useState(Date());
  const [bookingDate, setBookingDate] = useState(moment(props.selectedDate));
  const [selectedBookingType, setSelectedBookingType] = useState(
    BOOKINGTYPE.inhouse
  );
  const [selectedTime, setSelectedTime] = useState(0);
  const [toFilterFloor, setToFilterFloor] = useState(props.floor[0]);
  const [selectedTables, setSelectedTables] = useState(null);
  const [isMultipleTable, setIsMultipleTable] = useState(true);
  const [bookingTypeSelected, setBookingTypeSelected] = useState(false);
  const [tableLockSelected, setTableLockSelected] = useState(false);
  const [partySize, setPartySize] = useState("");
  const [tableList, setTableList] = useState([]);
  const [tablesData, setTablesData] = useState([]);
  const [isSuggested, setIsSuggested] = useState(false);
  const [showAllTables, setShowAllTables] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [bookingType, setBookingType] = useState(bookingStatusPhone);
  const [bookingNote, setBookingNote] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [guestName, setGuestName] = useState("");
  const [guestPhone, setGuestPhone] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  // Added for Experience Component
  const [bookingExperience, setBookingExperience] = useState(false);
  const [expNoOfTickets, setExpNoOfTickets] = useState();
  const [expDaysOfWeek, setExpDaysOfWeek] = useState([]);
  const [expStartDate, setExpStartDate] = useState("");
  const [expEndDate, setExpEndDate] = useState("");
  const [expRecurringType, setExpRecurringType] = useState(0);
  const [expRecurringValue, setExpRecurringValue] = useState(0);
  const [expShiftId, setExpShiftId] = useState(0);
  const [expName, setExpName] = useState("");
  const [expId, setExpId] = useState(0);
  const [expHandler, setExpHandler] = useState(false);
  const [alertHandler, setAlertHandler] = useState(false);
  const [ticketQuantityOpen, setTicketQuantityOpen] = useState(false);
  const [dialogAlertMessage, setDialogAlertMessage] = useState("");
  const isSuggestedState = useRef();
  const showAllState = useRef();
  const dispatch = useDispatch();

  isSuggestedState.current = isSuggested;
  showAllState.current = showAllTables;

  const handleCloseCreateBooking = props.handleCloseCreateBooking;
  const createBookingSuccess = props.createBookingSuccess;

  let _partySize = partySize;
  let _tables = [];
  let _floor = props.floor[0];

  const selectMonth = (selectedMonth) => {
    setMonth(selectedMonth);
  };

  const selectedDate = (date) => {
    setBookingDate(date);
    props.selectedBookingDate(date);
    props.setSelectedDate(moment(date).format("yyyy-MM-DD"));
    console.log(date);
  };

  useEffect(() => {
    if (props.guest !== undefined) {
      setGuestName(getFullname(props.guest));
      setGuestPhone(props.guest.phone);
    }
  }, [props.guest]);

  const guestNameOnChange = (e) => {
    setGuestName(e.target.value);
  };

  const guestPhoneOnChange = (e) => {
    let number;
    if (e.target.validity.valid) {
      number = e.target.value;
    } else {
      setHasError(true);
      setErrorMessage("Phone should be numbers only");
      return;
    }
    if (hasError) {
      setHasError(false);
      setErrorMessage("");
    }
    setGuestPhone(number);
  };

  const partySizeInputHandler = (e) => {
    let number;
    if (e.target.validity.valid) {
      number = parseInt(e.target.value) || "";
    } else {
      setHasError(true);
      setErrorMessage("Invalid party size value");
      return;
    }
    if (hasError) {
      setHasError(false);
      setErrorMessage("");
    }
    setPartySize(number);
    searchTables(isSuggestedState.current, showAllState.current);
  };

  const selectedPartySize = (value) => {
    if (isNaN(value)) {
      setHasError(true);
      setErrorMessage("Invalid party size value");
      return;
    }

    if (hasError) {
      setHasError(false);
      setErrorMessage("");
    }

    setPartySize(value);
    searchTables(isSuggestedState.current, showAllState.current);
  };

  const selectedOption = (floor) => {
    _floor = floor;
    setToFilterFloor(floor);
    setTableList(filterTableByFloor(floor.id, tablesData));
  };

  const selectedTimeHandler = (time) => {
    const filteredShifts = filterShifts(
      props.shifts,
      moment(bookingDate).isoWeekday()
    );
    setSelectedTime(time);
    setSelectedShift(getShiftByTime(time, filteredShifts));
    console.log(getShiftByTime(time, filteredShifts));
  };

  const setIsSuggestedHandler = () => {
    setIsSuggested(!isSuggested);
    searchTables(!isSuggested, showAllState.current);
  };

  const setShowAllTablesHandler = () => {
    setShowAllTables(!showAllTables);
    searchTables(isSuggestedState.current, !showAllTables);
  };

  const selecteBookingType = (type) => {
    switch (type) {
      case BOOKINGTYPE.inhouse:
        setSelectedBookingType(type);
        setBookingType(bookingStatusWalkIn);
        handleUnmountExp();
        break;
      case BOOKINGTYPE.phone:
        setSelectedBookingType(type);
        setBookingType(bookingStatusPhone);
        handleUnmountExp();
        break;
      case BOOKINGTYPE.widget:
        setSelectedBookingType(type);
        setBookingType(bookingStatusNetwork);
        handleUnmountExp();
        break;
      case BOOKINGTYPE.experience:
        selectNewBookingExperience();
        break;
    }
    setBookingTypeSelected(false);
  };

  const addNotes = () => {
    setCreateBookingNote(true);
  };

  const selectNewEmployee = () => {
    setCreateBookingEmployee(true);
  };

  const handleCloseBookingNote = (e) => {
    if (createBookingNote) {
      setBookingNote(e);
      setCreateBookingNote(false);
    }
  };

  const handleCloseBookingEmployee = () => {
    if (createBookingEmployee) {
      setCreateBookingEmployee(false);
    }
  };

  const saveBooking = async () => {
    if (isSaving) {
      return;
    }

    if (isEmpty(guestName)) {
      alert("Please enter guest name");
      return;
    }

    if (partySize === "") {
      alert("Please select party size");
      return;
    }

    if (selectedTables === null) {
      alert("Please select table");
      return;
    }

    if (selectedTime === 0) {
      alert("Please select time");
      return;
    }

    setIsSaving(true);
    console.log("Shift ID: ", selectedShift);

    const param = createBookingParameters(
      selectedShift.id,
      selectedBookingType,
      bookingNote,
      selectedTables.map((e) => e.id),
      tableLockSelected,
      createGuest(guestName, guestPhone),
      employeeData === undefined ? props.employeesData[0] : employeeData,
      BOOKINGSTATUS.upcoming,
      moment(bookingDate).format("yyyy-MM-DD") +
        ` ${getStringTime(selectedTime)}`,
      moment(bookingDate).format("yyyy-MM-DD") +
        ` ${getStringTime(selectedTime + selectedShift.turn_time)}`,
      partySize,
      expId,
      expNoOfTickets
    );

    try {
      const callback = (error, booking) => {
        if (error) {
          console.error(error);
        } else {
          setIsSaving(false);
          handleCloseCreateBooking();
          props.fetchData();
        }
      };
      dispatch(bookingStoreRequest(param, callback));
    } catch (err) {
      console.log("Error: ", err);
      setIsSaving(false);
    }
  };

  const searchTables = async (suggested, showAll) => {
    if (partySize === 0 && selectedTime === 0) {
      setHasError(true);
      return;
    }
    try {
      const param = {
        party_size: _partySize,
        booking_time: 36000,
        booking_date: moment(bookingDate).format("yyyy-MM-DD"),
        show_suggested: suggested,
        show_all: showAll,
      };
      const callback = (error, tables) => {
        if (error) {
          console.error(error);
        } else {
          setTablesData(tables);
          setTableList(
            filterTableByFloor(
              toFilterFloor === undefined ? _floor.id : toFilterFloor.id,
              tables
            )
          );
        }
      };
      dispatch(getTablesRequest(param, callback));
    } catch (err) {
      console.log("Error: ", err);
    }
  };

  useEffect(() => {
    if (selectedShift === null) {
      setSelectedShift(props.selectedShift);
    }
  }, [props.floor, props.selectedShift, selectedShift, hasError]);

  const expGetAssign = async (id) => {
    setExpId(id);
    try {
      const callback = (error, data) => {
        if (error) {
          console.error(error);
        } else {
          if (data.connection === null) {
            setDialogAlertMessage({
              title: "Missing Details",
              body: (
                <div>
                  Please enter details for 'No Shift ID <br />
                  Experience' experience. (eg: Start Date, <br /> Shift,.. etc)
                </div>
              ),
            });
            setAlertHandler(true);
            setTicketQuantityOpen(false);
          } else {
            setExpDaysOfWeek(data.connection.day_of_week);
            setExpStartDate(data.connection.start_date);
            setExpEndDate(data.connection.end_date);
            setExpRecurringType(data.connection.recurring_type);
            setExpRecurringValue(data.connection.recurring_value);
            setExpShiftId(data.connection.shift_id);
          }
        }
      };
      dispatch(getExperienceAssignRequest({ experience_id: id }, callback));

      props.experienceList.map((d) => {
        if (d.id === id) {
          setExpName(d.exp_name);
        }
      });
    } catch (err) {
      console.log("Error: ", err);
    }
  };

  const selectNewBookingExperience = () => {
    setBookingExperience(true);
  };

  const handleCloseBookingExperience = () => {
    setBookingExperience(false);
    setTicketQuantityOpen(true);
  };

  const expTimeShiftHandler = () => {
    setSelectedShift(expShiftId);
    props.setExperienceShift(expShiftId);
  };

  const handleTicketQuantitySubmit = (ticketsNo) => {
    setSelectedBookingType(BOOKINGTYPE.experience);
    setBookingType(experienceIcon);
    setExpHandler(!false);
    expTimeShiftHandler();
    selectedDate("");
    setExpNoOfTickets(ticketsNo);
    setTicketQuantityOpen(false);
  };

  const handleUnmountExp = () => {
    setExpHandler(false);
    setExpDaysOfWeek([]);
    setExpEndDate();
    setExpRecurringType(-2);
    setExpRecurringValue(-1);
    setExpShiftId(0);
    selectedDate("");
    setExpId(0);
    props.setExperienceShift(0);
    setTicketQuantityOpen(false);
    setSelectedShift(props.selectedShift);
  };

  const disabledDays = (date) => {
    return getDisabledDays(
      date,
      expEndDate,
      expStartDate,
      expDaysOfWeek,
      expRecurringType,
      expRecurringValue,
      expHandler
    );
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      sx={styles.modal}
      open={props.createBookingOpen}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.createBookingOpen}>
        <Box sx={styles.paper}>
          {hasError && (
            <Alert
              sx={{
                position: "absolute",
                width: "100%",
              }}
              severity="error"
            >
              <AlertTitle>Error</AlertTitle>
              {errorMessage}
            </Alert>
          )}
          <Box
            sx={{
              width: 1024,
              height: 1009,
              margin: "0 auto",
            }}
          >
            <AlertDialog
              alertHandler={alertHandler}
              dialogAlertMessage={dialogAlertMessage}
              setAlertHandler={setAlertHandler}
            />
            <img
              src={closeButton}
              width="48"
              height="48"
              style={{
                position: "absolute",
                top: 10,
                right: 24,
                cursor: "pointer",
              }}
              alt="checker"
              onClick={() => handleCloseCreateBooking()}
            />
            <Typography
              sx={{
                ...styles.label,
                textAlign: "center",
              }}
              onClick={expHandler ? selectNewBookingExperience : null}
            >
              {expHandler === false
                ? "MAKE A BOOKING"
                : `BOUGHT ${expNoOfTickets}x ${expName} TICKETS!`}
            </Typography>

            {/* Booking fields */}
            {/* guest name and contact number */}
            <Grid container spacing={2} sx={{ marginTop: 2 }}>
              <Grid item xs={12} sm={6} sx={{ textAlign: "right" }}>
                <Box sx={[styles.inputContainer, { float: "right" }]}>
                  <img
                    src={personIcon}
                    width="24"
                    height="24"
                    style={{ marginRight: 22 }}
                    alt="checker"
                  />
                  <TextField
                    fullWidth
                    variant="standard"
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        color: "white",
                      },
                    }}
                    placeholder="Enter guest name"
                    value={guestName}
                    onChange={guestNameOnChange}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box sx={styles.inputContainer}>
                  <img
                    src={phoneIcon}
                    width="24"
                    height="24"
                    style={{ marginRight: 22 }}
                    alt="checker"
                  />
                  <TextField
                    fullWidth
                    variant="standard"
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        color: "white",
                      },
                    }}
                    placeholder="Contact number"
                    value={guestPhone}
                    onChange={guestPhoneOnChange}
                    inputProps={{ pattern: "[\\+\\0-9]{0,18}" }}
                  />
                </Box>
              </Grid>
            </Grid>

            {/* Date selection */}
            <Grid container spacing={2} sx={{ marginTop: 2 }}>
              <Grid item xs={12} sm={6}>
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <CalendarMonth month={month} selectMonth={selectMonth} />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box sx={styles.inputContainer}>
                  <img
                    src={partySizeIcon}
                    width="24"
                    height="24"
                    style={{ marginRight: 22 }}
                    alt="checker"
                  />
                  <TextField
                    fullWidth
                    variant="standard"
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        color: "white",
                      },
                    }}
                    placeholder="Party Size"
                    value={partySize}
                    onChange={partySizeInputHandler}
                    inputProps={{ pattern: "[0-9]{0,2}" }}
                  />
                </Box>
              </Grid>
            </Grid>

            {/* Calendar selection */}
            <Grid container spacing={2} sx={{ marginTop: 2 }}>
              <Grid item xs={12} sm={6}>
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <BookingCalendar
                    bookingDate={bookingDate}
                    month={month}
                    selectedDate={selectedDate}
                    disabledDays={disabledDays}
                  />
                </Box>
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  {props.timeList.length > 0 && (
                    <TimeTable
                      selectedTimeHandler={selectedTimeHandler}
                      timeList={props.timeList}
                    />
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box sx={{ padding: 1 }}>
                  <TableFilter
                    selectedOption={selectedOption}
                    floor={props.floor}
                  />
                </Box>
                <Box sx={{ padding: 1 }}>
                  <PartySizeSelection
                    selectedPartySize={selectedPartySize}
                    partySize={12}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    paddingRight: "88px",
                  }}
                >
                  <BookingButton
                    isSelectedHandler={() => setIsSuggestedHandler()}
                    isSelected={isSuggested}
                    titleName="Suggested"
                    width={120}
                    height={50}
                  />
                  <BookingButton
                    isSelectedHandler={() => setShowAllTablesHandler()}
                    isSelected={showAllTables}
                    titleName="Show all tables"
                    width={140}
                    height={50}
                  />
                </Box>
                <Box
                  sx={{ paddingLeft: 1, paddingRight: "102px", marginTop: 1 }}
                >
                  <TableList
                    isMultipleTable={isMultipleTable}
                    selectedTables={selectedTables}
                    floors={props.floor}
                    tables={tableList}
                    selectedTableHandler={setSelectedTables}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    position: "relative",
                    height: 120,
                    marginTop: 1,
                  }}
                >
                  <BookingButton
                    clickHandler={addNotes}
                    isSelected={bookingNote === "" ? false : true}
                    buttonOnly={true}
                    canSelect={false}
                    icon={noteIcon}
                    width={60}
                    height={60}
                  />
                  <BookingNotes
                    handleCloseBookingNote={handleCloseBookingNote}
                    createBookingNote={createBookingNote}
                  />
                  <BookingButton
                    isSelectedHandler={setBookingTypeSelected}
                    buttonOnly={true}
                    isSelected={bookingTypeSelected}
                    icon={bookingType}
                    width={60}
                    height={60}
                  />
                  <BookingButton
                    isSelectedHandler={setTableLockSelected}
                    isSelected={tableLockSelected}
                    buttonOnly={true}
                    icon={tableLock}
                    width={60}
                    height={60}
                  />
                  <BookingButton
                    clickHandler={selectNewEmployee}
                    buttonOnly={true}
                    canSelect={false}
                    titleName={getEmployeeInitials(
                      employeeData === undefined
                        ? props.employeesData[0]
                        : employeeData
                    )}
                    width={60}
                    height={60}
                  />
                  <BookingEmployeeList
                    employee={
                      employeeData === undefined
                        ? props.employeesData[0]
                        : employeeData
                    }
                    employees={props.employeesData}
                    handleCloseBookingEmployee={handleCloseBookingEmployee}
                    createBookingEmployee={createBookingEmployee}
                    selecteEmployee={setEmployeeData}
                  />
                  <BookingButton
                    isSelectedHandler={setIsMultipleTable}
                    isSelected={isMultipleTable}
                    buttonOnly={true}
                    icon={addIcon}
                    width={60}
                    height={60}
                  />
                  <BookingButton
                    clickHandler={saveBooking}
                    alwaysSelected={true}
                    buttonOnly={true}
                    canSelect={false}
                    icon={checkIcon}
                    width={60}
                    height={60}
                  />
                  {bookingTypeSelected && (
                    <Box
                      sx={{
                        position: "absolute",
                        height: 60,
                        width: "100%",
                        bottom: -10,
                        display: "inline-flex",
                      }}
                    >
                      <BookingButton
                        value={BOOKINGTYPE.phone}
                        clickHandler={selecteBookingType}
                        buttonOnly={true}
                        canSelect={false}
                        icon={bookingStatusPhone}
                        width={60}
                        height={60}
                      />
                      <BookingButton
                        value={BOOKINGTYPE.inhouse}
                        clickHandler={selecteBookingType}
                        buttonOnly={true}
                        canSelect={false}
                        icon={bookingStatusWalkIn}
                        width={60}
                        height={60}
                      />
                      <BookingButton
                        value={BOOKINGTYPE.widget}
                        clickHandler={selecteBookingType}
                        buttonOnly={true}
                        canSelect={false}
                        icon={bookingStatusNetwork}
                        width={60}
                        height={60}
                      />
                      {props.experienceList && (
                        <BookingButton
                          value={BOOKINGTYPE.experience}
                          clickHandler={selecteBookingType}
                          bookingExperience={bookingExperience}
                          buttonOnly={true}
                          canSelect={false}
                          icon={experienceIcon}
                          width={60}
                          height={60}
                          imgWidth={"38.5"}
                        />
                      )}
                    </Box>
                  )}
                  <BookingExperience
                    handleCloseBookingExperience={handleCloseBookingExperience}
                    bookingExperience={bookingExperience}
                    experienceList={props.experienceList}
                    expGetAssign={expGetAssign}
                    setTicketQuantityOpen={setTicketQuantityOpen}
                  />
                  <TicketQuantity
                    experienceData={
                      props.experienceList.filter(
                        (item) => item.id === expId
                      )[0]
                    }
                    setExpNoOfTickets={setExpNoOfTickets}
                    handleTicketQuantitySubmit={handleTicketQuantitySubmit}
                    setTicketQuantityOpen={setTicketQuantityOpen}
                    ticketQuantityOpen={ticketQuantityOpen}
                    handleUnmountExp={handleUnmountExp}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}

export default CreateBooking;
