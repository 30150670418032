// import createBookingIcon from "../images/booking/create-booking-icon.png";
import React, {
  useState,
  useRef,
  useEffect,
  Fragment,
  useCallback,
} from "react";
// utils
import { DebounceInput } from "react-debounce-input";
import { Grid, Container, Box } from "@mui/material";
import { Mousewheel } from "swiper/modules";
import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";
import { useDispatch, useSelector } from "react-redux";
// import { useDrop } from "react-dnd";
import AddIcon from "@mui/icons-material/Add";
import moment from "moment";
import { v4 as uuid } from "uuid";

import {
  DndContext,
  useSensor,
  useSensors,
  MouseSensor,
  TouchSensor,
} from "@dnd-kit/core";
import {
  checkIfBookingIsOvertime,
  filterBookingByTime,
  filterShifts,
  getAllTime,
  getBookingsOnTable,
  getBookingStatusOptions,
  getBookingTableOptions,
  getCurrentShift,
  sortBookingByStatus,
} from "../utils/Utility";
// components
import AvailabilityModal from "./AvailabilityModal/AvailabilityModal.tsx";
import BookingCalendarArrow from "./BookingCalendarArrow/BookingCalendarArrow";
import BookingList from "./BookingList";
import CreateBooking from "./CreatBooking/index.js";
import FloorDropDown from "./FloorDropDown";
import ReservationDetails from "./CreatBooking/reservation-details/ReservationDetails";
import SegmentedControl from "./SegmentedControl";
import SelectBookingCalendar from "./BookingCalendar/SelectBookingCalendar.tsx";
import ShiftIndicator from "./CreatBooking/shift-indicator/shift-indicator";
import ShiftSelector from "./ShiftSelector/ShiftSelector";
import SideMenu from "../../../Shared/components/SideMenu.jsx";
import TableComponent from "./TableComponent/TableComponent.jsx";
import TableDetails from "./TableDetails/TableDetails";
import TimeLine from "./TimelineView/Timeline";
import WaitList from "./TableViewWaitList/WaitList";
// actions
import { checkAvailabilityRequest } from "../../../actions/availabilityActions.js";
import { fetchTimeslotsRequest } from "../../../actions/timeslotActions.js";
import {
  bookingStoreRequest,
  getBookingsRequest,
} from "../../../actions/bookingActions.js";
import { getEmployeesRequest } from "../../../actions/employeeActions.js";
import { getExperienceListRequest } from "../../../actions/experienceActions.js";
import { getFloorRequest } from "../../../actions/floorActions";
import { getOrderListsRequest } from "../../../actions/orderListActions.js";
import { getShiftRequest } from "../../../actions/shiftActions.js";
// styles
import "swiper/swiper-bundle.css";
import "../styles/bundle.scss";
// images
import { ReactComponent as TableFloor } from "../images/table-floor.svg";
import btnPosIcon from "../images/logo.svg";
import btnTimelineIcon from "../images/booking/btn-timeline-icon.png";
import btnTimelineSelectedIcon from "../images/booking/btn-timeline-selected-icon.png";

// constants
import { BOOKINGSTATUS, VIEWMODE } from "../constants/enum";
import PosModal from "./PosModal/PosModal.jsx";
import { getProductsRequest } from "../../../actions/productsActions.js";

// TODO: modify to 30000 when you deploy on production
const fetchTime = 30000000;

const formatDateShort = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

interface Table {
  [x: string]: any;
  id: number;
  name: string;
}
interface Booking {
  id: number;
  guest: string;
  table_lock: number;
  status: number;
}

const Booking = () => {
  const reducers = useSelector((state) => state.userReducers);
  const dispatch = useDispatch();

  const [availabilityModal, setAvailabilityModal] = useState(false);
  const [bookingDate, setBookingDate] = useState(new Date());
  const [bookingsList, setBookingsList] = useState<Booking[]>([]);
  const [checking, setChecking] = useState(false);
  const [createBookingOpen, setCreateBookingOpen] = useState(false);
  const [dateChangeHandler, setDateChangeHandler] = useState(false);
  const [employeesData, setEmployeesData] = useState<any>([]);
  const [experienceList, setExperienceList] = useState<any>([]);
  const [experienceShiftId, setExperienceShiftId] = useState(0);
  const [filteredBookingList, setFilteredBookingList] = useState<Booking[]>([]);
  const [floor, setFloor] = useState<any>(null);
  const [floorList, setFloorList] = useState<any>([]);
  const [floors, setFloors] = useState([]);
  const [height, setHeight] = useState(0);
  const [isChangingTable, setIsChangingTable] = useState(false);
  const [isInit, setIsInit] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [keyword, setKeyword] = useState("");
  const [openBookingCalendar, setOpenBookingCalendar] = useState(false);
  const [openPosScreen, setOpenPosScreen] = useState(false);
  const [orderLists, setOrderLists] = useState<any>([]);
  const [partySize, setPartySize] = useState<any>(null);
  const [partySizes, setPartySizes] = useState([]);
  const [posScreen, setPosScreen] = useState(false);
  const [posModalBooking, setPosModalBooking] = useState<any>(null);
  const [posModalTable, setPosModalTable] = useState(null);
  const [selectedBooking, setSelectedBooking] = useState<Booking | null>(null);
  const [selectedDate, setSelectedDate] = useState(formatDateShort(new Date()));
  const [selectedDateChanged, setSelectedDateChanged] =
    useState<boolean>(false);
  const [selectedFloor, setSelectedFloor] = useState<any>(null);
  const [selectedOption, setSelectedOption] = useState("1");
  const [selectedOptionName, setSelectedOptionName] = useState("Sort By: Time");
  const [selectedShift, setSelectedShift] = useState<any>(null);
  const [selectedTableData, setSelectedTableData] = useState([]);
  const [selectedTableId, setSelectedTableId] = useState([0]);
  const [shiftsData, setShiftsData] = useState<any>([]);
  const [showTableDetails, setShowTableDetails] = useState(false);
  const [showTimeline, setShowTimeline] = useState(false);
  const [table, setTable] = useState<any>(null);
  const [tableDetailsData, setTableDetailsData] = useState([]);
  const [tableList, setTableList] = useState<Table[]>([]);
  const [tables, setTables] = useState([]);
  const [timeList, setTimeList] = useState<string[]>([]);
  const [timeslot, setTimeslot] = useState<any>(null);
  const [timeslots, setTimeslots] = useState([]);
  const [viewState, setViewState] = useState(VIEWMODE.tableViewAvailability);
  const timeoutHandler = useRef<NodeJS.Timeout | null>(null);
  const [registeredProducts, setRegisteredProducts] = useState([]);

  const sideBarWidth = 363;
  const setFilteredBookingListHandler = (index, value) => {
    const safeBookingsList: Booking[] = bookingsList ?? [];
    const filteredBookings: Booking[] = safeBookingsList.filter((e) => e.guest);
    const sortedBookings: Booking[] = sortBookingByStatus(
      filteredBookings,
      index,
      value
    ) as Booking[];
    setFilteredBookingList(sortedBookings);
  };
  const selectedSegment = (index) => {
    setSelectedOption(index);
    setFilteredBookingListHandler(index, keyword);
    switch (index) {
      case "1":
        setSelectedOptionName("Sort By: Time");
        break;
      case "2":
        setSelectedOptionName("Sort By: Status");
        break;

      case "4":
        setSelectedOptionName("Sort By: Alerts");
        break;
      default:
        setSelectedOptionName(" ");
        break;
    }
  };
  const [slideClass, setSlideClass] = useState("slide-in");

  const sideBarRef = useRef(null);
  const handleSlideChange = (swiper) => {
    if (swiper.activeIndex === 0) {
      setSelectedBooking(null);
    }
    if (swiper.activeIndex === 1) {
      setSlideClass("slide-out");
    } else {
      setSlideClass("slide-in");
    }
  };
  const selectFloorCallBack = (floor) => {
    setIsLoading(true);
    setTableList([]);
    setSelectedFloor(floor);
  };

  const handleOpenCreateBooking = () => {
    if (!createBookingOpen) {
      setCreateBookingOpen(true);
    }
  };

  const handleCloseCreateBooking = () => {
    if (createBookingOpen) {
      setCreateBookingOpen(false);
    }
  };

  const handleOpenSelectedBookingCalendar = () => {
    if (!openBookingCalendar) {
      setOpenBookingCalendar(true);
    }
  };

  const handleCloseSelectedBookingCalendar = () => {
    if (openBookingCalendar) {
      setOpenBookingCalendar(false);
    }
  };

  const selectedBookingDate = (date) => {
    // @ts-ignore
    setSelectedDate(moment(date).format("yyyy-MM-DD"));
    setDateChangeHandler(true);
    const filteredShiftsData = filterShifts(
      shiftsData,
      moment(date).isoWeekday()
    );
    if (experienceShiftId === 0) {
      setTimeList(
        getAllTime(filteredShiftsData, moment(date).format("yyyy-MM-DD"))
      );
    } else {
      setTimeList(
        getAllTime(
          filteredShiftsData.find((e) => e.id === experienceShiftId),
          moment(date).format("yyyy-MM-DD")
        )
      );
    }
  };

  const saveBookingHandler = () => {
    setIsLoading(true);
    getFloor();
  };
  const getFloor = () => {
    const callback = (error: string | null, floors: any[] | null) => {
      if (error) {
        console.error("Error: ", error);
      } else if (floors) {
        setFloorList(floors);
        setSelectedFloor(floors[0]);
        getShift();
        getProducts();
        getOrderList();
      }
    };
    dispatch(getFloorRequest(callback));
  };

  const getEmployees = () => {
    const callback = (error: string | null, employees: any[] | null) => {
      if (error) {
        console.error("Error: ", error);
      } else if (employees) {
        setEmployeesData(employees);
      }
    };
    dispatch(getEmployeesRequest(callback));
  };

  const getShift = () => {
    const callback = (error: string | null, shifts: any[] | null) => {
      if (error) {
        console.error(error);
      } else if (shifts) {
        const filteredShifts = filterShifts(
          shifts,
          moment(selectedDate).isoWeekday()
        );
        setTimeList(getAllTime(filteredShifts, selectedDate));
        setShiftsData(shifts);
        getBookingList(shifts);
        getEmployees();
        getExperienceList();
      }
    };
    dispatch(getShiftRequest(selectedDate, callback));
  };

  const getExperienceList = () => {
    const callback = (error: string | null, experiences: any[] | null) => {
      if (error) {
        console.error(error);
      } else if (experiences) {
        setExperienceList(experiences);
      }
    };
    dispatch(getExperienceListRequest(callback));
  };
  const getProducts = () => {
    const callback = (error, products) => {
      if (error) {
        console.error(error);
      } else {
        setRegisteredProducts(products);
      }
    };
    dispatch(getProductsRequest(callback));
  };
  const getOrderList = async () => {
    const callback = (error: string | null, orders: any[] | null) => {
      if (error) {
        console.log("Error: ", error);
      } else if (orders) {
        setOrderLists(orders);
      }
    };
    dispatch(getOrderListsRequest(selectedDate, callback));
  };
  const getBookingList = async (propsShifts) => {
    let shiftId = 0;
    if (selectedShift === null) {
      const filteredShiftsData = filterShifts(
        propsShifts,
        moment(selectedDate).isoWeekday()
      );
      const shift = getCurrentShift(
        filteredShiftsData.length === 0 ? [] : filteredShiftsData
      );
      setSelectedShift(shift);
      shiftId = shift !== undefined ? shift.id : 0;
    } else {
      if (selectedShift !== undefined) {
        if (dateChangeHandler === true) {
          const filteredShiftsData = filterShifts(
            propsShifts,
            moment(selectedDate).isoWeekday()
          );
          const shift = getCurrentShift(
            filteredShiftsData.length === 0 ? [] : filteredShiftsData
          );
          setSelectedShift(shift);
          shiftId = shift !== undefined ? shift.id : 0;
          setDateChangeHandler(false);
        } else {
          shiftId = selectedShift.id;
        }
      } else {
        setDateChangeHandler(false);
        const filteredShiftsData = filterShifts(
          propsShifts,
          moment(selectedDate).isoWeekday()
        );
        const shift = getCurrentShift(
          filteredShiftsData.length === 0 ? [] : filteredShiftsData
        );
        setSelectedShift(shift);
        shiftId = shift !== undefined ? shift.id : 0;
      }
    }

    if (shiftId === 0) {
      console.log("Currently no shift available");
      if (timeoutHandler.current) {
        clearTimeout(timeoutHandler.current);
        timeoutHandler.current = null;
      }

      timeoutHandler.current = setTimeout(() => {
        setIsLoading(true);
        getFloor();
      }, fetchTime);
    }
    try {
      const callback = (error: string | null, bookings: any[] | null) => {
        setIsLoading(false);
        if (error) {
          console.log("Error: ", error);
        } else if (bookings) {
          let bookingList = bookings.filter((e) => {
            return (
              (moment(e.start_date).format("yyyy-MM-DD") === selectedDate ||
                moment(e.end_date).format("yyyy-MM-DD") === selectedDate) &&
              e.guest
            );
          });
          bookingList = bookingList.map((e) => {
            if (
              checkIfBookingIsOvertime(e.start_date, e.end_date) &&
              e.status !== BOOKINGSTATUS.finished &&
              e.status !== BOOKINGSTATUS.cancelled &&
              e.status !== BOOKINGSTATUS.noShow
            ) {
              let currentDate = moment().date();
              if (currentDate > e.start_date) {
                return { ...e, status: BOOKINGSTATUS.overTime };
              }
            }
            return e;
          });

          setBookingsList(bookingList.length === 0 ? [] : bookingList);
        }
      };
      dispatch(getBookingsRequest(selectedDate, shiftId, callback));
    } catch (err) {
      console.error("Error: ", err);
    }
  };

  useEffect(() => {
    if (bookingDate) {
      const callback = (data) => {
        setTimeslots(data);
      };
      dispatch(fetchTimeslotsRequest(formatDateShort(bookingDate), callback));
    }
  }, [bookingDate, dispatch]);

  const handleChange = (e) => {
    setKeyword(e.target.value);
    if (Number(selectedOption) !== 3) {
      setFilteredBookingListHandler(selectedOption, e.target.value);
    }
  };

  const checkAvailability = () => {
    if (table && floor && timeslot && partySize) {
      setChecking(true);
      const bookingDetails = {
        date: bookingDate,
        table_id: table.value,
        floor_id: floor.value,
        timeslot: timeslot.value,
        partySize: partySize.value,
      };
      const callback = (error: string | null, message: string | null) => {
        setChecking(false);
        if (error) {
          console.error(error);
        } else {
          alert(message);
        }
      };
      dispatch(checkAvailabilityRequest(bookingDetails, callback));
    } else {
      alert("Please fill up all details.");
    }
  };

  const updateTables = useCallback(() => {
    if (selectedFloor) {
      const _tables = [...selectedFloor.tables];
      setTableList(_tables);
    }
  }, [selectedFloor]);

  const fetchData = useCallback(() => {
    setIsLoading(true);
    getFloor();
  }, [getFloor]);

  const filterBookings = useCallback(() => {
    if (Number(selectedOption) !== 3) {
      const filteredBookings = sortBookingByStatus(
        bookingsList.filter((e) => e.guest),
        selectedOption,
        keyword
      );
      if (
        JSON.stringify(filteredBookingList) !== JSON.stringify(filteredBookings)
      ) {
        setFilteredBookingList(filteredBookings as Booking[]);
      }
    } else {
      if (
        JSON.stringify(filteredBookingList) !== JSON.stringify(bookingsList)
      ) {
        setFilteredBookingList(bookingsList);
      }
    }
  }, [
    bookingsList,
    filteredBookingList,
    keyword,
    selectedOption,
    sortBookingByStatus,
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (!reducers) return;
    if (bookingsList.length === 0) {
      if (tableList.length === 0) {
        updateTables();
      }

      if (!isLoading && isInit) {
        if (timeoutHandler.current) {
          clearTimeout(timeoutHandler.current);
          timeoutHandler.current = null;
        }

        if (selectedDateChanged) {
          setSelectedDateChanged(false);
          fetchData();
        } else {
          filterBookings();
        }

        timeoutHandler.current = setTimeout(fetchData, fetchTime);
      } else if (!isLoading && !isInit) {
        setIsInit(true);
        fetchData();
      }
    } else {
      if (selectedDateChanged) {
        setSelectedBooking(null);
        setSelectedDateChanged(false);
        fetchData();
        return;
      }

      filterBookings();

      if (tableList.length === 0) {
        updateTables();
      }

      if (!isLoading) {
        if (timeoutHandler.current) {
          clearTimeout(timeoutHandler.current);
          timeoutHandler.current = null;
        }

        timeoutHandler.current = setTimeout(fetchData, fetchTime);
      }
    }
  }, [
    reducers,
    bookingsList.length,
    tableList.length,
    selectedFloor,
    isLoading,
    isInit,
    selectedDateChanged,
    selectedOption,
    keyword,
    fetchData,
    filterBookings,
    updateTables,
  ]);

  const createBooking = (table) => {
    const now = moment();
    const params = {
      booking_taken: employeesData[0],
      created_at: "",
      end_date: now.clone().add(2, "hours").format("YYYY-MM-DD HH:mm:ss"),
      finished_date: "",
      floor_id: selectedFloor.id,
      guest: {
        address: "",
        anniversary: "",
        birthdate: "",
        card_brand: "",
        card_exp: "",
        card_last_4: "",
        city: "",
        company: "",
        email: "",
        first_name: "Walk",
        food_drink_preference: "",
        general_note: "",
        id: 0,
        last_name: "In",
        phone: "",
        photo: "",
        postal: "",
        seating_preference: "",
        special_relationship: "",
        state: "",
        string_tags: [],
        tags: [],
      },
      id: 0,
      no_limit: false,
      partial_seated: 0,
      party_size: table.capacity_max,
      reservation_note: "",
      shift_id: selectedShift.id,
      start_date: now.format("YYYY-MM-DD HH:mm:ss"),
      status: BOOKINGSTATUS.seated,
      table: [table],
      table_id: table.id,
      table_ids: [table.id],
      table_lock: false,
      type: 1,
      uuid: uuid(),
    };
    const callback = (error, booking) => {
      if (error) {
        console.error(error);
      } else {
        setPosModalBooking(booking);
        fetchData();
      }
    };
    dispatch(bookingStoreRequest(params, callback));
  };
  const showTableDetailsHandler = (propTable, selectedBooking) => {
    if (posScreen) {
      setOpenPosScreen(true);
      if (!selectedBooking) {
        createBooking(propTable);
      } else {
        setPosModalBooking(selectedBooking);
      }
      setPosModalTable(propTable);
    } else {
      if (showTableDetails) {
        if (isChangingTable) {
          changeTableIdHandler(propTable);
        } else {
          if (propTable.id === undefined) {
            setShowTableDetails(!showTableDetails);
            changeTableIdHandler(propTable);
          } else {
            if (selectedTableId[0] === propTable.id) {
              setShowTableDetails(!showTableDetails);
              changeTableIdHandler(propTable);
            } else {
              setShowTableDetails(showTableDetails);
              getSelectedTableData(propTable);
              changeTableIdHandler(propTable);
            }
          }
        }
      } else {
        setShowTableDetails(!showTableDetails);
        changeTableIdHandler(propTable);
        getSelectedTableData(propTable);
      }
    }
  };

  const getSelectedTableData = (val) => {
    let data = [];
    data = getBookingsOnTable(val.id, bookingsList, selectedShift);
    if (data !== null) {
      setSelectedTableData(data);
      setTableDetailsData([]);
    } else if (data === null) {
      setSelectedTableData([]);
      setTableDetailsData(val);
    }
  };

  const changeTableIdHandler = (value) => {
    let table_ids: any = [];
    if (selectedTableId[0] > 0) {
      if (isChangingTable) {
        if (selectedTableId.length >= 1) {
          if (selectedTableId.includes(value.id)) {
            table_ids = selectedTableId.filter((e) => e !== value.id);
            setSelectedTableId(table_ids);
          } else {
            table_ids = [...selectedTableId].concat(value.id);
            setSelectedTableId(table_ids);
          }
        }
      } else {
        if (selectedTableId[0] === value.id) {
          table_ids = [0];
          setSelectedTableId(table_ids);
        } else {
          table_ids = [value.id];
          setSelectedTableId(table_ids);
        }
      }
    } else {
      table_ids = [value.id];
      setSelectedTableId(table_ids);
    }
  };
  useEffect(() => {
    if (sideBarRef.current) {
      // @ts-ignore
      setHeight(sideBarRef?.current?.offsetHeight);
    }
  }, []);

  const swiperRef = useRef<any>(null);
  const startXRef = useRef(0);

  const [swiperInstance, setSwiperInstance] = useState<any>(null);

  useEffect(() => {
    const handleMouseDown = (e) => {
      startXRef.current = e.clientX;
    };

    const handleMouseMove = (e) => {
      const diffX = e.clientX - startXRef.current;
      if (diffX < 0) {
        if (swiperInstance) {
          swiperInstance.allowSlidePrev = false;
          swiperInstance.allowSlideNext = true;
        }
      } else {
        if (swiperInstance) {
          swiperInstance.allowSlidePrev = true;
          swiperInstance.allowSlideNext = false;
        }
      }
    };

    const handleMouseUp = () => {
      swiperInstance.allowSlidePrev = true;
      swiperInstance.allowSlideNext = true;
    };

    const swiperEl = swiperRef.current;
    if (swiperEl) {
      swiperEl.addEventListener("mousedown", handleMouseDown);
      swiperEl.addEventListener("mousemove", handleMouseMove);
      swiperEl.addEventListener("mouseup", handleMouseUp);
    }

    return () => {
      if (swiperEl) {
        swiperEl.removeEventListener("mousedown", handleMouseDown);
        swiperEl.removeEventListener("mousemove", handleMouseMove);
        swiperEl.removeEventListener("mouseup", handleMouseUp);
      }
    };
  }, [swiperInstance]);

  const setTableData = (floor) => {
    setTable(null);
    setFloor(floor);
    let tablesData: any = [];
    if (floor.hasOwnProperty("tables")) {
      for (var key in floor.tables) {
        if (floor.tables.hasOwnProperty(key)) {
          const tableData = {
            value: floor.tables[key].id,
            label: floor.tables[key].name,
          };
          tablesData.push(tableData);
        }
      }
    }
    if (floor.hasOwnProperty("combined_tables")) {
      for (var key in floor.combined_tables) {
        if (floor.combined_tables.hasOwnProperty(key)) {
          const tableData = {
            value: floor.combined_tables[key].combined_ids,
            label: floor.combined_tables[key].name,
          };
          tablesData.push(tableData);
        }
      }
    }
    setTables(tablesData);
  };
  function formatDate(date) {
    if (typeof date === "string") {
      date = new Date(date);
    }
    const options = { weekday: "long", day: "2-digit", month: "short" };
    return date.toLocaleDateString("en-AU", options);
  }

  const toggleBookingNavBar = () => setShowNavbar(!showNavbar);
  const [showNavbar, setShowNavbar] = useState(false);
  const changeView = (int) => {
    setViewState(int);
  };

  return (
    <Fragment>
      <Container
        style={{ background: "transparent", padding: 0, maxWidth: "none" }}
      >
        <Grid
          container
          style={{
            marginRight: 0,
            marginLeft: 0,
            justifyContent: "space-between",
            marginTop: 8,
            marginBottom: 16,
          }}
        >
          <Grid item className="menu-btn-container" sm={4} md={4} lg={4}>
            <div
              style={{ marginLeft: 10 }}
              className="btn-menu btn-menu--margin-top"
              onClick={toggleBookingNavBar}
            >
              <SideMenu />
            </div>
            <div
              className={
                viewState === 1
                  ? "btn-toggle btn-toggle--selected btn-toggle--margin-left"
                  : "btn-toggle btn-toggle--margin-left"
              }
              onClick={() => changeView(1)}
            >
              <div className="bg-icon-container">
                <TableFloor />
              </div>
            </div>
          </Grid>
          <Grid
            item
            className="menu-btn-container"
            sm={6}
            md={6}
            lg={6}
            style={{
              textAlign: "center",
            }}
          >
            <div
              style={{
                width: "100%",
                marginTop: 10,
                marginBottom: 10,
                display: "inline-flex",
                justifyContent: "center",
              }}
            >
              <BookingCalendarArrow
                arrowType="left"
                setSelectedDateChanged={setSelectedDateChanged}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                setDateChangeHandler={setDateChangeHandler}
              />
              <div style={{ margin: "0 15px" }}>
                <div
                  className="bg-date-shift"
                  onClick={() => handleOpenSelectedBookingCalendar()}
                >
                  <label>{formatDate(selectedDate)}</label>
                </div>
                <SelectBookingCalendar
                  selectedDate={selectedDate}
                  openBookingCalendar={openBookingCalendar}
                  setSelectedDateHandler={setSelectedDate}
                  setSelectedDateChanged={setSelectedDateChanged}
                  handleCloseSelectedBookingCalendar={
                    handleCloseSelectedBookingCalendar
                  }
                  setDateChangeHandler={setDateChangeHandler}
                />
              </div>
              <div style={{ margin: "0 15px" }}>
                <ShiftIndicator
                  selectedShift={selectedShift}
                  setSelectedDateChanged={setSelectedDateChanged}
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                />
              </div>
              <ShiftSelector
                setSelectedDateChanged={setSelectedDateChanged}
                selectedDateChanged={selectedDateChanged}
                selectedShiftHandler={setSelectedShift}
                selectedShift={selectedShift}
                shifts={shiftsData}
                selectedDate={selectedDate}
              />
              <BookingCalendarArrow
                arrowType="right"
                setSelectedDateChanged={setSelectedDateChanged}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                setDateChangeHandler={setDateChangeHandler}
              />
            </div>
          </Grid>
          <Grid
            item
            className="menu-btn-container"
            sm={2}
            md={2}
            lg={2}
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <div
              className="btn-menu btn-menu--big-size btn-menu--margin-left btn-menu--margin-top"
              onClick={() => handleOpenCreateBooking()}
            >
              <AddIcon
                style={{
                  color: "white",
                }}
              />
              {createBookingOpen && (
                <CreateBooking
                  selectedShift={selectedShift}
                  employeesData={employeesData}
                  timeList={timeList}
                  reducers={reducers}
                  floor={floorList}
                  shifts={shiftsData}
                  experienceList={
                    experienceList === undefined ? false : experienceList
                  }
                  createBookingOpen={createBookingOpen}
                  selectedDate={selectedDate}
                  fetchData={() => fetchData()}
                  setSelectedDate={setSelectedDate}
                  selectedBookingDate={selectedBookingDate}
                  handleCloseCreateBooking={(e) => handleCloseCreateBooking()}
                  setExperienceShift={setExperienceShiftId}
                />
              )}
            </div>
          </Grid>
        </Grid>
        {viewState === 1 ? (
          <Grid
            container
            style={{
              marginRight: 0,
              marginLeft: 0,
              display: viewState === 1 ? "flex" : "none",
              gap: "1%",
              position: "relative",
              left: posScreen ? "-360px" : 0,
              transition: "0.3s",
            }}
            className="bookingGrid-wrapper"
          >
            <Grid
              item
              className="side-bar-tableDetails"
              style={{
                width: `${sideBarWidth}px`,
                padding: "8px",
                borderRadius: "10px",
                boxShadow: "4px 11px 13px 0 #161a1d, 1px 2px 12px 7px #273036",
              }}
              ref={sideBarRef}
            >
              <TableDetails
                floorList={floorList}
                handleOpenCreateBooking={handleOpenCreateBooking}
                isChangingTable={isChangingTable}
                saveBookingHandler={saveBookingHandler}
                selectedBooking={selectedBooking}
                selectedBookingHandler={setSelectedBooking}
                selectedShift={selectedShift}
                selectedTableData={selectedTableData}
                selectedTableId={selectedTableId}
                setIsChangingTable={setIsChangingTable}
                setSelectedTableId={setSelectedTableId}
                setShowTableDetails={setShowTableDetails}
                showTableDetails={showTableDetails}
                showTableDetailsHandler={showTableDetailsHandler}
                tableDetailsData={tableDetailsData}
              />

              <div className="booking-list-container">
                <div className="bg-search-bar">
                  <DebounceInput
                    debounceTimeout={500}
                    type="text"
                    name="name"
                    style={{
                      width: "100%",
                      background: "transparent",
                      marginLeft: 10,
                      border: 0,
                    }}
                    onChange={(event) => handleChange(event)}
                  />
                </div>
              </div>
              <div
                style={{
                  color: "white",
                  height: 24,
                  fontWeight: 500,
                  marginTop: 10,
                  marginBottom: 8,
                }}
              >
                {selectedOptionName}
              </div>
              <BookingList
                selectedOption={selectedOption}
                filteredBookingList={filterBookingByTime(
                  filteredBookingList,
                  selectedShift
                )}
                floorList={floorList}
                bookingsList={bookingsList}
                keyword={keyword}
                selectedBooking={selectedBooking}
                selectedBookingHandler={setSelectedBooking}
              />
              <div className="booking-list-container-footer">
                <SegmentedControl selectedOption={selectedSegment} />
              </div>
            </Grid>
            {selectedBooking !== null && !isChangingTable && (
              <Box
                style={{
                  position: "fixed",
                  top: "80px",
                  right: 0,
                  bottom: 0,
                  zIndex: 1000,
                  width: "calc(100% - 380px)",
                  paddingRight: "16px",
                }}
              >
                <Swiper
                  ref={swiperRef}
                  modules={[Mousewheel]}
                  spaceBetween={50}
                  slidesPerView={"auto"}
                  onSlideChange={handleSlideChange}
                  className={`swiper-container ${slideClass}`}
                  style={{ height: height }}
                  initialSlide={1}
                  simulateTouch={true}
                  allowTouchMove={true}
                  onSwiper={(swiper) => setSwiperInstance(swiper)}
                >
                  <SwiperSlide />
                  <SwiperSlide>
                    <ReservationDetails
                      key={selectedBooking.id}
                      floor={floorList}
                      token={reducers.token}
                      selectedBooking={selectedBooking}
                      setSelectedBooking={setSelectedBooking}
                      statusOption={getBookingStatusOptions(
                        selectedBooking.status
                      )}
                      tableOptions={getBookingTableOptions(
                        selectedBooking?.table_lock
                      )}
                      saveBookingHandler={saveBookingHandler}
                      experienceList={experienceList}
                      shifts={shiftsData}
                    />
                  </SwiperSlide>
                  <SwiperSlide />
                </Swiper>
              </Box>
            )}
            <Grid
              item
              style={{
                width: `calc(99% - ${sideBarWidth}px)`,
                position: "relative",
              }}
              className="table-floor-containerWrapper"
            >
              <div className="table-floor-container">
                <div className="table-floor">
                  {tableList.length > 0 &&
                    tableList.map((table) => (
                      <div
                        key={Math.random()}
                        className="table-container"
                        style={{
                          position: "absolute",
                          top: table.pos_y * 0.6,
                          left: table.pos_x * 0.6,
                          color: "white",
                          fontSize: 10,
                        }}
                      >
                        <TableComponent
                          key={table.id}
                          table={table}
                          tableList={tableList}
                          bookingsList={bookingsList}
                          showTimeline={showTimeline}
                          selectedTableId={selectedTableId}
                          selectedShift={selectedShift}
                          orderLists={orderLists}
                          showTableDetailsHandler={showTableDetailsHandler}
                        />
                      </div>
                    ))}
                </div>
                <div className="table-floor-bottom">
                  {selectedFloor && (
                    <FloorDropDown
                      selectedFloor={selectedFloor}
                      floors={floorList}
                      selectFloorCallBack={selectFloorCallBack}
                    />
                  )}
                  <div
                    className="btn-timeline"
                    onClick={() => setShowTimeline(!showTimeline)}
                  >
                    <img
                      src={
                        showTimeline ? btnTimelineSelectedIcon : btnTimelineIcon
                      }
                      width="25"
                      height="25"
                      className="d-inline"
                      alt="checker"
                    />
                  </div>
                  <div
                    className="btn-pos"
                    onClick={() => setPosScreen(!posScreen)}
                    style={{
                      backgroundColor: posScreen ? "#ff7383" : "#fff",
                    }}
                  >
                    <img
                      src={btnPosIcon}
                      width="25"
                      height="25"
                      className="d-inline"
                      alt="checker"
                      style={{
                        border: "2px solid #fff",
                        borderRadius: "100px",
                      }}
                    />
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        ) : viewState === 2 ? (
          <TimeLine
            showTableDetailsHandler={showTableDetailsHandler}
            saveBookingHandler={saveBookingHandler}
            token={reducers.token}
            bookingList={bookingsList}
            floorList={floorList}
            bookingsList={bookingsList}
            selectedTableData={selectedTableData}
            tableDetailsData={tableDetailsData}
            selectedBooking={selectedBooking}
            isChangingTable={isChangingTable}
            selectedTableId={selectedTableId}
            selectedShift={selectedShift}
            setIsChangingTable={setIsChangingTable}
            setShowTableDetails={setShowTableDetails}
            setSelectedTableId={setSelectedTableId}
            selectedBookingHandler={setSelectedBooking}
          />
        ) : (
          viewState === 3 && (
            <WaitList
              floorList={floorList}
              tableList={tableList}
              bookingsList={bookingsList}
              showTimeline={showTimeline}
              selectedTableId={selectedTableId}
              selectedFloor={selectedFloor}
              selectFloorCallBack={selectFloorCallBack}
              setShowTimeline={setShowTimeline}
            />
          )
        )}
      </Container>
      {openPosScreen && (
        <PosModal
          employee={employeesData[0]}
          isOpen={openPosScreen}
          handleClose={() => setOpenPosScreen(false)}
          booking={posModalBooking}
          table={posModalTable}
          selectedFloor={selectedFloor}
          fetchData={() => fetchData()}
          registeredProducts={registeredProducts}
          orderLists={
            orderLists.filter(
              (order) => order.booking_uuid === posModalBooking?.uuid
            )
              ? orderLists.filter(
                  (order) => order.booking_uuid === posModalBooking?.uuid
                )[0]
              : null
          }
        />
      )}
      {/* @ts-ignore */}
      <AvailabilityModal
        isOpen={availabilityModal}
        handleClose={() => setAvailabilityModal(false)}
        bookingDate={bookingDate}
        setBookingDate={setBookingDate}
        floors={floors}
        setTableData={setTableData}
        tables={tables}
        setTable={setTable}
        timeslots={timeslots}
        setTimeslot={setTimeslot}
        partySizes={partySizes}
        setPartySize={setPartySize}
        checkAvailability={checkAvailability}
        checking={checking}
      />
    </Fragment>
  );
};

export default Booking;
