import React, { useEffect, useState } from "react";
import { Box, Typography, Modal } from "@mui/material";
import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/css";

const ColorPickerModal = ({ open, setOpenColorPickerModal, sendColor }) => {
  const [color, setColor] = useColor("#561ecb");

  const handleClose = () => {
    setOpenColorPickerModal(false);
  };

  useEffect(() => {
    console.log(color.hex);
    sendColor(color.hex);
  }, [color]);

  return (
    <Modal
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      open={open}
      onClose={handleClose}
    >
      <Box
        sx={{
          backgroundColor: "#282c34",
          borderRadius: "10px",
          padding: "20px",
          width: "50%",
        }}
      >
        <Typography variant="h5" sx={{ color: "#fff" }}>
          CHOOSE A COLOUR
        </Typography>
        <ColorPicker
          hideInput={["rgb", "hsv"]}
          color={color}
          onChange={setColor}
        />
      </Box>
    </Modal>
  );
};

export default ColorPickerModal;
