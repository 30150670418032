import styled from "styled-components";
import { Box, Typography, Button } from "@mui/material";
import colors from "./ColorStyled";

// ::::::::: Index Styles :::::::::

export const StyledModalBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 90%;
  background: ${colors.darkPrimary};
  box-shadow: 24px;
  border-radius: 12px;
  padding: 32px;
  display: flex;
  flex-direction: column;
`;

export const Header = styled(Box)`
  color: ${colors.white};
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${colors.darkSecondary};
  border-radius: 12px;
  padding: 8px;
  margin-bottom: 16px;
`;

export const HeaderTypography = styled(Typography)`
  font-size: 1rem;
  font-weight: bold;
`;

export const ScheduleInfoContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
`;

export const ScheduleDateRange = styled(Typography)`
  font-size: 1.3rem;
  font-weight: bold;
  color: ${colors.white};
`;

export const EmployeesCarouselWrapper = styled(Box)`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  gap: 8px;
  overflow: hidden;
`;

export const EmployeesSortContainer = styled(Box)`
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  padding: 8px 0;
  gap: 8px;
  scroll-behavior: smooth;
  width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const EmployeeCapsule = styled(Box)`
  display: flex;
  align-items: center;
  background: ${colors.darkSecondary};
  color: ${colors.white};
  border-radius: 50px;
  padding: 8px 16px;
  flex-shrink: 0;
  white-space: nowrap;
  min-width: fit-content;
  font-size: 0.7rem;
  font-weight: bold;
  cursor: pointer;
`;

export const EmployeeIcon = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.color || colors.tertiary};
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-weight: bold;
  margin-right: 8px;
  color: ${colors.white};
`;

export const ArrowButton = styled(Button)`
  color: ${colors.white};
  border-radius: 50px;
  min-width: auto;
  width: 32px;
  height: 32px;
  padding: 12px;
  cursor: pointer;
  background: ${colors.darkTertiary};
  z-index: 10;
`;

export const FilterSection = styled(Box)`
  align-items: center;
  justify-content: space-between;
  display: block;

  @media (min-width: 768px) {
    display: flex;
    gap: 16px;
  }
`;

export const FilterAll = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  gap: 8px;
  color: ${colors.white};
`;

export const FilterLabel = styled(Typography)`
  font-size: 0.8rem;
  font-weight: bold;
  color: ${colors.white};
`;

export const FilterOption = styled(Box)`
  background: ${colors.darkSecondary};
  padding: 8px 40px;
  border-radius: 50px;
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: bold;
  color: ${colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const ViewSwitchTabsContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;
export const ViewSwitchTabs = styled(Box)`
  display: flex;
  align-items: center;
  background: ${colors.darkSecondary};
  border-radius: 50px;
  padding: 4px 8px;
`;

export const ViewSwitchButton = styled(Button)`
  background: ${(props) => (props.active ? colors.white : "transparent")};
  color: ${(props) => (props.active ? colors.darkPrimary : colors.white)};
  padding: 6 16px;
  margin-right: 0;
  font-size: 0.6rem;
  font-weight: bold;
  border-radius: 50px;
  cursor: pointer;
`;

export const TabSeparator = styled(Box)`
  height: 100%;
  width: 1px;
  background: ${colors.white};
  margin: 0 8px;
`;

export const RightActionButtons = styled(Box)`
  display: flex;
  gap: 16px;

  & > button {
    background: ${colors.darkSecondary} !important;
    color: ${colors.white};
    font-size: 0.7rem;
    font-weight: bold;
    padding: 4px 16px;
    border-radius: 50px;
    &:hover {
      background: ${colors.darkTertiary};
    }
  }
`;

export const FooterActionButtons = styled(Box)`
  display: flex;
  gap: 16px;

  & > button {
    background: ${colors.darkSecondary};
    color: ${colors.white};
    font-size: 0.8rem;
    font-weight: bold;
    padding: 8px 0;
    width: 120px;
    border-radius: 8px;
    &:hover {
      background: ${colors.darkTertiary};
    }
  }
`;

// ::::::::: Week View Styles :::::::::

export const ScheduleDetailTypography = styled(Typography)`
  font-size: 0.8rem;
  font-weight: bold;
`;

export const DateHeader = styled.div`
  display: flex;
  color: ${colors.white};
  padding: 10px;
  border-bottom: 1px solid ${colors.white};
  margin-bottom: 8px;
`;
export const DateHeaderTypography = styled(Typography)`
  font-size: 0.7rem;
  font-weight: bold;
`;

export const EmployeeRow = styled.div`
  display: flex;
  margin-bottom: 8px;
  background: ${colors.darkSecondary};
  border-bottom: 1px solid #444;
  border-radius: 8px;
`;
export const EmployeeName = styled(Typography)`
  font-size: 0.8rem;
  font-weight: bold;
`;

export const ShiftCell = styled.div`
  flex: 1;
  text-align: center;
  background: ${(props) =>
    props.empty ? colors.limeGreen : colors.darkSecondary};
  height: auto;
  margin: 0 5px;

  border-radius: 4px;
  cursor: pointer !important;
`;

export const ShiftBlock = styled.div`
  background: ${(props) => {
    if (props.shifttype === 1) return colors.limeGreen;
    if (props.shifttype === 2) return colors.blue;
    if (props.shifttype === 3) return colors.darkTertiary;
    return colors.limeGreen; // デフォルトカラー
  }};
  color: ${(props) => {
    if (props.shifttype === 2 || props.shifttype === 3) return colors.white;
    return colors.darkPrimary; // デフォルトカラー
  }};
  padding: 8px 4px;
  margin-bottom: 8px;
  border-radius: 25px;
  font-size: 0.6rem;
  font-weight: bold;
  ${(props) =>
    props.empty &&
    `
    background: transparent;
    color: transparent;
  `}
`;

export const WeekViewContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SelectRoleBox = styled.select`
  margin-top: 8px;
  width: 250px;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
  position: absolute;
  z-index: 1000;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }
`;

// ::::::::: Day View Styles :::::::::

// ::::::::: Team View Styles :::::::::

// ::::::::: Floor View Styles :::::::::
