import React, { useState, useEffect } from "react";
import {
  Box,
  Modal,
  Typography,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import UploadIcon from "@mui/icons-material/Upload";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import { MdColorize } from "react-icons/md";
import {
  TextFieldStyled,
  ContainerStyled,
  AvatarStyled,
  ButtonStyled,
} from "../../StyledComponents";
import DetailsInfo from "./DetailsInfo";
import AccountInfo from "./AccountInfo";
import SecurityInfo from "./SecurityInfo";
import EmploymentInfo from "./EmploymentInfo";
import PayRates from "./PayRates";
import StaffPermissions from "./StaffPermissions";
import ColorPicker from "../../utility/ColorPicker.js";
import colors from "../../StyledComponents/ColorStyled";
import { daysOfWeek } from "../../../../../Shared/const.js";
import { useDispatch } from "react-redux";
import {
  addEmployeeRequest,
  deleteEmployeeRequest,
  updateEmployeeRequest,
  uploadEmployeePhotoRequest,
} from "../../../../../actions/employeeActions.js";

const AddEmployeeModal = ({
  onAddEmployee,
  employee,
  open,
  onClose,
  fetchEmployees,
  employees,
}) => {
  const initialEmployeeState = {
    first_name: "",
    last_name: "",
    address: "",
    mobile_no: "",
    emailAddress: "",
    date_hired: "",
    tax_no: "",
    bank_account: "",
    bank_name: "",
    quick_pin: "",
    system_pin: "",
    color: "#00FFFC",
    role: "staff",
    roles: [],
    contract: "SP",
    pay_rates: [
      { day_number: daysOfWeek.MONDAY, rate: 0 },
      { day_number: daysOfWeek.TUESDAY, rate: 0 },
      { day_number: daysOfWeek.WEDNESDAY, rate: 0 },
      { day_number: daysOfWeek.THURSDAY, rate: 0 },
      { day_number: daysOfWeek.FRIDAY, rate: 0 },
      { day_number: daysOfWeek.SATURDAY, rate: 0 },
      { day_number: daysOfWeek.SUNDAY, rate: 0 },
    ],
    pay_basis: 0,
    pay_cycle: 0,
    annual_salary: 0,
    hourly_rate: 0,
    hours_pay_cycle: 0,
    pay_wages_item: [],
    permissions: {
      home: 0,
      pos_setting: 0,
      team: 0,
    },
  };
  const dispatch = useDispatch();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [newEmployee, setNewEmployee] = useState(initialEmployeeState);
  const [file, setFile] = useState(null);
  const [fileObject, setFileObject] = useState(null);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (open) {
      setNewEmployee(initialEmployeeState);
      if (employee) {
        const s3Bucket =
          "https://wabi-staging.s3.ap-southeast-2.amazonaws.com/";
        const photoUrl = `${s3Bucket}${employee.photo}`;
        setFile(photoUrl);
      } else {
        setFile(null);
      }
      setCurrentStep(0);
      setError(""); // モーダルを開いた際にエラーをリセット
    }
    if (employee) {
      setNewEmployee({
        ...employee,
        first_name: employee.first_name || "",
        last_name: employee.last_name || "",
        address: employee.address || "",
        mobile_no: employee.mobile_no || "",
        emailAddress: employee.emailAddress || "",
        date_hired: employee.date_hired || "",
        tax_no: employee.tax_no || "",
        bank_account: employee.bank_account || "",
        bank_name: employee.bank_name || "",
        quick_pin: employee.quick_pin || "",
        system_pin: employee.system_pin || "",
        color: employee.color || "#00FFFC",
        role: employee.role || "staff",
        roles: [],
        contract: employee.contract || "",
        pay_basis: employee.pay_basis || "Hourly",
        pay_cycle: employee.pay_cycle || "Weekly",
        annual_salary: employee.annual_salary || 0,
        hourly_rate: employee.hourly_rate || 0,
        wage_pay_items: employee.wage_pay_items || [],
        permissions: {
          home: employee.permissions.home || 0,
          pos_setting: employee.permissions.pos_setting || 0,
          team: employee.permissions.team || 0,
        },
        pay_rates: employee.pay_rates || initialEmployeeState.pay_rates,
      });
    }
  }, [open, employee]);

  const checkDuplicatePins = () => {
    const duplicateQuickPin = employees.some(
      (emp) =>
        emp.id !== newEmployee.id && emp.quick_pin === newEmployee.quick_pin
    );
    const duplicateSystemPin = employees.some(
      (emp) =>
        emp.id !== newEmployee.id && emp.system_pin === newEmployee.system_pin
    );

    if (duplicateQuickPin) {
      setError("Quick Pin is already in use.");
      return false;
    }
    if (duplicateSystemPin) {
      setError("System Pin is already in use.");
      return false;
    }
    return true;
  };

  const handleFileChange = (e) => {
    const photoFile = e.target.files[0];
    setFile(URL.createObjectURL(photoFile));
    setFileObject(photoFile);
  };

  const handleAddEmployee = async () => {
    if (!checkDuplicatePins()) return;

    const uploadPhotoAndUpdateEmployee = async (employeeId) => {
      const params = { id: employeeId };
      if (fileObject) {
        try {
          await dispatch(uploadEmployeePhotoRequest(params, fileObject));
          console.log("Photo uploaded successfully:", fileObject);
        } catch (uploadError) {
          console.error("Photo upload failed:", uploadError);
          return;
        }
      }

      const updatedEmployee = {
        ...newEmployee,
        id: employeeId,
      };

      const callback = async (error, data) => {
        if (error) {
          console.error("Update failed:", error);
          return;
        }
        console.log("Employee updated successfully:", data);
        await fetchEmployees();
        onClose();
      };
      dispatch(updateEmployeeRequest(updatedEmployee, callback));
    };

    if (employee && employee.id) {
      await uploadPhotoAndUpdateEmployee(employee.id);
    } else {
      const callback = async (error, data) => {
        if (error) {
          console.error("Failed to add employee:", error);
          return;
        }
        const { staff } = data.data;
        console.log("Employee added successfully:", staff);
        await uploadPhotoAndUpdateEmployee(staff.id);
        await fetchEmployees();
        onClose();
      };
      dispatch(
        addEmployeeRequest(
          {
            ...newEmployee,
            pin: Math.floor(Math.random()),
            pin_confirmation: Math.floor(Math.random()),
          },
          callback
        )
      );
    }
  };

  const confirmDeleteEmployee = () => {
    const callback = async (error, data) => {
      if (error) {
        console.error("Failed to delete employee:", error);
      } else {
        onAddEmployee(null);
        await fetchEmployees();
        onClose();
      }
    };
    dispatch(deleteEmployeeRequest(employee, callback));
  };

  const steps = [
    {
      label: "Details Info",
      content: (
        <DetailsInfo
          newEmployee={newEmployee}
          setNewEmployee={setNewEmployee}
        />
      ),
    },
    {
      label: "Account Info",
      content: (
        <AccountInfo
          newEmployee={newEmployee}
          setNewEmployee={setNewEmployee}
        />
      ),
    },
    {
      label: "Security Info",
      content: (
        <SecurityInfo
          newEmployee={newEmployee}
          setNewEmployee={setNewEmployee}
        />
      ),
    },
    {
      label: "Employment Info",
      content: (
        <EmploymentInfo
          newEmployee={newEmployee}
          setNewEmployee={setNewEmployee}
        />
      ),
    },
    {
      label: "Pay Rates",
      content: (
        <PayRates
          payRates={newEmployee.pay_rates}
          setPayRates={(pay_rates) =>
            setNewEmployee({ ...newEmployee, pay_rates })
          }
        />
      ),
    },
    {
      label: "Staff Permissions",
      content: (
        <StaffPermissions
          newEmployee={newEmployee}
          setNewEmployee={setNewEmployee}
        />
      ),
    },
  ];

  return (
    <Modal
      open={open}
      aria-hidden={false}
      onClose={onClose}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ContainerStyled maxWidth="md">
        <Box
          color={colors.white}
          p={4}
          borderRadius={2}
          boxShadow={3}
          display="flex"
          height="800px"
          maxHeight="80vh"
        >
          <Box display="flex" flexDirection="column" alignItems="center" mr={4}>
            <AvatarStyled
              sx={{
                bgcolor: newEmployee.color,
              }}
              src={file ? file : undefined}
            >
              {!file && (
                <>
                  {newEmployee.first_name.charAt(0)}
                  {newEmployee.last_name.charAt(0)}
                </>
              )}
            </AvatarStyled>
            <ButtonStyled
              variant="contained"
              startIcon={<UploadIcon />}
              component="label"
              className="bg-primary neo-outer-glow"
              style={{
                margin: "30px 0 10px",
              }}
            >
              Upload Image
              <input
                type="file"
                hidden
                accept="image/*"
                onChange={handleFileChange}
              />
            </ButtonStyled>
            <ButtonStyled
              variant="contained"
              startIcon={<ColorLensIcon />}
              className="bg-primary neo-outer-glow"
              onClick={() => setShowColorPicker(!showColorPicker)}
            >
              Change Color
            </ButtonStyled>
            {showColorPicker && (
              <Modal
                open={showColorPicker}
                aria-hidden={false}
                onClose={() => setShowColorPicker(false)}
                style={{ background: "rgba(255,255,255,0.5)" }}
              >
                <Box
                  p={2}
                  borderRadius={2}
                  boxShadow={3}
                  position="relative"
                  mx="auto"
                  my={4}
                  width="fit-content"
                  className="bg-primary"
                >
                  <IconButton
                    size="small"
                    onClick={() => setShowColorPicker(false)}
                    style={{
                      position: "absolute",
                      top: 8,
                      right: 8,
                      color: colors.white,
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                  <Typography
                    variant="h6"
                    gutterBottom
                    style={{
                      marginBottom: 16,
                      color: colors.white,
                      fontSize: "12px",
                      fontWeight: "bold",
                    }}
                  >
                    <MdColorize style={{ fontSize: "18px" }} />
                    CHOOSE A COLOUR
                  </Typography>
                  <ColorPicker
                    color={newEmployee.color}
                    onChange={(color) =>
                      setNewEmployee({ ...newEmployee, color })
                    }
                  />
                </Box>
              </Modal>
            )}
            <Box mt={4} display="flex" flexDirection="column" width="100%">
              {employee && employee.id && (
                <ButtonStyled
                  variant="contained"
                  color="secondary"
                  startIcon={<DeleteIcon />}
                  className="bg-primary neo-outer-glow"
                  style={{
                    marginBottom: "10px",
                  }}
                  onClick={() => setShowDeleteConfirmation(true)}
                >
                  Delete
                </ButtonStyled>
              )}
              <ButtonStyled
                variant="contained"
                color="primary"
                onClick={handleAddEmployee}
                className="bg-primary neo-outer-glow"
              >
                Save
              </ButtonStyled>
              <Dialog
                open={showDeleteConfirmation}
                onClose={() => setShowDeleteConfirmation(false)}
              >
                <DialogTitle style={{ textAlign: "center" }}>
                  Confirmation
                </DialogTitle>
                <DialogContent>
                  <DialogContentText
                    style={{ textAlign: "center", fontSize: "14px" }}
                  >
                    Do you want to delete `{employee?.first_name}{" "}
                    {employee?.last_name}` on your staff list?
                  </DialogContentText>
                </DialogContent>

                <DialogActions style={{ justifyContent: "center" }}>
                  <Button
                    onClick={() => {
                      confirmDeleteEmployee();
                      setShowDeleteConfirmation(false);
                    }}
                    color="error"
                    sx={{ textTransform: "none" }}
                    style={{ width: "100%" }}
                  >
                    Delete
                  </Button>
                  <Button
                    onClick={() => setShowDeleteConfirmation(false)}
                    color="primary"
                    sx={{ textTransform: "none" }}
                    style={{ width: "100%" }}
                  >
                    Cancel
                  </Button>
                </DialogActions>
              </Dialog>
            </Box>
          </Box>
          <Box flex={1}>
            <Box display="flex" justifyContent="space-between" mb={2}>
              <TextFieldStyled
                label="First Name"
                value={newEmployee.first_name}
                onChange={(e) =>
                  setNewEmployee({
                    ...newEmployee,
                    first_name: e.target.value,
                  })
                }
                fullWidth
                margin="normal"
                variant="filled"
                style={{ marginRight: "10px" }}
              />
              <TextFieldStyled
                label="Last Name"
                value={newEmployee.last_name}
                onChange={(e) =>
                  setNewEmployee({
                    ...newEmployee,
                    last_name: e.target.value,
                  })
                }
                fullWidth
                margin="normal"
                variant="filled"
              />
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              height="86%"
            >
              <Box>
                <Typography variant="h6" mt={2} mb={1} textAlign="center">
                  {steps[currentStep].label}
                </Typography>
                {steps[currentStep].content}
              </Box>
              {error && (
                <Typography
                  variant="body2"
                  color="error"
                  style={{ textAlign: "center", marginTop: "10px" }}
                >
                  {error}
                </Typography>
              )}
              <Box
                display="flex"
                justifyContent={
                  currentStep === 0 ? "flex-end" : "space-between"
                }
                mt={2}
              >
                {currentStep > 0 && (
                  <ButtonStyled
                    variant="contained"
                    onClick={() =>
                      setCurrentStep((prev) => Math.max(prev - 1, 0))
                    }
                    className="bg-primary neo-outer-glow"
                    style={{
                      marginRight: "10px",
                    }}
                  >
                    Back
                  </ButtonStyled>
                )}
                {currentStep < steps.length - 1 && (
                  <ButtonStyled
                    variant="contained"
                    onClick={() =>
                      setCurrentStep((prev) =>
                        Math.min(prev + 1, steps.length - 1)
                      )
                    }
                    className="bg-primary neo-outer-glow"
                    style={{
                      marginLeft: "10px",
                    }}
                  >
                    Next
                  </ButtonStyled>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </ContainerStyled>
    </Modal>
  );
};

export default AddEmployeeModal;
