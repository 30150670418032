import React, { useState, useRef, useEffect } from "react";
import { BOOKINGSTATUS } from "../../../constants/enum";
import { getBookingStatusColor } from "../../../utils/Utility";

const baseStyle = {
  margin: 0,
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  overflow: "hidden",
};

const dropDownContainerStyle = {
  display: "flex",
  position: "relative",
  justifyContent: "center",
  width: "inherit",
};

const dropDownHeaderStyle = {
  display: "flex",
  alignItems: "center",
};

const dropDownListContainerStyle = {};

const dropDownListStyle = {
  position: "absolute",
  right: "0.5px",
  top: "0px",
  padding: 0,
  zIndex: 2,
};

const listItemStyle = {
  position: "relative",
  display: "flex",
  left: "0px",
  background: "#ffffff",
  listStyle: "none",
  cursor: "pointer",
  color: "black",
  alignItems: "center",
  justifyContent: "flex-start",
  margin: 0,
  padding: "8px",
  fontSize: "16px",
  color: "#000000ba",
};

function ReservationDropDown(props) {
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef(null);

  const toggling = () => {
    if (props.selectionDisabled) {
      return;
    }
    setIsOpen(!isOpen);
  };

  const setSelectedOption = (item) => {
    setIsOpen(!isOpen);
    props.setSelectedOption(item);
  };

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const getTextStyle = (value) => {
    if (value === BOOKINGSTATUS.overTime) {
      return {
        ...baseStyle,
        color: getBookingStatusColor(value, props.startDate),
      };
    }
    if (value === BOOKINGSTATUS.finished) {
      return { ...baseStyle, color: "#999999" };
    }
    if (value === BOOKINGSTATUS.cancelled || props.bookingStatus === 8) {
      return { ...baseStyle, color: "#707070" };
    }
    return { ...baseStyle, color: "white" };
  };

  return (
    <div style={dropDownContainerStyle} ref={wrapperRef}>
      <div style={dropDownHeaderStyle} onClick={toggling}>
        {!props.hideLeftIcon && (
          <img
            style={{ marginRight: 10 }}
            src={props.selectedOption.icon}
            width="20"
            height="20"
            alt="checker"
          />
        )}
        <span
          className="tableName"
          style={getTextStyle(props.selectedOption.value)}
        >
          {props.selectedOption === null
            ? "- No Selected Status -"
            : typeof props.selectedOption === "object"
            ? props.selectedOption.name
            : props.selectedOption}
        </span>
      </div>

      {isOpen && (
        <div style={dropDownListContainerStyle}>
          <ul style={dropDownListStyle}>
            {props.options.map((item) => (
              <li
                style={listItemStyle}
                onClick={() => setSelectedOption(item)}
                key={item.value}
              >
                {item.icon && (
                  <img
                    style={{ marginRight: 10 }}
                    src={item.icon}
                    width="20"
                    height="20"
                    alt="checker"
                  />
                )}
                {typeof item === "object" ? item.name : item}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default ReservationDropDown;
