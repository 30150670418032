import React from "react";
import { TextFieldStyled } from "../../StyledComponents";

const SecurityInfo = ({ newEmployee, setNewEmployee }) => {
  return (
    <>
      <TextFieldStyled
        label="Quick Pin"
        value={newEmployee.quick_pin}
        onChange={(e) =>
          setNewEmployee({ ...newEmployee, quick_pin: e.target.value })
        }
        fullWidth
        margin="normal"
        variant="filled"
      />
      <TextFieldStyled
        label="System Pin"
        value={newEmployee.system_pin}
        onChange={(e) =>
          setNewEmployee({ ...newEmployee, system_pin: e.target.value })
        }
        fullWidth
        margin="normal"
        variant="filled"
      />
    </>
  );
};

export default SecurityInfo;
