import React, { useEffect, useState, useRef } from "react";
import { Box, Button, TextField, Modal } from "@mui/material";
import moment from "moment";
import {
  getBookingStatusColor,
  getFullname,
  imgUrl,
  getRelativeLuminance,
} from "../../utils/Utility";
import posIcon from "../../images/logo.svg";
import { v4 as uuid } from "uuid";
import { useDispatch } from "react-redux";
import { BOOKINGSTATUS } from "../../constants/enum";
import { addOrderRequest } from "../../../../actions/orderListActions";
import AddonModal from "./AddonModal.jsx";
import ProductModal from "./ProductModal.jsx";
import ModifierModal from "./ModifierModal.jsx";
import AddOnsSection from "./AddOnsSection.jsx";
import ProductGrid from "./ProductGrid.jsx";
import OrderLists from "./OrderLists.jsx";
import AllModsModal from "./AllModsModal.jsx";
const styles = {
  iconButton: {
    color: "#fff",
    fontSize: "16px",
    fontWeight: "bold",
    height: "40px",
    width: "100px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 4,
  },
  paper: {
    background: "transparent",
    textAlign: "center",
    color: "#fff",
    height: "100%",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    position: "relative",
    backgroundSize: "cover",
  },
  productImg: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    position: "absolute",
    left: 0,
    top: 0,
  },
  gridItem: {
    borderRadius: "4px",
    overflow: "hidden",
    width: "16%",
    height: "80px",
    cursor: "pointer",
  },
};
const currentTimestamp = String(Date.now() * 1000);

const PosModal = ({
  employee,
  isOpen,
  table,
  booking,
  selectedFloor,
  handleClose,
  fetchData,
  registeredProducts,
  orderLists,
}) => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [showAddOns, setShowAddOns] = useState(false);
  const [addOnType, setAddOnType] = useState(0); // 0 for plus, 1 for minus
  const [openAddonModal, setOpenAddonModal] = useState(false);
  const [openProductModal, setOpenProductModal] = useState(false);
  const [selectedAddOns, setSelectedAddOns] = useState([]);
  const [productOnAddOnModal, setProductOnAddOnModal] = useState();
  const partySize = booking ? booking.party_size : table.capacity_max;
  const [modalOrderListProducts, setModalOrderListProducts] = useState(
    orderLists
      ? orderLists.products.sort((a, b) => {
          return a.currentTimestamp - b.currentTimestamp;
        })
      : []
  );
  const [openModifierModal, setOpenModifierModal] = useState(false);
  const [editAddon, setEditAddon] = useState(null);
  const [openAllModsModal, setOpenAllModsModal] = useState(false);
  const searchRef = useRef(null);
  const addOnRef = useRef(null);
  const contentRef = useRef(null);
  const [productWrapperHeight, setProductWrapperHeight] = useState(0);
  useEffect(() => {
    if (isOpen) {
      console.log(111);
      requestAnimationFrame(() => {
        console.log(2);
        console.log(addOnRef);
        if (searchRef.current && addOnRef.current) {
          console.log(3);
          const headerHeight = searchRef.current.offsetHeight;
          const footerHeight = addOnRef.current.offsetHeight;
          const wrapperHeight = contentRef.current.offsetHeight;
          console.log(headerHeight, footerHeight, wrapperHeight);
          setProductWrapperHeight(
            wrapperHeight - headerHeight - footerHeight - 32 - 16
          );
        }
      });
    }
  }, [isOpen]);
  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredProducts = searchQuery
    ? registeredProducts.flatMap((category) =>
        category.products.filter((product) =>
          product.title.toLowerCase().includes(searchQuery.toLowerCase())
        )
      )
    : selectedCategory
    ? selectedCategory.products
    : [];

  const dispatch = useDispatch();
  const now = moment();
  const handleAddOrder = (newBooking = null) => {
    const baseParams = {
      employee_id: employee.id,
      products: modalOrderListProducts,
      guest: {},
      pos_device_id: 0,
      status: (newBooking || booking).status,
      uuid: uuid(),
    };

    const params = newBooking
      ? {
          ...baseParams,
          booking_id: newBooking.id,
          booking_uuid: newBooking.uuid,
          table_id: newBooking.table_ids[0],
        }
      : orderLists
      ? {
          ...orderLists,
          status: booking.status,
          products: modalOrderListProducts,
          employee_id: employee.id,
        }
      : {
          ...baseParams,
          booking_id: booking.id,
          booking_uuid: booking.uuid,
          table_id: booking.table[0].id,
        };

    const callback = (error, orderList) => {
      if (error) {
        console.error(error);
      } else {
        fetchData();
      }
      handleClose();
    };

    dispatch(addOrderRequest(params, callback));
  };

  useEffect(() => {
    if (registeredProducts.length > 0) {
      setSelectedCategory(registeredProducts[0]);
    }
  }, [registeredProducts]);

  const bookingTime = booking
    ? moment(booking.start_date, "YYYY-MM-DD HH:mm:ss").format("hh:mm A")
    : now.format("hh:mm A");
  const guestName = getFullname(booking?.guest);

  const handleAddOn = (addOn) => {
    const newAddOn = {
      category_id: addOn.pos_product_category_id,
      id: addOn.id,
      name: addOn.name,
      price: addOn.price,
      quantity: 1,
      status: addOn.status,
      type: addOn.type,
    };
    console.log("adgadg");

    setModalOrderListProducts((prevProducts) => {
      console.log("00000");
      let targetProduct = prevProducts[prevProducts.length - 1];
      if (!targetProduct.addOns) {
        targetProduct.addOns = [];
      }
      const existingAddOnIndex = targetProduct.addOns.findIndex(
        (item) =>
          item.category_id === newAddOn.category_id && item.id === newAddOn.id
      );
      if (existingAddOnIndex > -1) {
        console.log("111111");
        targetProduct.addOns[existingAddOnIndex].quantity += 1;
      } else {
        targetProduct.addOns.push(newAddOn);
      }
      console.log("99999");
      return [...prevProducts];
    });
  };

  const handleAddOnToggle = (type) => {
    setAddOnType(type);
    if (addOnType === type) {
      setShowAddOns(!showAddOns);
    }
  };

  const getAddOns = () => {
    if (!selectedCategory) return [];
    return selectedCategory.add_ons.filter(
      (add_on) => add_on.type === addOnType
    );
  };
  const getSum = () => {
    let sum = 0;
    modalOrderListProducts?.forEach((product) => {
      product?.addOns?.forEach((addOn) => {
        sum += addOn?.price * addOn?.quantity;
      });
      sum += product?.price * product?.quantity;
    });
    return sum;
  };

  const addProductToOrderList = (product) => {
    if (product.is_pop_up === 1) {
      setProductOnAddOnModal(product);
      setSelectedAddOns(
        registeredProducts.find(
          (rproduct) => rproduct.id === product.pos_product_category_id
        )?.add_ons
      );
      setOpenAddonModal(true);
      return;
    }

    const productItem = {
      addOns: [],
      category_id: product.pos_product_category_id,
      code: product.code,
      created_at: product.created_at,
      currentTimestamp: currentTimestamp,
      description: product.description,
      id: product.id,
      is_printed: 0,
      note: "",
      order: product.order,
      price: product.price,
      quantity: 1,
      isCancelled: false,
      is_deleted: false,
      is_pop_up: false,
      status: product.status,
      stock: product.stock,
      title: product.title,
      updated_at: product.updated_at,
      uuid: uuid(),
    };
    setModalOrderListProducts((prevProducts) => {
      const existingProduct = prevProducts.find(
        (item) =>
          item.currentTimestamp === currentTimestamp && item.id === product.id
      );
      if (existingProduct) {
        return prevProducts.map((item) =>
          item.currentTimestamp === currentTimestamp && item.id === product.id
            ? { ...item, quantity: item.quantity + 1 }
            : item
        );
      } else {
        return [...prevProducts, productItem];
      }
    });
  };

  const statusColor = booking
    ? getBookingStatusColor(booking.status, booking.start_date)
    : getBookingStatusColor(BOOKINGSTATUS.seated);

  const [longPressTimeout, setLongPressTimeout] = useState(null);
  const [isLongPress, setIsLongPress] = useState(false);
  const [editProduct, setEditProduct] = useState(null);

  const handleLongPress = (val, target) => {
    if (target === "product") {
      setEditProduct(val);
      setOpenProductModal(true);
    } else if (target === "addOn") {
      setEditAddon(val);
      setOpenModifierModal(true);
    }
  };

  const handleMouseDown = (val, target) => {
    setIsLongPress(false);
    const timeout = setTimeout(() => {
      setIsLongPress(true);
      handleLongPress(val, target);
    }, 500);
    setLongPressTimeout(timeout);
  };

  const handleMouseUp = (val, target) => {
    clearTimeout(longPressTimeout);
    if (!isLongPress) {
      if (target === "product") {
        addProductToOrderList(val);
      } else if (target === "addOn") {
        handleAddOn(val);
      }
    }
  };
  const handleAddModifier = (selectedAddOns) => {
    setModalOrderListProducts((prevProducts) => {
      let targetProduct = prevProducts[prevProducts.length - 1];
      if (!targetProduct?.addOns) {
        targetProduct.addOns = [];
      }
      selectedAddOns.forEach((addOn) => {
        const newAddOn = {
          category_id: addOn.category_id,
          id: addOn.id,
          name: addOn.name,
          price: addOn.price,
          quantity: 1,
          status: addOn.status,
          type: addOn.type,
        };
        const existingAddOnIndex = targetProduct?.addOns.findIndex(
          (item) =>
            item.category_id === newAddOn.category_id && item.id === newAddOn.id
        );
        if (existingAddOnIndex > -1) {
          targetProduct.addOns[existingAddOnIndex].quantity += 1;
        } else {
          targetProduct.addOns.push(newAddOn);
        }
      });
      return [...prevProducts];
    });
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      sx={{ padding: 0, margin: 0, overflow: "auto" }}
    >
      <Box
        display="flex"
        flexDirection="column"
        bgcolor="background.paper"
        width="100%"
        height="100%"
        borderRadius={1}
      >
        <Box display="flex" sx={{ height: "100%" }} color="white">
          <OrderLists
            guestName={guestName}
            statusColor={statusColor}
            table={table}
            partySize={partySize}
            selectedFloor={selectedFloor}
            bookingTime={bookingTime}
            modalOrderListProducts={modalOrderListProducts}
            getSum={getSum}
          />
          <Box
            width="calc( 100% - 300px )"
            flexGrow={1}
            bgcolor="#1a1f23"
            color="white"
            ref={contentRef}
            p={2}
            className="pos-modal-menu flex flex-col"
          >
            <Box ref={searchRef} className="pb-4">
              <TextField
                variant="outlined"
                placeholder="Search Product"
                fullWidth
                value={searchQuery}
                onChange={handleSearchChange}
                InputProps={{
                  style: { backgroundColor: "#1a1f23", color: "#a2a2a3" },
                }}
                className="rounded border border-solid border-gray"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& input": {
                      padding: "8px 14px",
                    },
                  },
                }}
              />
            </Box>
            <Box
              className="flex gap-5"
              sx={{ height: `${productWrapperHeight}px` }}
            >
              <ProductGrid
                filteredProducts={filteredProducts}
                styles={styles}
                imgUrl={imgUrl}
                handleMouseDown={handleMouseDown}
                handleMouseUp={handleMouseUp}
                setOpenProductModal={setOpenProductModal}
                getRelativeLuminance={getRelativeLuminance}
                height={productWrapperHeight}
              />
              <Box
                sx={{ overflow: "auto", height: `${productWrapperHeight}px` }}
                justifyContent="space-around"
                className="flex flex-col gap-2 min-w-32"
              >
                {registeredProducts.map((category) => (
                  <Button
                    key={category.id}
                    sx={{ color: "#fff" }}
                    onClick={() => handleCategoryClick(category)}
                    className={[
                      selectedCategory?.id === category?.id
                        ? "bg-purple border-purple"
                        : "bg-grayDarker border-gray",
                      "w-28",
                      "min-h-16",
                      "border",
                      "border-solid",
                      "border-gray",
                      "text-xs font-bold",
                      "rounded-lg",
                    ].join(" ")}
                  >
                    {category.name}
                  </Button>
                ))}
              </Box>
            </Box>
            <AddOnsSection
              ref={addOnRef}
              styles={styles}
              handleAddOnToggle={handleAddOnToggle}
              showAddOns={showAddOns}
              getAddOns={getAddOns}
              handleMouseDown={handleMouseDown}
              handleMouseUp={handleMouseUp}
              setOpenModifierModal={setOpenModifierModal}
              setOpenAllModsModal={setOpenAllModsModal}
            />
            <Box
              sx={{
                position: "fixed",
                right: "16px",
                bottom: "16px",
                background: "#fff",
                borderRadius: "4px",
              }}
            >
              <Button
                sx={{
                  minWidth: "40px",
                }}
                onClick={() =>
                  modalOrderListProducts.length > 0
                    ? handleAddOrder()
                    : handleClose()
                }
              >
                <img
                  src={posIcon}
                  width="25"
                  height="25"
                  className="d-inline rounded-full border-2 border-solid border-white"
                  alt="checker"
                />
              </Button>
            </Box>
          </Box>
        </Box>
        {openAddonModal && (
          <AddonModal
            open={openAddonModal}
            setOpenAddonModal={setOpenAddonModal}
            addOns={selectedAddOns}
            productOnAddOnModal={productOnAddOnModal}
            setModalOrderListProducts={setModalOrderListProducts}
          />
        )}
        {openProductModal && (
          <ProductModal
            open={openProductModal}
            setOpenProductModal={setOpenProductModal}
            selectedCategory={selectedCategory}
            editProduct={editProduct}
            setEditProduct={setEditProduct}
            fetchData={fetchData}
          />
        )}
        {openModifierModal && (
          <ModifierModal
            open={openModifierModal}
            setOpenModifierModal={setOpenModifierModal}
            editAddon={editAddon}
            setEditAddon={setEditAddon}
            fetchData={fetchData}
            registeredProducts={registeredProducts}
          />
        )}
        {openAllModsModal && (
          <AllModsModal
            open={openAllModsModal}
            registeredProducts={registeredProducts}
            handleClose={() => setOpenAllModsModal(false)}
            handleAddModifier={handleAddModifier}
            fetchData={fetchData}
            propSelectedCategory={selectedCategory}
          />
        )}
      </Box>
    </Modal>
  );
};

export default PosModal;
