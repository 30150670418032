import {
  MenuBookOutlined,
  LocalLibraryOutlined,
  PrintOutlined,
  QuestionAnswerOutlined,
  EditCalendarOutlined,
  AssessmentOutlined,
  StorefrontOutlined,
  GroupOutlined,
  ComputerOutlined,
  SmartphoneOutlined,
} from "@mui/icons-material";
export const menuItems = [
  {
    title: "Front of House",
    url: "/front-of-house",
    icon: <MenuBookOutlined />,
  },
  {
    title: "Guest Book",
    url: "/guest-book",
    icon: <LocalLibraryOutlined />,
  },
  {
    title: "Printer Settings",
    url: "/printer-settings",
    icon: <PrintOutlined />,
  },
  {
    title: "Messages",
    url: "/messages",
    icon: <QuestionAnswerOutlined />,
  },
  {
    title: "Table Layout and Planning",
    url: "/table-layout-and-planning",
    icon: <EditCalendarOutlined />,
  },
  {
    title: "Report",
    url: "/report",
    icon: <AssessmentOutlined />,
  },
  // {
  //   title: "Experience Sales Report",
  //   url: "/experience-sales-report",
  //   icon: <ConfirmationNumberOutlined />
  // },
  {
    title: "Restaurant Profile",
    url: "/restaurant-profile",
    icon: <StorefrontOutlined />,
  },
  {
    title: "Team",
    url: "/team",
    icon: <GroupOutlined />,
  },
  {
    title: "POS Settings",
    url: "/pos-settings",
    icon: <ComputerOutlined />,
  },
  {
    title: "Windcave",
    url: "/windcave",
    icon: <SmartphoneOutlined />,
  },
  {
    title: "Novatti",
    url: "/novatti",
    icon: <SmartphoneOutlined />,
  },
  {
    title: "MYOB Settings",
    url: "/myob-settings",
  },
  {
    title: "Xero Settings",
    url: "/xero-settings",
  },
];
