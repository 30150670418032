export const daysOfWeek = {
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
  SUNDAY: 7,
};
export const STAFF_HOME_PERMISSIONS = {
  // DEFAULT: 0,
  MESSAGES: 1 << 2,
  TABLE_LAYOUT_AND_PLANNING: 1 << 3,
  REPORTS: 1 << 4,
  EXPERIENCE_SALES_REPORT: 1 << 5,
  RESTAURANT_PROFILE: 1 << 6,
  TEAM: 1 << 7,
  POS_SETTINGS: 1 << 8,
  INTEGRATIONS: 1 << 9,
  PRINTERS: 1 << 10,
};

export const STAFF_POS_PERMISSIONS = {
  // DEFAULT: 0,
  DOCKET_VIEW: 2 << 1,
  DAY_VIEW_SALES: 2 << 2,
  ANALYTICS: 2 << 3,
  HARDWARE: 2 << 4,
  STAFF_ACCESS: 2 << 5,
  REGISTER_SETTINGS: 2 << 6,
  DELETED_ITEMS: 2 << 7,
  DISABLED_PRODUCT_ITEMS: 2 << 10,
  POS_NAME: 2 << 8,
  SURCHARGE_SETTINGS: 2 << 9,
  CASH_DRAWER: 2 << 11,
  ON_ACCOUNT: 2 << 12,
};

export const STAFF_TEAM_PERMISSIONS = {
  // DEFAULT: 0,
  TEAM: 1 << 1,
  ROSTER: 1 << 2,
  TIMESHEET: 1 << 3,
  SETTINGS: 1 << 4,
};

export function getPermissionName(permission) {
  switch (permission) {
    case STAFF_HOME_PERMISSIONS.MESSAGES:
      return "Messages";
    case STAFF_HOME_PERMISSIONS.TABLE_LAYOUT_AND_PLANNING:
      return "Table Layout and Planning";
    case STAFF_HOME_PERMISSIONS.REPORTS:
      return "Reports";
    case STAFF_HOME_PERMISSIONS.EXPERIENCE_SALES_REPORT:
      return "Experience Sales Report";
    case STAFF_HOME_PERMISSIONS.RESTAURANT_PROFILE:
      return "Restaurant Profile";
    case STAFF_HOME_PERMISSIONS.TEAM:
      return "Team";
    case STAFF_HOME_PERMISSIONS.POS_SETTINGS:
      return "POS Settings";
    case STAFF_HOME_PERMISSIONS.INTEGRATIONS:
      return "Integrations";
    case STAFF_HOME_PERMISSIONS.PRINTERS:
      return "Printer Settings";
    default:
      return "No name";
  }
}
export function checkStaffHomePermissions(value) {
  const permissions = [];

  if (value & STAFF_HOME_PERMISSIONS.MESSAGES) {
    permissions.push("Messages");
  }
  if (value & STAFF_HOME_PERMISSIONS.TABLE_LAYOUT_AND_PLANNING) {
    permissions.push("Table Layout and Planning");
  }
  if (value & STAFF_HOME_PERMISSIONS.REPORTS) {
    permissions.push("Reports");
  }
  if (value & STAFF_HOME_PERMISSIONS.EXPERIENCE_SALES_REPORT) {
    permissions.push("Experience Sales Report");
  }
  if (value & STAFF_HOME_PERMISSIONS.RESTAURANT_PROFILE) {
    permissions.push("Restaurant Profile");
  }
  if (value & STAFF_HOME_PERMISSIONS.TEAM) {
    permissions.push("Team");
  }
  if (value & STAFF_HOME_PERMISSIONS.POS_SETTINGS) {
    permissions.push("POS Settings");
  }
  if (value & STAFF_HOME_PERMISSIONS.INTEGRATIONS) {
    permissions.push("Integrations");
  }
  if (value & STAFF_HOME_PERMISSIONS.PRINTERS) {
    permissions.push("Printers");
  }

  return permissions.length > 0 ? permissions : ["No Permissions"];
}
