export const getProductsRequest = (callback) => ({
  type: "GET_PRODUCTS_REQUEST",
  callback,
});
export const createProductRequest = (params, callback) => ({
  type: "CREATE_PRODUCT_REQUEST",
  params,
  callback,
});

export const addPhotoToProductRequest = (params, callback) => ({
  type: "ADD_PHOTO_TO_PRODUCT_REQUEST",
  params,
  callback,
});

export const createAddonRequest = (params, callback) => ({
  type: "CREATE_ADDON_REQUEST",
  params,
  callback,
});
