import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Switch,
  Modal,
} from "@mui/material";
import { v4 as uuid } from "uuid";
import { useDispatch } from "react-redux";
import {
  addPhotoToProductRequest,
  createProductRequest,
} from "../../../../actions/productsActions";
import "react-color-palette/css";
import ColorPickerModal from "./ColorPickerModal";
import { imgUrl, getRelativeLuminance } from "../../utils/Utility";
const CustomTextField = ({ label, value, onChange }) => (
  <Box className="p-6 flex items-center mb-2 border-t border-solid border-gray">
    <Typography className="text-white text-sm min-w-72">{label}</Typography>
    <TextField
      fullWidth
      placeholder={label}
      variant="outlined"
      value={value}
      onChange={onChange}
      className="text-white rounded border border-solid border-gray"
      sx={{
        "& .MuiOutlinedInput-root": {
          color: "#fff",
          "& fieldset": {
            border: "none",
          },
          "& input": {
            color: "#fff",
          },
          "& ::placeholder": {
            color: "#a9a9a9",
          },
        },
      }}
    />
  </Box>
);

const ProductModal = ({
  open,
  setOpenProductModal,
  selectedCategory,
  editProduct = null,
  setEditProduct,
  fetchData,
}) => {
  const dispatch = useDispatch();
  const {
    code,
    color,
    photo,
    is_pop_up,
    price: editPrice,
    pos_product_category_id,
    title,
    id: editId,
    stock,
    description,
  } = editProduct || {};
  const [productName, setProductName] = useState(title || "");
  const [productCode, setProductCode] = useState(code || "");
  const [productStock, setProductStock] = useState(stock || "");
  const [productDescription, setProductDescription] = useState(
    description || ""
  );
  const [ingredients, setIngredients] = useState("");
  const [price, setPrice] = useState(editPrice || "");
  const [image, setImage] = useState(photo ? imgUrl + photo : null);
  const [fileObject, setFileObject] = useState(null);
  const [forcePopup, setForcePopup] = useState(
    is_pop_up ? (is_pop_up === 0 ? false : true) : false
  );
  const [colorPickerModalOpen, setOpenColorPickerModal] = useState(false);
  const [productModalColor, setProductModalColor] = useState(color || "");
  const handleImageUpload = (event) => {
    setImage(URL.createObjectURL(event.target.files[0]));
    setFileObject(event.target.files[0]);
  };

  const handleAddProduct = () => {
    const product = {
      barcode: "",
      description: productDescription,
      is_deleted: false,
      order: 0,
      uuid: uuid(),
      price: Number(price),
      updated_at: new Date(),
      created_at: new Date(),
      is_pop_up: forcePopup === true ? 1 : 0,
      is_printed: 0,
      color: productModalColor,
      status: 1,
      code: productCode,
      category_id: selectedCategory.id,
      currentTimestamp: new Date(),
      note: "",
      isCancelled: false,
      addOns: selectedCategory.add_ons ? selectedCategory.add_ons : [],
      option_ids: [],
      quantity: 0,
      stock: Number(productStock),
      parent_category: selectedCategory.category_id,
      title: productName,
      photo: photo,
    };

    const callback = (error, product) => {
      if (!error) {
        if (fileObject) {
          const params = {
            id: product.id,
            image: fileObject,
          };
          dispatch(
            addPhotoToProductRequest(params, (error, response) => {
              if (!error) {
                console.log("Photo added to product");
              } else {
                console.log(response, "response");
              }
            })
          );
        }
      } else {
        console.error(error);
      }
      fetchData();
      handleClose();
    };

    if (editId) {
      product.id = editId;
    }

    dispatch(createProductRequest(product, callback));
  };

  const resetColor = () => {
    setProductModalColor("");
  };

  const handleClose = () => {
    setOpenProductModal(false);
    setEditProduct(null);
  };

  return (
    <Modal
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      open={open}
      onClose={handleClose}
    >
      <Box
        display="flex"
        flexDirection="column"
        bgcolor="#25292d"
        color="#fff"
        width="90%"
        height="90%"
        maxHeight="90vh"
        margin="auto"
        borderRadius={1}
        overflow="auto"
      >
        <Card className="w-full h-full bg-grayDark text-white">
          <CardContent className="relative">
            <Box className="pb-2">
              <Typography variant="h5">Add Product</Typography>
              <Typography variant="body2">Add product details here.</Typography>
            </Box>
            <Box
              sx={{ height: "calc( 90vh - 70px)" }}
              className="overflow-auto"
            >
              <Box className="flex flex-col items-center mb-4">
                {image ? (
                  <Box>
                    <CardMedia
                      component="img"
                      image={image || "https://via.placeholder.com/140"}
                      alt="Product Image"
                      className=" w-48 h-48 rounded-lg"
                    />
                  </Box>
                ) : (
                  <Box className="w-48 h-48 relative bg-grayLight rounded-lg flex items-center justify-center flex-col">
                    <Typography className="text-xl">
                      {productCode ? productCode : "AA"}
                    </Typography>
                    <Typography className="text-xs">
                      {productName ? productName : "Product Name"}
                    </Typography>
                  </Box>
                )}
                {!image && (
                  <Button
                    variant="outlined"
                    component="label"
                    className="mb-2 mt-2 text-white border-white w-48 font-bold normal-case"
                  >
                    Upload Image
                    <input type="file" hidden onChange={handleImageUpload} />
                  </Button>
                )}
                {image && (
                  <Button
                    variant="outlined"
                    component="label"
                    className="mb-2 mt-2 text-white border-white w-48 font-bold normal-case"
                    onClick={() => setImage(null)}
                  >
                    Remove Image
                  </Button>
                )}
                <Button
                  variant="outlined"
                  className="text-white border-white w-48 font-bold normal-case"
                  sx={{
                    backgroundColor: productModalColor
                      ? productModalColor + " !important"
                      : undefined,
                    color: productModalColor
                      ? getRelativeLuminance(productModalColor)
                      : "#fff",
                  }}
                  onClick={() =>
                    productModalColor
                      ? resetColor()
                      : setOpenColorPickerModal(true)
                  }
                >
                  {productModalColor ? "Remove color" : "Set Color"}
                </Button>
              </Box>
              <CustomTextField
                label="Product Name"
                value={productName}
                onChange={(e) => setProductName(e.target.value)}
              />
              <CustomTextField
                label="Price"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              />
              <CustomTextField
                label="Code"
                value={productCode}
                onChange={(e) => setProductCode(e.target.value)}
              />
              <CustomTextField
                label="Stock"
                value={productStock}
                onChange={(e) => setProductStock(e.target.value)}
              />
              <CustomTextField
                label="Description"
                value={productDescription}
                onChange={(e) => setProductDescription(e.target.value)}
              />
              <Box className="p-6 flex items-center mb-2 border-t border-solid border-gray">
                <Typography className="text-white text-sm min-w-72">
                  Ingredients
                </Typography>
                <Button
                  variant="outlined"
                  className="mb-4 text-white border-white"
                >
                  Add Ingredients
                </Button>
              </Box>

              <Box className="p-6 flex items-center mb-2 border-t border-solid border-gray">
                <Typography className="text-white text-sm min-w-72">
                  Turn on force popup
                </Typography>
                <Switch
                  checked={forcePopup}
                  onChange={() => setForcePopup(!forcePopup)}
                  color="secondary"
                  sx={{
                    "& .MuiSwitch-switchBase": {
                      color: "default",
                    },
                    "& .MuiSwitch-track": {
                      backgroundColor: "default",
                    },
                    "& .MuiSwitch-switchBase.Mui-checked": {
                      color: "#7af8c8", // Reset the color when checked, or choose another color
                    },
                    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                      backgroundColor: "#7af8c8", // Reset the background when checked, or choose another color
                    },
                  }}
                />
              </Box>
              <Box className="p-6 flex items-center mb-2 border-t border-solid border-gray">
                <Typography className="text-white text-sm min-w-72">
                  Select add-ons for force popup
                </Typography>
                <Box sx={{ height: "250px", overflow: "auto", mt: 2 }}>
                  <Box display="flex" flexWrap="wrap" gap={"8px 2%"}>
                    {selectedCategory?.add_ons &&
                      selectedCategory?.add_ons.map((addOn, index) => (
                        <Button
                          key={index}
                          sx={{
                            minWidth: "32%",
                            borderRadius: "8px",
                            fontSize: "12px",
                            flexDirection: "column",
                            lineHeight: "1.2",
                            justifyContent: "center",
                            height: "40px",
                          }}
                          className="text-white border border-solid border-gray"
                        >
                          {addOn.name}
                          <span
                            style={{
                              fontSize: "10px",
                            }}
                            className="text-purple"
                          >
                            {addOn.price > 0 ? `+ $${addOn.price}` : ""}
                          </span>
                        </Button>
                      ))}
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className="flex justify-between gap-4 absolute top-4 right-4 right-0">
              <Button
                variant="outlined"
                className="text-white border-white"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                className="bg-purple text-white"
                onClick={handleAddProduct}
              >
                Save
              </Button>
            </Box>
          </CardContent>
        </Card>
        {colorPickerModalOpen && (
          <ColorPickerModal
            open={colorPickerModalOpen}
            setOpenColorPickerModal={setOpenColorPickerModal}
            sendColor={setProductModalColor}
          />
        )}
      </Box>
    </Modal>
  );
};

export default ProductModal;
