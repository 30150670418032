import React, { useState, useEffect } from "react";
import { Box, Typography, Grid } from "@mui/material";
import moment from "moment";
import axios from "axios";
import { apiUrl } from "../../utils/Utility";

// Components
import TableDetails from "../TableDetails/TableDetails";
import CollapsableFloor from "./CollapsableFloor";
import DotLoader from "../TableLayoutPlanning/pages/SubComponents/DotLoader.tsx";

const styles = {
  mainContainer: {
    position: "relative",
    display: "inline-flex",
    color: "white",
    minHeight: 670,
    height: "auto",
    width: "100%",
    minWidth: 670,
    overflow: "hidden",
    userSelect: "none",
  },
  subContainer: {
    display: "inline-flex",
    flexDirection: "column",
    color: "white",
    maxHeight: 652,
    width: "100%",
    userSelect: "none",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: 10,
      height: 10,
      backgroundColor: "#222a2f",
    },
    "&::-webkit-scrollbar-thumb": {
      width: 10,
      height: 10,
      borderRadius: 20,
      backgroundColor: "#2a343a",
    },
    "&::-webkit-scrollbar-corner": {
      display: "none",
    },
  },
  headerRow: {
    display: "inline-flex",
    height: 41,
    width: "fit-content",
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    background: "#394044",
    borderBottom: "1px solid #222a2f",
    position: "sticky",
    top: 0,
    zIndex: 4,
  },
  headerSpan: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    minWidth: 150,
    borderRight: "1px solid #9a9a9a",
    background: "#394044",
    position: "sticky",
    left: 0,
    top: 0,
    zIndex: 3,
  },
  headerText: {
    position: "absolute",
    right: 10,
    fontSize: 14,
    fontWeight: 400,
    color: "#9d9d9d",
  },
  headerTime: {
    position: "absolute",
    left: -18,
    fontSize: 14,
    fontWeight: 400,
    color: "#9d9d9d",
    zIndex: 1,
  },
  headerBlock: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    height: 40,
    minWidth: 160,
    background: "#394044",
    overflow: "visible",
  },
  lineIndicator: {
    position: "absolute",
    left: -1,
    bottom: 1,
    height: 12,
    width: 2,
    background: "#9d9d9d",
    zIndex: 1,
  },
  tableDetailsWrapper: {
    position: "relative",
    height: "fit-content",
    marginRight: 0,
    transition: "width 200ms ease",
  },
  loadingContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    zIndex: 6,
  },
};

function TimeLine(props) {
  const [showTableDetails, setShowTableDetails] = useState(false);
  const [bookingsOnTable, setBookingsOnTable] = useState([]);
  const [tableDetailsData, setTableDetailsData] = useState("");
  const [selectedBooking, setSelectedBooking] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [bookingList, setBookingList] = useState([]);
  const [floors, setFloors] = useState([]);
  const hours = [];

  for (let i = -2; i <= 22; i++) {
    hours.push(moment(i * 3600 * 1000).format("HH"));
  }

  useEffect(() => {
    if (props.bookingList.length > 0) {
      setBookingList([...props.bookingList]);
    } else {
      setBookingList([]);
    }
    setFloors(props.floorList);
  }, [props.floorList, props.bookingList]);

  const toggleTableDetails = (booking) => {
    if (booking.id === undefined) {
      setShowTableDetails(!showTableDetails);
    } else {
      if (showTableDetails) {
        if (selectedBooking.id === booking.id) {
          setShowTableDetails(!showTableDetails);
        }
      } else {
        setShowTableDetails(!showTableDetails);
      }
    }
  };

  const handleBookingClick = (booking, table) => {
    let data = [booking];
    setSelectedBooking(booking);
    setBookingsOnTable(data);
    setTableDetailsData(table);
  };

  const changeTable = async (booking) => {
    if (isSaving) {
      return;
    }
    setIsSaving(true);
    setIsLoading(true);
    try {
      const response = await axios({
        url: `${apiUrl}/api/booking/update`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.token}`,
        },
        data: booking,
      });

      if (response) {
        const { status } = response;
        if (status === 200) {
          setIsLoading(false);
          setIsSaving(false);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box sx={styles.mainContainer}>
          <Box
            sx={{
              ...styles.tableDetailsWrapper,
              width: showTableDetails ? 540 : 0,
              marginRight: showTableDetails ? 20 : 0,
            }}
          >
            <TableDetails
              floorList={props.floorList}
              isChangingTable={props.isChangingTable}
              saveBookingHandler={props.saveBookingHandler}
              selectedBooking={null}
              selectedShift={props.selectedShift}
              selectedTableData={bookingsOnTable}
              selectedTableId={props.selectedTableId}
              setIsChangingTable={props.setIsChangingTable}
              setSelectedTableId={props.setSelectedTableId}
              setShowTableDetails={setShowTableDetails}
              showTableDetails={showTableDetails}
              showTableDetailsHandler={toggleTableDetails}
              tableDetailsData={tableDetailsData}
              token={props.token}
            />
          </Box>

          <Box sx={styles.subContainer}>
            <Box sx={styles.headerRow} id="timeline-header">
              <Box sx={styles.headerSpan}>
                <Typography variant="h4">Table</Typography>
                <Typography sx={styles.headerText}>Max</Typography>
              </Box>
              {hours.map((time, key) => (
                <Box key={key} sx={styles.headerBlock}>
                  <Typography sx={styles.headerTime}>
                    {moment(time, "HH").format("LT")}
                  </Typography>
                  {key !== 0 && <Box sx={styles.lineIndicator} />}
                </Box>
              ))}
            </Box>
            {floors.map((floor, key) => (
              <CollapsableFloor
                key={key}
                floor={floor}
                index={key}
                bookings={bookingList}
                toggleTableDetails={toggleTableDetails}
                bookingClickHandler={handleBookingClick}
                changeTable={changeTable}
              />
            ))}
          </Box>
          {isLoading && (
            <Box sx={styles.loadingContainer}>
              <DotLoader />
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  );
}

export default TimeLine;
