import React, { useEffect, useState, useCallback } from "react";
import SideBar from "./components/common/SideBar";
import Team from "./components/team/Team";
import Roster from "./components/roster/Roster";
import Timesheet from "./components/timesheet/Timesheet";
import Settings from "./components/settings/Settings";
import { Box } from "@mui/material";
import { useDispatch } from "react-redux";
import { Routes, Route } from "react-router-dom";
import { getEmployeesRequest } from "../../actions/employeeActions.js";
import "./styles/style.scss";

const TeamIndex = () => {
  const dispatch = useDispatch();
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchEmployees = useCallback(async () => {
    setLoading(true);
    setError(null);
    dispatch(
      getEmployeesRequest((err, data) => {
        if (err) {
          setError(err);
        } else {
          setEmployees(data);
        }
        setLoading(false);
      })
    );
  }, [dispatch]);

  useEffect(() => {
    fetchEmployees();
  }, [fetchEmployees]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <Box className="neumorphism-container body-wrapper" display="flex">
      <SideBar />
      <Box width="80%" p={2} minWidth="400px" marginLeft="320px">
        <Routes>
          <Route path="/" element={<Team />} />
          <Route path="roster" element={<Roster />} />
          <Route path="timesheet" element={<Timesheet />} />
          <Route path="settings" element={<Settings />} />
        </Routes>
      </Box>
    </Box>
  );
};

export default TeamIndex;
