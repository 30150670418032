import React from "react";
import { Grid, Select, Button, CircularProgress } from "@mui/material";
import DatePicker from "react-datepicker";
import moment from "moment";
import CustomModal from "../../components/CustomModal";

interface AvailabilityModalProps {
  isOpen: boolean;
  handleClose: () => void;
  bookingDate: Date | null;
  setBookingDate: (date: Date) => void;
  floors: { value: string; label: string }[];
  setTableData: (e: any) => void;
  tables: { value: string; label: string }[];
  setTable: (e: any) => void;
  table: { value: string; label: string } | null;
  timeslots: { value: string; label: string }[];
  setTimeslot: (e: any) => void;
  partySizes: { value: string; label: string }[];
  setPartySize: (e: any) => void;
  checkAvailability: () => void;
  checking: boolean;
}

const AvailabilityModal: React.FC<AvailabilityModalProps> = ({
  isOpen,
  handleClose,
  bookingDate,
  setBookingDate,
  floors,
  setTableData,
  tables,
  setTable,
  table,
  timeslots,
  setTimeslot,
  partySizes,
  setPartySize,
  checkAvailability,
  checking,
}) => {
  return (
    <CustomModal isOpen={isOpen} handleClose={handleClose} hasClose>
      <div>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={4}>
                Date
              </Grid>
              <Grid item xs={8}>
                <DatePicker
                  selected={bookingDate}
                  onChange={(date) => setBookingDate(date as Date)}
                  minDate={moment().toDate()}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={4}>
                Floors
              </Grid>
              <Grid item xs={8}>
                <Select options={floors} onChange={setTableData} />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={4}>
                Table
              </Grid>
              <Grid item xs={8}>
                <Select options={tables} onChange={setTable} value={table} />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={4}>
                Time
              </Grid>
              <Grid item xs={8}>
                <Select options={timeslots} onChange={setTimeslot} />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={4}>
                Party Size
              </Grid>
              <Grid item xs={8}>
                <Select options={partySizes} onChange={setPartySize} />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={4}></Grid>
              <Grid item xs={8}>
                <Button
                  onClick={checkAvailability}
                  color="primary"
                  disabled={checking}
                >
                  {checking ? (
                    <CircularProgress size={50} color="secondary" />
                  ) : (
                    "Check Availability"
                  )}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </CustomModal>
  );
};

export default AvailabilityModal;
