import React from "react";
import { DayPicker } from "react-day-picker";
import "react-day-picker/src/style.css";

function BookingCalendar(props) {
  const handleDayClick = (day, modifiers = {}) => {
    if (modifiers.disabled) {
      return;
    }
    props.selectedDate(day);
  };

  return (
    <DayPicker
      mode="single"
      month={new Date(props.month)}
      selectedDays={[new Date(props.bookingDate)]}
      onSelect={handleDayClick}
      showOutsideDays={true}
      className="booking-calendar"
      disabled={props.disabledDays}
    />
  );
}

export default BookingCalendar;
