import React, { useState } from "react";
import {
  Box,
  Drawer,
  List,
  ListItemButton,
  ListItemText,
  IconButton,
  ListItemIcon,
  Typography,
} from "@mui/material";
import { Menu, CloseOutlined } from "@mui/icons-material/";
import { menuItems } from "../../Shared/interfaces/menu.jsx";
import { useNavigate } from "react-router-dom";

import ProfileIcon from "../../Modules/Team/components/common/ProfileIcon.jsx";
const SideMenu = ({ profileIcon = null }) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const toggleDrawer = (open) => (event) => {
    setIsOpen(open);
  };
  const handleNavigation = (url) => () => {
    toggleDrawer(false)();
    navigate(url);
  };
  const styles = {
    sideMenuWrapper: {
      position: "relative",
      zIndex: 100,
      width: "44px",
    },
  };

  return (
    <div className="sideMenu-wrapper" style={styles.sideMenuWrapper}>
      {!profileIcon ? (
        <IconButton
          onClick={toggleDrawer(true)}
          className="neumorphism-button"
          sx={{ color: "#fff", position: "relative", zIndex: 1 }}
        >
          <Menu fontSize="medium" />
        </IconButton>
      ) : (
        <ProfileIcon profilePicUrl={profileIcon} onClick={toggleDrawer(true)}>
          <Menu fontSize="medium" />
        </ProfileIcon>
      )}
      <Drawer
        anchor="left"
        open={isOpen}
        onClose={toggleDrawer(false)}
        PaperProps={{
          style: {
            width: 340,
            borderTopRightRadius: "15px",
            borderBottomRightRadius: "15px",
          },
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          className="neumorphism-container"
        >
          <Box sx={{ color: "#fff" }}>
            <IconButton
              onClick={toggleDrawer(false)}
              className="neumorphism-button"
              sx={{ color: "#fff", ml: 2, mt: 2, mb: 3 }}
            >
              <CloseOutlined fontSize="medium" />
            </IconButton>
            <Box display="flex" alignItems="center" justifyContent="center">
              <Typography variant="h2" style={{ fontSize: 20 }}>
                Restaurant Name
              </Typography>
            </Box>
          </Box>
          <Box flexGrow={1} overflow="auto">
            <List>
              {menuItems.map((item) => (
                <ListItemButton
                  onClick={handleNavigation(item.url)}
                  key={item.title}
                  sx={{ color: "#fff" }}
                >
                  <ListItemIcon sx={{ color: "#fff", minWidth: "32px" }}>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={item.title} sx={{ ml: 0 }} />
                </ListItemButton>
              ))}
            </List>
          </Box>
          <Box height={150}>
            <List>
              <ListItemButton
                onClick={toggleDrawer(false)}
                key="Switch Account"
                sx={{ color: "#fff" }}
              >
                <ListItemText primary="Switch Account" />
              </ListItemButton>
              <ListItemButton
                onClick={toggleDrawer(false)}
                key="Sign Out"
                sx={{ color: "#fff" }}
              >
                <ListItemText primary="Sign Out" />
              </ListItemButton>
            </List>
          </Box>
        </Box>
      </Drawer>
    </div>
  );
};

export default SideMenu;
