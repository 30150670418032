import React, { useRef, useState, useEffect, useCallback } from "react";
import {
  Box,
  Grid,
  Card,
  List,
  TextField,
  ListItem,
  ListItemText,
} from "@mui/material";
import moment from "moment";
import ReservationDropDown from "./ReservationDropDown";
import EditPartySizeTableModal from "./EditPartySizeTableModal";
import ReservationButton from "./ReservationButton";
import PartialPartySize from "../party-size/PartialPartySize";
import EditBookingTimeModal from "./EditBookingTimeModal.js";
import EditSessionTimeModal from "./EditSessionTimeModal.jsx";
import btnTimelineIcon from "../../../images/booking/btn-timeline-icon.png";
import { debounce } from "lodash";

import {
  getBookingStatus,
  getBookingStatusButton,
  getBookingStatusColor,
  getRemainingTime,
  getTimeStr,
  updateDateTime,
} from "../../../utils/Utility.js";
import { BOOKINGSTATUS, TABLELOCKSTATUS } from "../../../constants/enum.js";

const baseStyle = {
  backgroundColor: "transparent",
  border: "1px solid #fff",
  width: "100%",
  padding: "8px",
  color: "#fff",
  fontSize: "14px",
  textAlign: "center",
  cursor: "pointer",
  overflow: "visible",
  backgroundColor: "transparent",
  position: "relative",
};
const BookingControlPanel = (props) => {
  const {
    selectedBooking,
    tableOptions,
    reservationLockTableIcon,
    unlockTableIcon,
    sectionPartyIcon,
    statusOption,
    floor,
    handleSetOpenCalendar,
    editPartySizeTableHandle,
    selectedStatusOption,
    partyPartySizeHandler,
    openPartialPartySize,
    setOpenPartialPartySize,
    statusBtnOnClickHandler,
    setSelectedBooking,
    updateBooking,
    timeList,
    setIsSaving,
    setGuest,
    guest,
  } = props;

  const [selectTableStatusBoxVisible, setSelectTableStatusBoxVisible] =
    useState(false);
  const cardRef = useRef(null);
  const listRef = useRef(null);

  const handleCardClick = () => {
    setSelectTableStatusBoxVisible(true);
  };

  const handleClickOutside = (event) => {
    if (
      listRef.current &&
      !listRef.current.contains(event.target) &&
      cardRef.current &&
      !cardRef.current.contains(event.target)
    ) {
      setSelectTableStatusBoxVisible(false);
    }
  };
  const [editSessionTimeOpen, setEditSessionTimeOpen] = useState(false);
  const handleOpenEditSessionTime = () => {
    if (!editSessionTimeOpen) {
      setEditSessionTimeOpen(true);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  const selectOptionHandler = (value) => {
    setSelectTableStatusBoxVisible(false);
  };

  const [partySizeOpen, setPartySizeOpen] = useState(false);
  const [bookingTimeOpen, setBookingTimeOpen] = useState(false);
  const [note, setNote] = useState(selectedBooking.reservation_note || "");
  const updateTableLockStatus = (tableLockStatus) => {
    let booking = selectedBooking;
    if (booking.table_ids === undefined) {
      booking["table_ids"] = booking.table.map((e) => e.id);
    }
    booking = { ...booking, table_lock: tableLockStatus };
    setSelectedBooking(booking);
    updateBooking(booking);
    setSelectTableStatusBoxVisible(false);
  };
  useEffect(() => {
    if (
      selectedBooking.reservation_note !== undefined &&
      selectedBooking.reservation_note !== null
    ) {
      setNote(selectedBooking.reservation_note);
    }
  }, [selectedBooking]);

  const debouncedSave = useCallback(
    debounce((value) => updateValue(value, 1), 1500),
    []
  );

  const handleChange = (event) => {
    const { value } = event.target;
    setNote(value);
    debouncedSave(value);
  };

  const updateValue = (val, type) => {
    let booking = selectedBooking;
    if (booking.table_ids === undefined) {
      booking["table_ids"] = booking.table.map((e) => e.id);
    }

    setIsSaving(true);

    switch (type) {
      case 1:
        booking = { ...booking, reservation_note: val };

        setSelectedBooking(booking);
        updateBooking(booking);
        break;
      case 2:
        setGuest({ ...guest, general_note: val });
        break;
      case 3:
        setGuest({ ...guest, special_relationship: val });
        break;
      case 4:
        setGuest({ ...guest, seating_preference: val });
        break;
      case 5:
        setGuest({ ...guest, food_drink_preference: val });
        break;
    }
  };

  const handleOpenEditPartySize = () => {
    if (!partySizeOpen) {
      setPartySizeOpen(true);
    }
  };

  const handleCloseEditPartySize = () => {
    if (partySizeOpen) {
      setPartySizeOpen(false);
    }
  };

  const handleOpenEditTimeBooking = () => {
    if (!bookingTimeOpen) {
      setBookingTimeOpen(true);
    }
  };

  const selectedTimeHandler = (time) => {
    let booking = selectedBooking;
    if (booking.table_ids === undefined) {
      booking["table_ids"] = booking.table.map((e) => e.id);
    }
    booking = {
      ...booking,
      start_time: time,
      end_time: time + booking.session_time,
      start_date: updateDateTime(booking.start_date, time),
      end_date: updateDateTime(booking.end_date, time + booking.session_time),
    };
    setSelectedBooking(booking);
    updateBooking(booking);
  };

  const selectedSessionTimeHandler = (time = null) => {
    setEditSessionTimeOpen(false);
    let booking = selectedBooking;
    if (booking.table_ids === undefined) {
      booking["table_ids"] = booking.table.map((e) => e.id);
    }
    if (time === null) {
      booking = {
        ...booking,
        end_date: moment(booking.start_date)
          .add(1, "day")
          .subtract(1, "minute")
          .format("YYYY-MM-DD HH:mm:ss"),
        end_time: moment(booking.start_date)
          .add(1, "day")
          .subtract(1, "minute")
          .format("HHmm"),
      };
    } else {
      const endDate = moment(booking.start_date)
        .add(time, "hours")
        .format("YYYY-MM-DD HH:mm:ss");
      const momentDate = moment(endDate);
      booking = {
        ...booking,
        end_date: endDate,
        end_time:
          momentDate.hours() * 3600 +
          momentDate.minutes() * 60 +
          momentDate.seconds(),
      };
      console.log(
        momentDate.hours() * 3600 +
          momentDate.minutes() * 60 +
          momentDate.seconds()
      );
    }
    setSelectedBooking(booking);
    updateBooking(booking);
  };

  return (
    <Box className="bookingControlPanel" sx={{ m: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} className="controlPanel-timeWrapper">
          <Card
            style={baseStyle}
            className="bg-grayDark"
            onClick={() => handleOpenEditTimeBooking()}
          >
            <span className="header-text">
              {getTimeStr(selectedBooking.start_time)}
            </span>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4} className="controlPanel-tableWrapper">
          <Card ref={cardRef} style={baseStyle} onClick={handleCardClick}>
            <span>
              {selectedBooking && selectedBooking?.table?.length > 1
                ? `${selectedBooking?.table?.map((e) => e.name).join(",")}`
                : `${
                    selectedBooking?.table ? selectedBooking?.table[0].name : ""
                  }`}
            </span>
          </Card>
          {selectTableStatusBoxVisible && (
            <Box
              ref={listRef}
              sx={{
                marginTop: "10px",
                minWidth: "120px",
                backgroundColor: "white",
                border: "1px solid #ccc",
                borderRadius: "4px",
                position: "absolute",
                zIndex: 2,
                color: "#000",
              }}
            >
              <List style={{ cursor: "pointer" }}>
                {selectedBooking.table_lock === TABLELOCKSTATUS.unlocked ? (
                  <ListItem
                    onClick={() =>
                      updateTableLockStatus(TABLELOCKSTATUS.locked)
                    }
                  >
                    <ListItemText primary="Lock booking table" />
                  </ListItem>
                ) : (
                  <ListItem
                    onClick={() =>
                      updateTableLockStatus(TABLELOCKSTATUS.unlocked)
                    }
                  >
                    <ListItemText primary="Unlock booking table" />
                  </ListItem>
                )}
                <ListItem onClick={() => handleOpenEditPartySize()}>
                  <ListItemText primary="Change table" />
                </ListItem>
              </List>
            </Box>
          )}
        </Grid>
        <Grid item xs={12} sm={4} className="controlPanel-calendarWrapper">
          <Card style={baseStyle} onClick={() => handleSetOpenCalendar()}>
            <span>
              {moment(selectedBooking.start_date).format("MMMM Do, yyyy")}
            </span>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4} className="controlPanel-partysizeWrapper">
          <Card style={baseStyle} onClick={handleOpenEditPartySize}>
            <span className="header-text">{`${selectedBooking.party_size} ${
              selectedBooking.party_size > 1 ? "guests" : "guest"
            }`}</span>
          </Card>
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          className="controlPanel-bookingSessionWrapper"
        >
          <Card style={baseStyle} onClick={handleOpenEditSessionTime}>
            <span
              className="header-text"
              style={
                selectedBooking.status === BOOKINGSTATUS.overTime
                  ? {
                      color: getBookingStatusColor(
                        selectedBooking.status,
                        selectedBooking.start_date
                      ),
                    }
                  : { color: "#fff" }
              }
            >
              {getRemainingTime(
                selectedBooking.start_time,
                selectedBooking.end_time,
                selectedBooking.status,
                Boolean(selectedBooking.no_limit)
              )}
            </span>
          </Card>
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          className="controlPanel-reservationDropdownWrapper"
        >
          <Card style={baseStyle} className="dropdown-wrapper">
            <ReservationDropDown
              selectedOption={getBookingStatus(selectedBooking.status)}
              options={statusOption}
              startDate={selectedBooking.start_date}
              hideLeftIcon={false}
              setSelectedOption={selectedStatusOption}
              selectionDisabled={selectedBooking.status === 8 ? true : false}
            />
            <PartialPartySize
              partyPartySizeHandler={partyPartySizeHandler}
              openPartialPartySize={openPartialPartySize}
              handleCloseSelectedBookingCalendar={setOpenPartialPartySize}
            />
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card style={{ borderRadius: "8px" }}>
            <ReservationButton
              onClickHandler={statusBtnOnClickHandler}
              selectedBookingStatus={getBookingStatusButton(
                selectedBooking.status
              )}
            />
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card style={{ borderRadius: "8px" }}>
            <TextField
              fullWidth
              placeholder="Leave Note"
              variant="outlined"
              multiline
              rows={4}
              onChange={handleChange}
              value={note}
              sx={{
                backgroundColor: "#444e56",
                "& .MuiOutlinedInput-root": {
                  color: "#fff",
                  "& fieldset": {
                    border: "none",
                  },
                  "& input": {
                    color: "#fff",
                  },
                  "& ::placeholder": {
                    color: "#a9a9a9",
                  },
                },
              }}
            />
          </Card>
        </Grid>
      </Grid>
      <EditBookingTimeModal
        bookingTimeOpen={bookingTimeOpen}
        setBookingTimeOpen={setBookingTimeOpen}
        selectedTimeHandler={selectedTimeHandler}
        timeList={timeList}
        modalTitle={"EDIT BOOKING TIME"}
        btnTimelineIcon={btnTimelineIcon}
      />
      <EditSessionTimeModal
        editSessionTimeOpen={editSessionTimeOpen}
        setEditSessionTimeOpen={setEditSessionTimeOpen}
        selectedSessionTimeHandler={selectedSessionTimeHandler}
        modalTitle={"EDIT SESSION TIME"}
      />
      <EditPartySizeTableModal
        partySizeOpen={partySizeOpen}
        setPartySizeOpen={setPartySizeOpen}
        handleCloseEditPartySize={(e) => handleCloseEditPartySize(e)}
        editPartySizeTableHandle={editPartySizeTableHandle}
        modalTitle={"EDIT PARTY SIZE/TABLE"}
        sectionPartyIcon={sectionPartyIcon}
        floor={floor}
        selectedTables={selectedBooking.table}
        startDate={selectedBooking.start_date}
        partySize={selectedBooking.party_size}
        startTime={selectedBooking.start_time}
      />
    </Box>
  );
};

export default BookingControlPanel;
