import React from "react";
import { Grid, Paper, Box, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

const ProductGridItem = ({
  product,
  styles,
  imgUrl,
  handleMouseDown,
  handleMouseUp,
  getRelativeLuminance,
}) => {
  const productStyle = product.photo
    ? { backgroundImage: `url(${imgUrl + product.photo})` }
    : product.color
    ? { backgroundColor: product.color }
    : {};

  return (
    <Grid
      item
      sx={styles.gridItem}
      className="text-xs rounded-lg text-white bg-grayDark"
      onMouseDown={() => handleMouseDown(product, "product")}
      onMouseUp={() => handleMouseUp(product, "product")}
      onTouchStart={() => handleMouseDown(product, "product")}
      onTouchEnd={() => handleMouseUp(product, "product")}
    >
      <Paper sx={styles.paper} className="relative" style={productStyle}>
        {product.photo ? (
          <Box>
            <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
              {product.code}
            </Typography>
            <Box className="absolute bottom-0 bg-black/[.6] w-full left-0 flex items-center p-1 pl-2">
              <Typography className="text-[8px]">
                {`$${product.price.toFixed(2)}`}
              </Typography>
              <Typography className="text-xs font-bold ml-4">
                {product.title}
              </Typography>
            </Box>
          </Box>
        ) : (
          <Box
            className="relative-color"
            sx={
              product.color && {
                color: getRelativeLuminance(product.color),
              }
            }
          >
            <Typography className="text-xl font-bold">
              {product.code}
            </Typography>
            <Typography className="text-xs font-bold">
              {product.title}
            </Typography>
            <Typography
              className={`${
                product.color ? "" : "text-purple"
              } text-[8px] absolute bottom-1 right-1 font-bold`}
            >{`$${product.price.toFixed(2)}`}</Typography>
          </Box>
        )}
      </Paper>
    </Grid>
  );
};

const ProductGrid = ({
  filteredProducts,
  styles,
  imgUrl,
  handleMouseDown,
  handleMouseUp,
  setOpenProductModal,
  getRelativeLuminance,
  height = 300,
}) => {
  return (
    <Grid
      height={height}
      style={{ overflow: "auto", gap: "4px 0.8%" }}
      container
      className="product-grid content-start"
    >
      {filteredProducts.map((product, index) => (
        <ProductGridItem
          key={index}
          product={product}
          styles={styles}
          imgUrl={imgUrl}
          handleMouseDown={handleMouseDown}
          handleMouseUp={handleMouseUp}
          getRelativeLuminance={getRelativeLuminance}
        />
      ))}
      <Grid
        item
        className="text-xs rounded-lg text-white bg-grayDark"
        sx={styles.gridItem}
      >
        <Paper sx={styles.paper} onClick={() => setOpenProductModal(true)}>
          <AddIcon className="text-white" />
        </Paper>
      </Grid>
      {filteredProducts.length < 23 &&
        Array.from({ length: 23 - filteredProducts.length }).map((_, index) => (
          <Grid
            item
            sx={styles.gridItem}
            key={index}
            className="text-xs rounded-lg text-white bg-grayDark"
          >
            <Paper sx={styles.paper} />
          </Grid>
        ))}
    </Grid>
  );
};

export default ProductGrid;
