import React from "react";
import MuiButton from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import MuiDialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/system";

const DialogTitle = styled((theme) => ({
  root: {
    textAlign: "center",
    color: "#9b9b9b",
    fontSize: "0.5em",
  },
}))(MuiDialogTitle);
const Button = styled((theme) => ({
  root: {
    fontSize: "1em",
    color: "#9e9eef",
    borderTop: "3px solid #0000003d;",
  },
}))(MuiButton);

function AlertDialog(props) {
  const handleClose = () => {
    props.setAlertHandler(false);
  };

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.alertHandler}
      >
        <DialogTitle id="customized-dialog-title">
          <div
            style={{
              fontWeight: "bold",
            }}
          >
            {props.dialogAlertMessage.title}
          </div>
          {props.dialogAlertMessage.body}
        </DialogTitle>

        <Button onClick={handleClose}>OK</Button>
      </Dialog>
    </div>
  );
}
export default AlertDialog;
