import React, { forwardRef } from "react";
import { Grid, Box, IconButton, Button, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { getRelativeLuminance } from "../../utils/Utility";
const AddOnsSection = forwardRef(
  (
    {
      styles,
      handleAddOnToggle,
      showAddOns,
      getAddOns,
      handleMouseDown,
      handleMouseUp,
      setOpenModifierModal,
      setOpenAllModsModal,
    },
    ref
  ) => {
    return (
      <Grid
        ref={ref}
        className="add-ons-wrapper mt-auto pt-4"
        container
        sx={{ justifyContent: "space-between" }}
      >
        <Grid item sx={{ width: "100px" }}>
          <Box display="flex" sx={{ flexDirection: "column", gap: "4px" }}>
            <Button
              style={styles.iconButton}
              className="bg-grayDark text-xs"
              onClick={() => setOpenAllModsModal(true)}
            >
              All Mods
            </Button>
            <IconButton
              style={styles.iconButton}
              className="bg-grayDark"
              onClick={() => handleAddOnToggle(0)}
            >
              <AddIcon />
            </IconButton>
            <IconButton
              style={styles.iconButton}
              className="bg-grayDark"
              onClick={() => handleAddOnToggle(1)}
            >
              <RemoveIcon />
            </IconButton>
            <IconButton style={styles.iconButton} className="bg-grayDark">
              <Typography variant="body2">Note</Typography>
            </IconButton>
          </Box>
        </Grid>
        <Grid item sx={{ width: "calc(100% - 116px)" }}>
          {showAddOns && (
            <Box
              display="flex"
              flexWrap="wrap"
              justifyContent="left"
              gap="4px"
              maxHeight="128px"
              overflow="auto"
            >
              {getAddOns().map((addOn) => (
                <Button
                  key={addOn.id}
                  sx={{
                    minWidth: "24%",
                    flexDirection: "column",
                    lineHeight: "1.2",
                    justifyContent: "center",
                    height: "40px",
                    backgroundColor: addOn.color + " !important" || undefined,
                    color: getRelativeLuminance(addOn?.color),
                  }}
                  className="text-xs rounded-lg border border-solid border-gray"
                  onMouseDown={() => handleMouseDown(addOn, "addOn")}
                  onMouseUp={() => handleMouseUp(addOn, "addOn")}
                  onTouchStart={() => handleMouseDown(addOn, "addOn")}
                  onTouchEnd={() => handleMouseUp(addOn, "addOn")}
                >
                  {addOn.name}
                  <span
                    style={{
                      fontSize: "10px",
                    }}
                    className={`${addOn.color ? "" : "text-purple"}`}
                  >
                    {addOn.price > 0 ? `+ $${addOn.price}` : ""}
                  </span>
                </Button>
              ))}
              <Button
                sx={{ minWidth: "24%" }}
                className="rounded-lg text-white border border-solid border-gray"
                onClick={() => setOpenModifierModal(true)}
              >
                <AddIcon className="text-pink" />
              </Button>
            </Box>
          )}
        </Grid>
      </Grid>
    );
  }
);

export default AddOnsSection;
