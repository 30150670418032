export const createBookingParameters = (
  shiftId, // Int
  type, // Int
  reservationNote, // String
  table_ids, // Int
  table_lock, // Boolean
  guest, // Guest Object
  bookingTaken, // Employee Object
  status, // Int
  startDate, // String
  endDate, // String
  partySize, // Int
  expId = 0, // Int
  expNoOfTickets = 0 // Int
) => {
  let params = {
    shift_id: shiftId,
    id: 0,
    type: type,
    reservation_note: reservationNote,
    table_ids: table_ids,
    table_lock: table_lock,
    guest: guest,
    booking_taken: bookingTaken,
    status: status,
    start_date: startDate,
    end_date: endDate,
    party_size: partySize,
    partial_seated: 0,
  };

  if (expId > 0) {
    params = {
      ...params,
      experience_id: expId,
      experience_no_of_tickets: expNoOfTickets,
    };
  }

  return params;
};
