import React, { useState, useEffect } from "react";
import {
  getBookingOnTable,
  hasConflictBooking,
  getFullname,
  getTimeOnly,
  getBookingOnTableByIndex,
  getBookingTimeOnly,
  getTableImg,
  getTableWidth,
  getTableHeight,
  getRadWidth,
  getRadHeight,
  radToDeg,
  getPositionTop,
  getPositionLeft,
  tableNamePositionLeft,
  tableNamePositionMarginLeft,
  tableNamePositionMarginTop,
  tableNamePositionLeftTranslate,
  getLabelBookingColor,
  getBookingStatusColor,
  getBookingProgressStatusColor,
  calculateElapsedPercentageByDate,
  getRoundTableBookingStatus,
} from "../../utils/Utility";
import conflictIcon from "../../images/booking/conflict-icon.png";
import SemiCircularProgressBar from "../ProgressBar/SemiCircularProgressBar";
import { TABLETYPE } from "../../constants/enum";
import { useDraggable, useDroppable } from "@dnd-kit/core";

const TableComponent = ({
  table,
  tableList,
  bookingsList,
  showTimeline,
  selectedTableId,
  selectedShift,
  orderLists,
  showTableDetailsHandler,
}) => {
  const booking = getBookingOnTable(table.id, bookingsList, selectedShift);
  // dnd
  const {
    setNodeRef: setDraggableNodeRef,
    attributes,
    listeners,
    transform,
    isDragging,
  } = useDraggable({
    id: table.id,
    data: {
      type: booking ? "Booking" : "Table",
      table,
      bookingId: booking ? booking?.id : null,
      booking: booking ? booking : null,
    },
    // disabled: rearrangeMode ? !rearrangeMode : isBookingNull,
  });

  const { setNodeRef: setDroppableNodeRef, isOver } = useDroppable({
    id: table.id,
    data: {
      type: "Table",
      table: table,
    },
  });

  const hasConflict = hasConflictBooking(null, tableList, booking);
  const isBookingNull = booking === null;
  const bookingStatus = booking ? booking.status : null;
  const guestName = booking ? getFullname(booking.guest) : null;
  const startTime = booking ? getTimeOnly(booking.start_date) : null;
  const tableType = table.table_type;
  const tableRotate = table.rotate_deg;
  const rotateVal = radToDeg(tableRotate);
  const isRoundTable = getRoundTableBookingStatus(tableType);
  const positionTop = tableNamePositionMarginTop(tableType, tableRotate);
  const tableHeight = getTableHeight(tableType);
  const tableWidth = getTableWidth(tableType);
  const commonStyle = {
    position: "absolute",
    zIndex: 1,
    left: tableNamePositionLeft(tableType),
    transform: `translateX(${tableNamePositionLeftTranslate(tableType)})`,
    fontSize: 8,
    fontWeight: "bold",
    marginLeft: tableNamePositionMarginLeft(tableType, tableRotate),
  };

  const [tableAmount, setTableAmount] = useState(null);

  useEffect(() => {
    const getTableAmount = () => {
      if (booking) {
        const products = orderLists
          .filter((val) => val.booking_uuid === booking.uuid)
          .map((val) => val.products)[0];
        let grandTotalAmount = 0;
        if (products && products.length) {
          products.forEach((data) => {
            let totalAmount = data.price * data.quantity;
            data.addOns.forEach((addOn) => {
              totalAmount += addOn.price * addOn.quantity;
            });
            grandTotalAmount += totalAmount;
          });
        }
        setTableAmount(grandTotalAmount.toFixed(2));
      }
    };

    getTableAmount();
  }, [booking, orderLists]);

  const getBookingDetailPosition = () => {
    if (isRoundTable) {
      return {
        inset: "0",
        margin: "auto",
        paddingTop: "8px",
        transform: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      };
    } else {
      return {
        bottom: positionTop + 3,
      };
    }
  };

  const getProgressBarStyle = () => {
    let baseStyle = {
      position: "absolute",
      zIndex: 1,
      background: getBookingStatusColor(bookingStatus, booking.start_date),
      borderRadius: 4,
      width: "100%",
      left: 0,
      marginLeft: 0,
      bottom: "6px",
      height: 3,
    };
    if (tableType === TABLETYPE.twoSinglePairTable) {
      if (rotateVal !== 90) {
        return {
          ...baseStyle,
          width: "80%",
          left: "10%",
          bottom: 0,
        };
      }
    } else if (tableType === TABLETYPE.fourPersonSingleTable) {
      return {
        ...baseStyle,
        width: "80%",
        left: "10%",
        bottom: "10%",
      };
    } else if (tableType === TABLETYPE.singlePairTable && tableRotate === 0) {
      return {
        ...baseStyle,
        width: "80%",
        left: "10%",
        bottom: 0,
      };
    }
    return baseStyle;
  };

  return (
    <div
      onClick={(e) => showTableDetailsHandler(table, booking)}
      key={Math.random()}
      style={{
        position: "relative",
        width: getRadWidth(tableRotate, tableType),
        height: getRadHeight(tableRotate, tableType),
        // opacity: isDragging ? 0.5 : 1,
      }}
      // ref={setDroppableNodeRef}
      className="booking-detail"
    >
      <div
        // ref={setDraggableNodeRef}
        {...attributes}
        {...listeners}
      >
        <span
          key={Math.random()}
          style={{
            ...commonStyle,
            marginTop: positionTop,
            lineHeight: 1.2,
            textAlign: isRoundTable ? "center" : "left",
          }}
          className="table-name"
        >
          {table.name}
          {tableAmount && tableAmount > 0 && (
            <span
              style={{
                display: "block",
                color: "#7af8c8",
              }}
            >
              ${tableAmount}
            </span>
          )}
        </span>
        {hasConflict && (
          <img
            src={conflictIcon}
            width="18"
            height="18"
            className="d-inline"
            alt="checker"
            style={{
              ...commonStyle,
              marginTop: positionTop + 15,
            }}
          />
        )}
        {!showTimeline && !isBookingNull && (
          <>
            <div
              key={Math.random()}
              style={{
                ...commonStyle,
                lineHeight: "1.2",
                ...getBookingDetailPosition(),
                color: getLabelBookingColor(bookingStatus),
                textAlign: isRoundTable ? "center" : "left",
              }}
              className="booking-detail"
            >
              {guestName}
              <br />
              {startTime}
            </div>
            {isRoundTable ? (
              <SemiCircularProgressBar
                percentage={calculateElapsedPercentageByDate(
                  booking.start_date,
                  booking.end_date
                )}
                background={getBookingStatusColor(
                  bookingStatus,
                  booking.start_date
                )}
                progressbarColor={getBookingProgressStatusColor(bookingStatus)}
                tableType={tableType}
              />
            ) : (
              <div
                key={Math.random()}
                style={{
                  ...getProgressBarStyle(),
                }}
                className="progress-bar"
              >
                <div
                  key={Math.random()}
                  style={{
                    background: getBookingProgressStatusColor(bookingStatus),
                    width: `${calculateElapsedPercentageByDate(
                      booking.start_date,
                      booking.end_date
                    )}%`,
                    height: 3,
                    borderRadius: 4,
                  }}
                  className="progress-status"
                />
              </div>
            )}
          </>
        )}
        {showTimeline && (
          <div
            key={Math.random()}
            style={{
              position: "absolute",
              zIndex: 2,
              height: "100%",
              width: 60,
              top: "50%",
              left: "50%",
              transform: `translate(-50%, -23%)`,
            }}
            className="timeline-container"
          >
            {[0, 1, 2].map((index) => (
              <div
                className="timeline-item"
                key={Math.random()}
                style={{
                  background: "rgba(0, 0, 0, 0.8)",
                  height: "8px",
                  margin: "2px 3px 4px",
                  borderRadius: 1,
                  textAlign: "center",
                  lineHeight: 1.3,
                  fontSize: "8px",
                  color: getBookingStatusColor(
                    bookingStatus,
                    booking.start_date
                  ),
                }}
              >
                {getBookingTimeOnly(
                  getBookingOnTableByIndex(table.id, bookingsList, index)
                ) ?? ""}
              </div>
            ))}
          </div>
        )}
        <div
          key={Math.random()}
          style={{
            width: tableWidth,
            height: tableHeight,
            transformOrigin: "top left",
            position: "absolute",
            top: getPositionTop(tableRotate, tableType),
            left: getPositionLeft(tableRotate, tableType),
            transform: `rotate(${rotateVal}deg)`,
          }}
          className="table-container-wrapper"
        >
          <img
            draggable="false"
            key={Math.random()}
            src={getTableImg(tableType)}
            width="100%"
            height="100%"
            className={
              selectedTableId.length >= 1 && selectedTableId.includes(table.id)
                ? "table-floor table-container img-filter"
                : "table-floor table-container"
            }
            alt="checker"
          />
        </div>
      </div>
    </div>
  );
};

export default TableComponent;
