import React from "react";
import { Modal, Backdrop, Fade, Box, Typography, Paper } from "@mui/material";
import btnClose from "../../../images/booking/create-booking/button-close-icon.png";

const styles = {
  container: {
    width: "100%",
    height: "100%",
    position: "relative",
  },
  mainContainer: {
    width: 544,
    height: 313,
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: 10,
    color: "white",
    overflowY: "scroll",
    overflowX: "hidden",
  },
  gridContainer: {
    display: "grid",
    gridTemplateColumns: "auto auto",
    gridTemplateRows: "auto auto",
    gap: "15px 10px",
    padding: "15px 15px",
  },
  itemWrapper: {
    padding: 10,
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: 130,
    userSelect: "none",
    justifyItems: "center",
    background: "linear-gradient(0deg, #ff8585 0%, #ff7192 100%)",
    borderRadius: 10,
    cursor: "pointer",
  },
  gridHeader: {
    fontSize: "1.1em",
  },
  gridContent: {
    padding: "10px 0",
    fontSize: "0.8em",
    textOverflow: "ellipsis",
  },
  gridFooter: {
    display: "flex",
    justifyContent: "space-between",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    width: "100%",
    height: "100%",
    backdropFilter: "blur(10px)",
    outline: "none",
  },
  closeButton: {
    textAlign: "center",
    margin: "10px 20px",
    marginRight: 10,
    cursor: "pointer",
  },
};

function BookingExperience(props) {
  const handleClose = () => {
    props.handleCloseBookingExperience();
  };

  const selectExperience = (id) => {
    props.expGetAssign(id);
    props.handleCloseBookingExperience();
    props.setTicketQuantityOpen(true);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      sx={styles.modal}
      open={props.bookingExperience}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.bookingExperience}>
        <Box sx={styles.paper}>
          <Box sx={styles.container}>
            <Box
              sx={{
                textAlign: "right",
              }}
            >
              <img
                onClick={() => handleClose()}
                src={btnClose}
                width="48"
                height="48"
                className="d-inline"
                style={styles.closeButton}
                alt="checker"
              />
            </Box>
            <Box sx={styles.mainContainer}>
              <Box sx={styles.gridContainer}>
                {props.experienceList.length > 0
                  ? props.experienceList.map((id) => (
                      <Paper
                        key={id.id}
                        sx={styles.itemWrapper}
                        onClick={() => selectExperience(id.id)}
                      >
                        <Typography sx={styles.gridHeader}>
                          {id.exp_name}
                        </Typography>
                        <Typography sx={styles.gridContent}>
                          {id.exp_description}
                        </Typography>
                        <Box sx={styles.gridFooter}>
                          <span>${id.price}.00</span>
                          <span>{id.no_of_ticket} Tickets</span>
                        </Box>
                      </Paper>
                    ))
                  : ""}
              </Box>
            </Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}

export default BookingExperience;
