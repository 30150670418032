import React, { useState, useEffect } from "react";
import { Modal, Backdrop, Fade, Box } from "@mui/material";
import { filterTableByFloor, buildRequestHeader } from "../../../utils/Utility";

// Components
import TableFilter from "../table-filter/TableFilter";
import PartySizeSelection from "../party-size-selection/PartySizeSelection";
import TableList from "../table-list/TableList";
import BookingButton from "../booking-button/BookingButton";
import { useDispatch } from "react-redux";

// Icons
import exitBtnTableIcon from "../../../images/booking/exit-btn-table-details.png";
import moment from "moment";
import { getTablesRequest } from "../../../../../actions/serachTableActions";

const styles = {
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    width: "100%",
    height: "100%",
    backdropFilter: "blur(10px)",
    outline: "none",
  },
};

function EditPartySizeTableModal(props) {
  const [selectedTable, setSelectedTable] = useState(null);
  const [partySize, setPartySize] = useState();
  const [tableList, setTableList] = useState([]);
  const [tablesData, setTablesData] = useState([]);
  const [toFilterFloor, setToFilterFloor] = useState(props.floor[0]);
  const [hasError, setHasError] = useState(false);
  const [isSuggested, setIsSuggested] = useState(false);
  const [showAllTables, setShowAllTables] = useState(false);
  const [selectedTables, setSelectedtables] = useState(null);
  const dispatch = useDispatch();

  let _floor = props.floor[0];
  let _partySize = props.partySize;

  const handleOpenEditPartySize = props.partySizeOpen;
  const handleCloseEditPartySize = () => {
    props.editPartySizeTableHandle(partySize, selectedTables);
    props.handleCloseEditPartySize(false);
  };

  const selectedOption = (floor) => {
    _floor = floor;
    setToFilterFloor(floor);
    setTableList(filterTableByFloor(floor.id, tablesData));
  };

  const selectedPartySize = (value) => {
    _partySize = value;
    setPartySize(value);
  };

  const setIsSuggestedHandler = () => {
    setIsSuggested(!isSuggested);
  };

  const setShowAllTablesHandler = () => {
    setShowAllTables(!showAllTables);
  };
  useEffect(() => {
    searchTables();
  }, [showAllTables, isSuggested, partySize]);

  // Search Tables
  const searchTables = async () => {
    if (partySize === 0 && props.startTime === 0) {
      setHasError(true);
      return;
    }
    try {
      let param = {};
      param = {
        party_size: partySize,
        booking_time: props.startTime,
        booking_date: moment(props.startDate).format("yyyy-MM-DD"),
        show_suggested: isSuggested,
        show_all: showAllTables,
      };
      const callback = (error, tables) => {
        if (error) {
          console.log("Error: ", error);
          return;
        }

        setTablesData(tables);
        setTableList(
          filterTableByFloor(
            toFilterFloor === undefined ? _floor.id : toFilterFloor.id,
            tables
          )
        );
      };
      dispatch(getTablesRequest(param, callback));
    } catch (err) {
      console.log("Error: ", err);
    }
  };

  useEffect(() => {
    if (props.partySize && props.startTime && props.selectedTables) {
      setPartySize(props.partySize);
      setSelectedtables(props.selectedTables);
      searchTables();
    }
  }, []);

  return (
    <Modal
      sx={styles.modal}
      open={props.partySizeOpen}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={handleOpenEditPartySize}>
        <Box sx={styles.paper}>
          <Box className="modal-content-wrapper">
            <Box className="editPartySize-content-container">
              <Box className="img-wrapper" onClick={handleCloseEditPartySize}>
                <img
                  src={exitBtnTableIcon}
                  width="12"
                  height="12"
                  alt="checker"
                />
              </Box>
              <label className="modal-title-header">
                <img
                  src={props.sectionPartyIcon}
                  width="26"
                  height="26"
                  alt="Icon"
                  style={{ marginTop: 5, marginRight: 5, marginBottom: 5 }}
                />
                {props.modalTitle}
              </label>
              <Box className="table-btn-wrapper">
                <BookingButton
                  titleName="Suggested"
                  isSelectedHandler={() => setIsSuggestedHandler()}
                  isSelected={isSuggested}
                  width={120}
                  height={50}
                />
                <BookingButton
                  titleName="Show all tables"
                  isSelectedHandler={() => setShowAllTablesHandler()}
                  isSelected={showAllTables}
                  width={140}
                  height={50}
                />
              </Box>
              <TableFilter
                selectedOption={selectedOption}
                floor={props.floor}
                padRight={0}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: 5,
                  marginBottom: 24,
                }}
              >
                <PartySizeSelection
                  selectedPartySize={selectedPartySize}
                  partySize={12}
                  selectedOption={partySize}
                />
                <TableList
                  isMultipleTable={true}
                  selectedTables={selectedTables}
                  tables={tableList}
                  floors={props.floor}
                  selectedTableHandler={setSelectedtables}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}

export default EditPartySizeTableModal;
