import React from "react";
import { Avatar } from "@mui/material";

const ProfileAvatar = ({
  profilePicUrl,
  firstName,
  lastName,
  color,
  width = 56,
  height = 56,
}) => {
  const s3Bucket = "https://wabi-staging.s3.ap-southeast-2.amazonaws.com/";
  const imgSrc = profilePicUrl ? `${s3Bucket}${profilePicUrl}` : null;
  const firstInitial =
    typeof firstName === "string" && firstName.length > 0
      ? firstName.charAt(0)
      : "";

  const lastInitial =
    typeof lastName === "string" && lastName.length > 0
      ? lastName.charAt(0)
      : "";

  return (
    <Avatar
      sx={{
        bgcolor: color,
        width: width,
        height: height,
        fontWeight: "bold",
        margin: "auto",
      }}
    >
      {imgSrc ? (
        <img
          src={imgSrc}
          alt={`${firstInitial} ${lastInitial}`}
          style={{ width: "100%", height: "100%" }}
        />
      ) : (
        <>
          {firstInitial?.charAt(0)}
          {lastInitial?.charAt(0)}
        </>
      )}
    </Avatar>
  );
};

export default ProfileAvatar;
