// import exitBtnIcon from "../../../images/booking/exit-btn-table-details.png";
// import bookingStatusPhoneIcon from "../../../images/booking/create-booking/booking-status-phone.png";
// import bookingStatusNetwork from "../../../images/booking/create-booking/booking-status-network.png";
// import bookingStatusWalkIn from "../../../images/booking/create-booking/booking-status-walk-in.png";
// import experienceIcon from "../../../images/booking/create-booking/booking-option-experience-icon.png";
// import ReservationDropDown from "./ReservationDropDown.js";
// import ReservationButton from "./ReservationButton.js";
// import overtimeIcon from "../../../images/booking/overtime-icon.png";
// import calendarIcon from "../../../images/booking/booking-details/icon-report-calendar.png";
// import phoneIcon from "../../../images/booking/reservation-btn-phone-icon.png";
// import EditPartySizeTableModal from "./EditPartySizeTableModal.jsx";
// import sessionIcon from "../../../images/booking/session-icon.png";
// import EditBookingTimeModal from "./EditBookingTimeModal.js";
// import btnDownArrowIcon from "../../../images/booking/down-arrow-icon.png";
// import ToggleSwitch from "./toggleSwitch.js";
import React, { useCallback, useEffect, useState } from "react";
// import { debounce } from "lodash";
import BookingExperience from "../booking-experience/BookingExperience.js";
import AddIcon from "@mui/icons-material/Add";

import {
  apiUrl,
  getBookingTakenFullname,
  getBookingType,
  getDisabledDays,
  getFullname,
  updateDateTime,
  getNearestAvailableDate,
  getAllTime,
  filterShifts,
} from "../../../utils/Utility.js";
// ICONS
import reservationLockTableIcon from "../../../images/booking/reservation-lock-table-icon.png";
import unlockTableIcon from "../../../images/booking/unlocked-table-icon.png";
import personIcon from "../../../images/booking/create-booking/person-icon.png";
import sectionPartyIcon from "../../../images/booking/section-party-size-icon.png";

import axios from "axios";
import { BOOKINGSTATUS, BOOKINGTYPE } from "../../../constants/enum.js";
import moment from "moment";
import SelectBookingCalendar from "../../BookingCalendar/SelectBookingCalendar.tsx";
import EditNameModal from "./EditNameModal.js";
import TicketQuantity from "../booking-experience/TicketQuantity.js";
import AlertDialog from "../booking-experience/AlertDialog.js";
import SelectBookingType from "./SelectBookingType.js";

import {
  Box,
  Typography,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  Alert,
  AlertTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
} from "@mui/material";
import { styled } from "@mui/system";
import BookingControlPanel from "./BookingControlPanel.jsx";
import { useDispatch } from "react-redux";
import { getExperienceAssignRequest } from "../../../../../actions/experienceActions.js";
import {
  getBookingHistoryRequest,
  updateBookingRequest,
} from "../../../../../actions/bookingActions.js";

const ScrollableContainer = styled(Box)({
  display: "flex",
  overflowX: "auto",
  padding: "10px",
  gap: "10px",
  "&::-webkit-scrollbar": {
    display: "none",
  },
});

const ItemCard = styled(Card)({
  minWidth: "150px",
  textAlign: "center",
});
function ReservationDetails(props) {
  const [openCalendar, setOpenCalendar] = useState(false);
  const [guest, setGuest] = useState(props.selectedBooking.guest);

  const [isSaving, setIsSaving] = useState(false);
  const [openPartialPartySize, setOpenPartialPartySize] = useState(false);
  const [openEditName, setOpenEditName] = useState(false);
  const [selectBookingOpen, setSelectBookingOpen] = useState(false);

  const [timeList, setTimeList] = useState([]);

  // Booking Experience
  const [bookingExperience, setBookingExperience] = useState(false);
  const [alertHandler, setAlertHandler] = useState(false);
  const [expDaysOfWeek, setExpDaysOfWeek] = useState([]);
  const [expStartDate, setExpStartDate] = useState();
  const [expEndDate, setExpEndDate] = useState();
  const [expRecurringType, setExpRecurringType] = useState(-2);
  const [expRecurringValue, setExpRecurringValue] = useState(-1);
  const [expShiftId, setExpShiftId] = useState(0);
  const [expName, setExpName] = useState();
  const [expId, setExpId] = useState(0);
  // const [expNoOfTickets, setExpNoOfTickets] = useState(0);
  const [expHandler, setExpHandler] = useState(false);
  const [ticketQuantityOpen, setTicketQuantityOpen] = useState(false);
  const [hideShiftAlert, setHideShiftAlert] = useState(false);
  const [testDate, setTestDate] = useState();
  const [dialogAlertMessage, setdialogAlertMessage] = useState("");
  const dispatch = useDispatch();

  // const debouncedSave = useCallback(
  //   debounce((val, type) => updateValue(val, type), 1500),
  //   []
  // );

  // const updateValue = (val, type) => {
  //   let booking = props.selectedBooking;
  //   if (booking.table_ids === undefined) {
  //     booking["table_ids"] = booking.table.map((e) => e.id);
  //   }

  //   setIsSaving(true);

  //   switch (type) {
  //     case 1:
  //       booking = { ...booking, reservation_note: val };

  //       props.setSelectedBooking(booking);
  //       updateBooking(booking);
  //       break;
  //     case 2:
  //       setGuest({ ...guest, general_note: val });
  //       break;
  //     case 3:
  //       setGuest({ ...guest, special_relationship: val });
  //       break;
  //     case 4:
  //       setGuest({ ...guest, seating_preference: val });
  //       break;
  //     case 5:
  //       setGuest({ ...guest, food_drink_preference: val });
  //       break;
  //   }
  // };

  // const handleOnChange = (e, type) => {
  //   debouncedSave(e, type);
  // };
  const [bookingHistory, setBookingHistory] = useState([
    { label: "On Account Spend", value: "$ 0" },
    { label: "On-Going Spend", value: "$ 0" },
    { label: "Avg Spend", value: "$ 0" },
    { label: "Total Spend", value: "$ 0" },
    { label: "Total Visits", value: 0 },
    { label: "Avg Turn Time", value: 0 },
  ]);
  useEffect(() => {
    const callback = (err, data) => {
      if (err) {
        console.log(err);
      } else {
        const historyData = data.data;
        console.log(historyData);
        setBookingHistory([
          {
            label: "On Account Spend",
            value:
              Number(historyData.on_account_spend) === 0
                ? "$ 0"
                : `$ ${historyData.on_account_spend.toFixed(2)}`,
          },
          { label: "On-Going Spend", value: `$ ${historyData.on_going_spend}` },
          { label: "Avg Spend", value: `$ ${historyData.avg_spend}` },
          { label: "Total Spend", value: `$ ${historyData.total_spend}` },
          { label: "Total Visits", value: historyData.total_visits },
          {
            label: "Avg Turn Time",
            value: moment
              .duration(historyData.avg_turn_time, "seconds")
              .asHours()
              .toFixed(0),
          },
        ]);
      }
    };
    dispatch(getBookingHistoryRequest(guest.id, callback));
  }, [props.selectedBooking]);

  const handleSetOpenCalendar = () => {
    if (!openCalendar) {
      setOpenCalendar(true);
    }
  };

  const handleOpenEditName = () => {
    if (!openEditName) {
      setOpenEditName(true);
    }
  };

  const handleCloseEditName = (guest) => {
    if (openEditName) {
      setOpenEditName(false);
    }
    let booking = props.selectedBooking;
    booking = { ...booking, guest: guest };

    if (booking.table_ids === undefined) {
      booking["table_ids"] = booking.table.map((e) => e.id);
    }
    props.setSelectedBooking(booking);
    updateBooking(booking);
  };
  const editPartySizeTableHandle = (partySize, tables) => {
    let booking = props.selectedBooking;

    booking = { ...booking, party_size: partySize, table: tables };

    if (booking.table_ids === undefined) {
      console.log(booking);
      booking["table_ids"] = booking.table?.map((e) => e.id);
    }

    props.setSelectedBooking(booking);
    updateBooking(booking);
  };

  const partyPartySizeHandler = (partySize) => {
    let booking = props.selectedBooking;
    if (booking.table_ids === undefined) {
      booking["table_ids"] = booking.table.map((e) => e.id);
    }

    booking = {
      ...booking,
      status: BOOKINGSTATUS.partiallySeated,
      partial_seated: partySize,
    };
    props.setSelectedBooking(booking);
    updateBooking(booking);
  };

  const selectedStatusOption = (option) => {
    if (option.value === BOOKINGSTATUS.partiallySeated) {
      setOpenPartialPartySize(true);
      return;
    }

    let booking = props.selectedBooking;
    if (booking.table_ids === undefined) {
      booking["table_ids"] = booking.table.map((e) => e.id);
    }

    booking = { ...booking, status: option.value };
    props.setSelectedBooking(booking);
    updateBooking(booking);
  };

  const updateSelectedDate = (date) => {
    setTestDate(date);
    let booking = props.selectedBooking;

    console.log(date);
    const filteredShifts = filterShifts(
      props.shifts,
      moment(date).isoWeekday()
    );
    const nearestDateShift = filteredShifts.filter(
      (item) => item.id === expShiftId
    );
    const expData = props.experienceList.filter((item) => item.id === expId);

    if (booking.table_ids === undefined) {
      booking["table_ids"] = booking.table.map((e) => e.id);
    }
    if (booking.experience_id > 0) {
      if (nearestDateShift.length > 0) {
        booking = {
          ...booking,
          start_time: booking.start_time,
          end_time: booking.start_time + booking.session_time,
          start_date: updateDateTime(date, booking.start_time),
          end_date: updateDateTime(
            date,
            booking.end_time + booking.session_time
          ),
        };
      } else {
        setExpName(expData[0].exp_name);
        setHideShiftAlert(true);
        return;
      }
    } else {
      booking = {
        ...booking,
        start_time: booking.start_time,
        end_time: booking.start_time + booking.session_time,
        start_date: updateDateTime(date, booking.start_time),
        end_date: updateDateTime(date, booking.end_time + booking.session_time),
      };
    }

    props.setSelectedBooking(booking);
    updateBooking(booking);
  };

  const updateSelectedBookingType = (type) => {
    let booking = props.selectedBooking;
    if (booking.table_ids === undefined) {
      booking["table_ids"] = booking.table.map((e) => e.id);
    }
    switch (type) {
      case BOOKINGTYPE.inhouse:
        if (props.selectedBooking.experience_id > 0) {
          handleUnmountExp();
          booking = {
            ...booking,
            type: type,
            experience_id: null,
            experience_no_of_tickets: null,
          };
        } else {
          booking = {
            ...booking,
            type: type,
          };
        }
        break;
      case BOOKINGTYPE.phone:
        if (props.selectedBooking.experience_id > 0) {
          handleUnmountExp();
          booking = {
            ...booking,
            type: type,
            experience_id: null,
            experience_no_of_tickets: null,
          };
        } else {
          booking = {
            ...booking,
            type: type,
          };
        }
        break;
      case BOOKINGTYPE.widget:
        if (props.selectedBooking.experience_id > 0) {
          handleUnmountExp();
          booking = {
            ...booking,
            type: type,
            experience_id: null,
            experience_no_of_tickets: null,
          };
        } else {
          booking = {
            ...booking,
            type: type,
          };
        }
        break;
      case BOOKINGTYPE.experience:
        handleOpenBookingExperience();
        break;
    }

    props.setSelectedBooking(booking);
    updateBooking(booking);
  };

  const updateSelectedExperience = (value) => {
    let booking = props.selectedBooking;
    if (expShiftId > 0) {
      const shift = props.shifts.filter((item) => item.id === expShiftId)[0];
      const startTime = shift.start_time;
      const endTime = shift.end_time;
      const sessionTime = shift.session_time;

      let ticketNo = parseInt(value);
      let nearestDate = getNearestAvailableDate(
        expStartDate,
        expEndDate,
        expDaysOfWeek,
        expRecurringType,
        expRecurringValue
      );

      console.log(nearestDate);
      let filteredShifts = filterShifts(
        props.shifts,
        moment(nearestDate).isoWeekday()
      );
      let nearestDateShift = filteredShifts.filter(
        (item) => item.id === expShiftId
      );
      let expData = props.experienceList.filter((item) => item.id === expId);

      if (nearestDateShift.length > 0) {
        if (nearestDateShift[0].id === expShiftId) {
          if (booking.table_ids === undefined) {
            booking["table_ids"] = booking.table.map((e) => e.id);
          }
          if (expId === booking.experience_id) {
            if (ticketNo > 0) {
              booking = {
                ...booking,
                experience_no_of_tickets:
                  booking.experience_no_of_tickets + ticketNo,
              };
            } else {
              booking = {
                ...booking,
                experience_no_of_tickets: booking.experience_no_of_tickets,
              };
            }
          } else {
            if (ticketNo > 0) {
              if (booking.experience_no_of_tickets === 0) {
                booking = {
                  ...booking,
                  type: 3,
                  status: BOOKINGSTATUS.upcoming,
                  shift_id: expShiftId,
                  experience_id: expId,
                  experience_no_of_tickets: ticketNo,
                  start_time: startTime,
                  end_time: startTime + sessionTime,
                  start_date: updateDateTime(nearestDate, booking.start_time),
                  end_date: updateDateTime(nearestDate, endTime + sessionTime),
                };
              } else {
                booking = {
                  ...booking,
                  status: BOOKINGSTATUS.upcoming,
                  shift_id: expShiftId,
                  experience_id: expId,
                  experience_no_of_tickets:
                    booking.experience_no_of_tickets + ticketNo,
                  start_time: startTime,
                  end_time: startTime + sessionTime,
                  start_date: updateDateTime(nearestDate, startTime),
                  end_date: updateDateTime(nearestDate, endTime + sessionTime),
                };
              }
            } else {
              booking = {
                ...booking,
                status: BOOKINGSTATUS.upcoming,
                shift_id: expShiftId,
                experience_id: expId,
                experience_no_of_tickets: booking.experience_no_of_tickets,
                start_time: startTime,
                end_time: startTime + sessionTime,
                start_date: updateDateTime(nearestDate, startTime),
                end_date: updateDateTime(nearestDate, endTime + sessionTime),
              };
            }
            timeListHandler(nearestDate);
          }
          props.setSelectedBooking(booking);
          updateBooking(booking);
        }
      } else {
        setExpName(expData[0].exp_name);
        setHideShiftAlert(true);
        if (booking.experience_id > 0) {
          handleUnmountExp();
          expGetAssign(booking.experience_id);
        } else {
          handleUnmountExp();
        }
      }
    }
  };

  const statusBtnOnClickHandler = (item) => {
    let booking = props.selectedBooking;
    booking = { ...booking, status: item.value };

    if (booking.table_ids === undefined) {
      booking["table_ids"] = booking.table.map((e) => e.id);
    }

    props.setSelectedBooking(booking);
    updateBooking(booking);
  };

  const updateBooking = async (booking) => {
    const callback = (err, data) => {
      if (err) {
        console.log(err);
      } else {
        props.saveBookingHandler();
      }
    };
    dispatch(updateBookingRequest(booking, callback));
  };

  const handleCloseReservationDetails = () => {
    props.setSelectedBooking(null);
    setExpHandler(false);
    setExpDaysOfWeek([]);
    setExpStartDate("");
    setExpEndDate("");
    setExpRecurringType(-2);
    setExpRecurringValue(-1);
    setExpShiftId(0);
  };

  const handleUnmountExp = () => {
    setExpHandler(false);
    setExpDaysOfWeek([]);
    setExpStartDate("");
    setExpEndDate("");
    setExpRecurringType(-2);
    setExpRecurringValue(-1);
    setExpShiftId(0);
    setExpId(0);
  };

  const handleCloseShiftAlert = () => {
    setHideShiftAlert(!hideShiftAlert);
  };

  const handleOpenBookingExperience = () => {
    setBookingExperience(true);
  };

  const handleCloseBookingExperience = () => {
    if (bookingExperience) {
      setBookingExperience(false);
    }
  };

  const handleTicketQuantitySubmit = (value) => {
    setTicketQuantityOpen(false);
    updateSelectedExperience(value);
  };

  const handleOpenSelectBookingType = () => {
    setSelectBookingOpen(true);
  };

  const disabledDays = (date) => {
    return getDisabledDays(
      date,
      expEndDate,
      expStartDate,
      expDaysOfWeek,
      expRecurringType,
      expRecurringValue,
      expHandler
    );
  };

  const timeListHandler = (date) => {
    const filteredShiftsData = filterShifts(
      props.shifts,
      moment(date).isoWeekday()
    );
    if (expShiftId > 0) {
      setTimeList(
        getAllTime(
          filteredShiftsData.find((e) => e.id === expShiftId),
          moment(date).format("yyyy-MM-DD")
        )
      );
    } else {
      setTimeList(
        getAllTime(filteredShiftsData, moment(date).format("yyyy-MM-DD"))
      );
    }
  };

  const experienceData = props.experienceList.filter(
    (item) => item.id === props.selectedBooking.experience_id
  );

  const expGetAssign = async (id) => {
    setExpId(id);
    try {
      const callback = (err, data) => {
        if (err) {
          console.log(err);
        } else {
          if (data.connection === null) {
            setdialogAlertMessage({
              title: "Missing Details",
              body: (
                <div>
                  Please enter details for 'No Shift ID <br />
                  Experience' experience. (eg: Start Date, <br /> Shift,.. etc)
                </div>
              ),
            });
            setAlertHandler(true);
          } else {
            setExpDaysOfWeek(data.connection.day_of_week);
            setExpStartDate(data.connection.start_date);
            setExpEndDate(data.connection.end_date);
            setExpRecurringType(data.connection.recurring_type);
            setExpRecurringValue(data.connection.recurring_value);
            setExpShiftId(data.connection.shift_id);
            setExpHandler(true);
          }
        }
      };
      dispatch(getExperienceAssignRequest({ experience_id: id }, callback));
    } catch (err) {
      console.log("Error: ", err);
    }
  };

  useEffect(() => {
    if (guest && isSaving) {
      setIsSaving(false);
      let booking = { ...props.selectedBooking, guest: guest };

      props.setSelectedBooking(booking);
      updateBooking(booking);
    }
  }, [guest]);

  useEffect(() => {
    handleUnmountExp();
    if (props.selectedBooking.experience_id > 0) {
      expGetAssign(props.selectedBooking.experience_id);
      timeListHandler(props.selectedBooking.start_date);
    } else {
      handleUnmountExp();
    }
  }, [props.selectedBooking]);

  useEffect(() => {
    timeListHandler(props.selectedBooking.start_date);
  }, [expShiftId]);

  return (
    <div className="reservation-det-wrapper">
      <div className="row1">
        <Box display="flex" alignItems="center" p={2} borderRadius={1}>
          <div
            style={{
              backgroundColor: "#d7ffca",
              width: "40px",
              height: "40px",
              borderRadius: "40px",
              textAlign: "center",
            }}
          >
            <AddIcon
              style={{
                color: "#2b772e",
              }}
              alt="add create booking"
            />
          </div>
          <Box ml={2}>
            <Typography variant="h6" color="#fff">
              {getFullname(guest)}
            </Typography>
            <Typography variant="body2" color="#bcbcbc">
              {guest.phone || "No Phone available"}
            </Typography>
            <Typography variant="body2" color="#bcbcbc">
              {guest.email || "No email available"}
            </Typography>
          </Box>
        </Box>

        {/* <div onClick={handleOpenEditName} style={{ cursor: "pointer" }}>
          {getFullname(props.selectedBooking.guest)}
        </div> */}
        <EditNameModal
          openEditName={openEditName}
          handleCloseEditName={(e) => handleCloseEditName(e)}
          modalTitle={"EDIT NAME"}
          personIcon={personIcon}
          guest={guest}
        />
        <AlertDialog
          alertHandler={alertHandler}
          dialogAlertMessage={dialogAlertMessage}
          setAlertHandler={setAlertHandler}
        />

        {props.selectedBooking.experience_id > 0 && (
          <div
            onClick={handleOpenBookingExperience}
            style={{
              fontSize: ".7em",
              fontWeight: "400",
              position: "absolute",
              right: 80,
              cursor: "pointer",
            }}
          >
            {props.selectedBooking.experience_no_of_tickets}x{" "}
            {experienceData[0].exp_name}
          </div>
        )}

        <BookingExperience
          bookingExperience={bookingExperience}
          handleCloseBookingExperience={(e) => handleCloseBookingExperience(e)}
          setTicketQuantityOpen={setTicketQuantityOpen}
          experienceList={props.experienceList}
          expGetAssign={expGetAssign}
        />
        <TicketQuantity
          ticketQuantityOpen={ticketQuantityOpen}
          setTicketQuantityOpen={setTicketQuantityOpen}
          experienceData={
            props.experienceList.filter((item) => item.id === expId)[0]
          }
          // setExpNoOfTickets={setExpNoOfTickets}
          handleTicketQuantitySubmit={handleTicketQuantitySubmit}
        />

        {/* <div className="exit-btn-wrapper">
          <img
            src={exitBtnIcon}
            width="12"
            height="12"
            alt="checker"
            onClick={() => handleCloseReservationDetails()}
          />
        </div> */}
      </div>

      <ScrollableContainer>
        {bookingHistory.length &&
          bookingHistory.map((item, index) => (
            <ItemCard
              style={{ backgroundColor: "#1b2125", padding: "8px" }}
              key={index}
            >
              <CardContent
                style={{ padding: 0, textAlign: "left", color: "#fff" }}
              >
                <Typography style={{ fontSize: "14px" }}>
                  {item.label}
                </Typography>
                <Typography>{item.value}</Typography>
              </CardContent>
            </ItemCard>
          ))}
      </ScrollableContainer>
      <Box>
        <Grid container spacing={1}>
          <Grid item xs={8}>
            <Box width="100%">
              <BookingControlPanel
                selectedBooking={props.selectedBooking}
                tableOptions={props.tableOptions}
                reservationLockTableIcon={reservationLockTableIcon}
                unlockTableIcon={unlockTableIcon}
                sectionPartyIcon={sectionPartyIcon}
                statusOption={props.statusOption}
                floor={props.floor}
                handleSetOpenCalendar={handleSetOpenCalendar}
                editPartySizeTableHandle={editPartySizeTableHandle}
                selectedStatusOption={selectedStatusOption}
                partyPartySizeHandler={partyPartySizeHandler}
                setSelectedBooking={props.setSelectedBooking}
                openPartialPartySize={openPartialPartySize}
                setOpenPartialPartySize={setOpenPartialPartySize}
                statusBtnOnClickHandler={statusBtnOnClickHandler}
                updateBooking={updateBooking}
                timeList={timeList}
                setIsSaving={setIsSaving}
                setGuest={setGuest}
                guest={guest}
                key={props.selectedBooking.id}
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box width="100%">
              <Typography variant="h6">GUEST NOTES</Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {/* <div className="content">
        <span>GUEST NOTES</span>
        <span className="row">
          <h6>General Notes</h6>
          <input
            onChange={(e) => handleOnChange(e.target.value, 2)}
            value={guest.general_note}
          ></input>
        </span>
        <span className="row">
          <h6>Special Relationship</h6>
          <input
            onChange={(e) => handleOnChange(e.target.value, 3)}
            value={guest.special_relationship}
          ></input>
        </span>
        <span className="row">
          <h6>Seating Preference</h6>
          <input
            onChange={(e) => handleOnChange(e.target.value, 4)}
            value={guest.seating_preference}
          ></input>
        </span>
        <span className="row">
          <h6>{"Food & Drink Preference"}</h6>
          <input
            onChange={(e) => handleOnChange(e.target.value, 5)}
            value={guest.food_drink_preference}
          ></input>
        </span>
        <div className="row4">
          <SelectBookingType
            selectBookingOpen={selectBookingOpen}
            handleCloseSelectBookingType={setSelectBookingOpen}
            updateSelectedBookingType={updateSelectedBookingType}
          />

          <div
            onClick={handleOpenSelectBookingType}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            {props.selectedBooking.type === 2 ? (
              <img
                src={bookingStatusPhoneIcon}
                width="25"
                height="25"
                alt="checker"
                style={{ margin: "20px 10px 20px 5px" }}
              />
            ) : props.selectedBooking.type === 1 ? (
              <img
                src={bookingStatusWalkIn}
                width="25"
                height="25"
                alt="checker"
                style={{ margin: "20px 10px 20px 5px" }}
              />
            ) : props.selectedBooking.type === 0 ? (
              <img
                src={bookingStatusNetwork}
                width="25"
                height="25"
                alt="checker"
                style={{ margin: "20px 10px 20px 5px" }}
              />
            ) : props.selectedBooking.type === 3 ? (
              <img
                src={experienceIcon}
                width="25"
                height="25"
                alt="checker"
                style={{ margin: "20px 10px 20px 5px" }}
              />
            ) : (
              ""
            )}

            {getBookingType(props.selectedBooking)}
          </div>
        </div>
        <div className="row5">
          <ToggleSwitch /> Exclude party from pacing limit
        </div>
        <p>
          Booking taken by
          {getBookingTakenFullname(props.selectedBooking.booking_taken)}
        </p>
      </div> */}

      <SelectBookingCalendar
        selectedDate={props.selectedBooking.start_date}
        openBookingCalendar={openCalendar}
        setSelectedDateHandler={updateSelectedDate}
        handleCloseSelectedBookingCalendar={setOpenCalendar}
        disabledDays={disabledDays}
        setBookingDate={props.setBookingDate}
      />
      {hideShiftAlert === true && (
        <Dialog open={true} onClose={handleCloseShiftAlert()}>
          <DialogTitle>
            <Alert severity="error">
              <AlertTitle>Oops...</AlertTitle>
            </Alert>
          </DialogTitle>
          <DialogContent>
            <Typography
              variant="body1"
              style={{ color: "red", fontWeight: "bold" }}
            >
              "No shift available
            </Typography>
            <Typography variant="body1"> for </Typography>
            <Typography variant="body1" style={{ fontWeight: "bold" }}>
              "{expName}"
            </Typography>
            <Typography variant="body1">
              {" "}
              Please select another experience or{" "}
            </Typography>
            <Typography variant="body1" style={{ color: "red" }}>
              edit shift for
            </Typography>
            <Typography variant="body1" style={{ fontWeight: "bold" }}>
              "{expName}"
            </Typography>
            <Typography variant="body1" style={{ color: "red" }}>
              {" "}
              on settings"
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseShiftAlert()} color="primary" autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}
export default ReservationDetails;
