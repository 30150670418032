import React, { useEffect, useState } from "react";
import { Box, Typography, InputAdornment, TextField } from "@mui/material";
import { styled } from "@mui/system";
import { daysOfWeek } from "../../../../../Shared/const";

const CustomTextField = styled(TextField)({
  "& .MuiFilledInput-input": {
    paddingTop: "16px",
    paddingBottom: "16px",
    color: "white",
  },
});

const PayRates = ({ payRates = [], setPayRates }) => {
  const [initializedPayRates, setInitializedPayRates] = useState([]);

  useEffect(() => {
    const allDays = Object.keys(daysOfWeek).map((day) => {
      const dayNumber = daysOfWeek[day];
      return (
        payRates.find((rate) => rate.day_number === dayNumber) || {
          day_number: dayNumber,
          rate: "",
        }
      );
    });
    setInitializedPayRates(allDays);
    if (payRates.length !== allDays.length) {
      setPayRates(allDays);
    }
  }, [payRates, setPayRates]);

  const handleRateChange = (index, event) => {
    const newPayRates = [...initializedPayRates];
    newPayRates[index].rate = event.target.value;
    setInitializedPayRates(newPayRates);
    setPayRates(newPayRates);
  };

  return (
    <Box height="500px" maxHeight="50vh" overflow="auto" p={2}>
      {initializedPayRates.map((pay_rates, index) => {
        const dayName = Object.keys(daysOfWeek).find(
          (key) => daysOfWeek[key] === pay_rates.day_number
        );

        if (!dayName) {
          return null;
        }

        return (
          <Box key={index} width="100%" mb={2}>
            <Typography variant="body1" color="white" pl={2}>
              {dayName}
            </Typography>
            <CustomTextField
              variant="filled"
              value={pay_rates.rate}
              onChange={(event) => handleRateChange(index, event)}
              width="200px"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Typography variant="body1" style={{ color: "white" }}>
                      per hour
                    </Typography>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        );
      })}
    </Box>
  );
};

export default PayRates;
