import React, { useEffect, useState } from "react";
import { Modal, Box, Button, TextField, Select, MenuItem } from "@mui/material";
import colors from "../../StyledComponents/ColorStyled";
import moment from "moment";
import ProfileAvatar from "../../common/ProfileAvatar";

const ShiftDetailModal = ({
  open,
  onClose,
  shift = {},
  mode = "edit",
  position,
  onShiftChange = {},
  employeeId,
  employeesState,
  onCopyShift,
  onPasteShift,
  roles,
}) => {
  const employee = employeesState.find(
    (emp) => emp.id === parseInt(employeeId, 10)
  );
  const employeeName = employee
    ? `${employee.first_name} ${employee.last_name}`
    : "Unknown Employee";
  const employeeRole = roles.find(
    (role) => role.id === employee?.employment_role_id
  );

  const displayEmployeeRole = employeeRole ? `${employeeRole.title}` : "";

  const isEditMode = mode === "edit";
  const [startTime, setStartTime] = useState(
    shift?.start_time
      ? moment().startOf("day").add(shift.start_time, "seconds").format("HH:mm")
      : ""
  );

  const [endTime, setEndTime] = useState(
    shift?.end_time
      ? moment().startOf("day").add(shift.end_time, "seconds").format("HH:mm")
      : ""
  );

  const [breakTime, setBreakTime] = useState(
    shift?.break_time ? shift.break_time / 3600 : 0
  );
  const [section, setSection] = useState(shift?.floor_id || "");
  const [station, setStation] = useState(shift?.station_id || "");
  const [type, setType] = useState(shift?.type || 1);
  const [notes, setNotes] = useState(shift?.note || "");
  const existingShift = shift;

  const [totalHours, setTotalHours] = useState(0);
  const [totalCost, setTotalCost] = useState(0);

  useEffect(() => {
    const calculateTotalHours = () => {
      if (type === 2 || type === 3) {
        return 0;
      }

      const startSeconds = moment(startTime, "HH:mm").diff(
        moment().startOf("day"),
        "seconds"
      );
      const endSeconds = moment(endTime, "HH:mm").diff(
        moment().startOf("day"),
        "seconds"
      );
      const breakTimeSeconds = parseFloat(breakTime) * 3600;

      const totalSeconds = endSeconds - startSeconds - breakTimeSeconds;
      const totalHours = totalSeconds / 3600;

      return totalHours > 0 ? totalHours : 0;
    };

    const calculateTotalCost = () => {
      if (type === 2 || type === 3) {
        return 0;
      }

      let dayNumber = moment(shift.start_date).day();
      dayNumber = dayNumber === 0 ? 7 : dayNumber;

      const rate =
        employee?.pay_rates?.find((rate) => rate.day_number === dayNumber)
          ?.rate || 0;

      return calculateTotalHours() * rate;
    };

    setTotalHours(calculateTotalHours());
    setTotalCost(calculateTotalCost());
  }, [startTime, endTime, breakTime, shift, employee, type]);

  const handleDeleteShift = () => {
    const deletedShift = {
      ...existingShift,
      deleted: true,
    };

    onShiftChange(deletedShift);
    onClose();
  };

  const handleUpdateShift = () => {
    const updatedShift = {
      ...existingShift,
      start_time: moment(startTime, "HH:mm").diff(
        moment().startOf("day"),
        "seconds"
      ),
      end_time: moment(endTime, "HH:mm").diff(
        moment().startOf("day"),
        "seconds"
      ),
      break_time: parseFloat(breakTime) * 3600,
      floor_id: section,
      station_id: station,
      type,
      note: notes,
      totalHours: totalHours * 3600,
      totalCost: totalCost,
    };

    onShiftChange(updatedShift);
    onClose();
  };

  const handleAddShift = () => {
    const addShift = {
      id: `new-${Date.now()}`,
      employee_id: parseInt(employeeId, 10),
      start_time: moment(startTime, "HH:mm").diff(
        moment().startOf("day"),
        "seconds"
      ),
      end_time: moment(endTime, "HH:mm").diff(
        moment().startOf("day"),
        "seconds"
      ),
      break_time: parseFloat(breakTime) * 3600,
      floor_id: section,
      station_id: station,
      type: type || 1,
      note: notes,
      start_date: shift.start_date,
      totalHours: totalHours * 3600,
      totalCost: totalCost,
    };
    onShiftChange(addShift);
    onClose();
  };

  const formatHoursAndMinutes = (totalHours) => {
    const hours = Math.floor(totalHours);
    const minutes = Math.round((totalHours % 1) * 60);
    return `${hours}h ${minutes}m`;
  };

  const handleCopyShift = () => {
    onCopyShift({
      start_time: startTime,
      end_time: endTime,
      break_time: breakTime,
      floor_id: section,
      station_id: station,
      type,
      note: notes,
    });
    onClose();
  };

  const handlePasteShift = () => {
    const copiedShift = onPasteShift();
    if (copiedShift) {
      setStartTime(copiedShift.start_time);
      setEndTime(copiedShift.end_time);
      setBreakTime(copiedShift.break_time);
      setSection(copiedShift.floor_id);
      setStation(copiedShift.station_id);
      setType(copiedShift.type);
      setNotes(copiedShift.note);
    }
  };
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: colors.darkQuaternary,
          color: colors.white,
          borderRadius: "8px",
          boxShadow: 24,
          p: 4,
          maxHeight: "90vh",
          overflowY: "auto",
        }}
      >
        <Box
          sx={{
            position: "relative",
            "&::before": {
              content: '""',
              position: "absolute",
              width: "5px",
              height: "100%",
              bgcolor: employee?.color,
              left: 0,
              top: 0,
              transformOrigin: "center",
              boxShadow: "inherit",
              borderRadius: "8px",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              pl: 2,
              mb: 1,
            }}
          >
            <ProfileAvatar
              profilePicUrl={employee?.photo}
              firstName={employee.first_name}
              lastName={employee.last_name}
              color={employee.color}
              width={50}
              height={50}
            />
            <>
              <p
                style={{
                  flex: 1,
                  lineHeight: 1.2,
                  marginLeft: "8px",
                  fontWeight: "bold",
                  fontSize: "1.25rem",
                }}
              >
                {employeeName}
              </p>
            </>
            {isEditMode ? (
              <Button
                variant="contained"
                sx={{ ml: 1, height: "30px" }}
                onClick={handleCopyShift}
                className="bg-primary neo-outer-glow"
              >
                Copy
              </Button>
            ) : (
              <Button
                variant="contained"
                sx={{ ml: 1, height: "30px" }}
                onClick={handlePasteShift}
                className="bg-primary neo-outer-glow"
              >
                Paste
              </Button>
            )}
          </Box>
          <p style={{ paddingLeft: "16px", fontSize: "0.8rem", margin: "0" }}>
            {displayEmployeeRole}
          </p>
        </Box>

        {/* Form Fields */}
        <Box
          sx={{
            mt: 2,
            "& .MuiTextField-root": {
              mb: 1,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              color: colors.white,
            },
            "& .MuiInputLabel-root": {
              color: colors.white,
            },
            "& .MuiInputBase-root": {
              color: colors.white,
            },
            "& .MuiTextField-root::after": {
              borderBottom: `1px solid ${colors.white}`,
            },
          }}
        >
          <p
            style={{
              fontWeight: "bold",
              fontSize: "1.2rem",
              marginBottom: "0.2em",
            }}
          >
            {moment(shift.start_date).format("ddd, MMM D")}
          </p>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "1px solid #fff",
              mb: 2,
            }}
          >
            <p
              style={{
                margin: "0.2em 0",
              }}
            >
              Start Time
            </p>
            <TextField
              type="time"
              value={startTime}
              onChange={(e) => setStartTime(e.target.value)}
              sx={{ width: "60%", marginBottom: "8px!important" }}
              variant="standard"
              InputProps={{ disableUnderline: true }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "1px solid #fff",
              mb: 2,
            }}
          >
            <p
              style={{
                margin: "0.2em 0",
              }}
            >
              End Time
            </p>
            <TextField
              type="time"
              value={endTime}
              onChange={(e) => setEndTime(e.target.value)}
              sx={{ width: "60%", marginBottom: "8px!important" }}
              variant="standard"
              InputProps={{ disableUnderline: true }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "1px solid #fff",
              mb: 2,
            }}
          >
            <p
              style={{
                margin: "0.2em 0",
              }}
            >
              Break Time (hours)
            </p>
            <TextField
              type="number"
              value={breakTime}
              onChange={(e) => setBreakTime(e.target.value)}
              sx={{ width: "60%", marginBottom: "8px!important" }}
              variant="standard"
              InputProps={{ disableUnderline: true }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "1px solid #fff",
              mb: 2,
            }}
          >
            <p
              style={{
                margin: "0.2em 0",
              }}
            >
              Section
            </p>
            <TextField
              value={section}
              onChange={(e) => setSection(e.target.value)}
              sx={{ width: "60%", marginBottom: "8px!important" }}
              variant="standard"
              InputProps={{ disableUnderline: true }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "1px solid #fff",
              mb: 2,
            }}
          >
            <p
              style={{
                margin: "0.2em 0",
              }}
            >
              Station
            </p>
            <TextField
              value={station}
              onChange={(e) => setStation(e.target.value)}
              sx={{ width: "60%", marginBottom: "8px!important" }}
              variant="standard"
              InputProps={{ disableUnderline: true }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              borderBottom: "1px solid #fff",
              mb: 2,
            }}
          >
            <p
              style={{
                margin: "0.2em 0",
              }}
            >
              Type
            </p>
            <Select
              value={type}
              onChange={(e) => setType(e.target.value)}
              sx={{ width: "60%", marginBottom: "8px!important" }}
              variant="standard"
              disableUnderline
            >
              <MenuItem value={1}>Shift</MenuItem>
              <MenuItem value={2}>Requested Off</MenuItem>
              <MenuItem value={3}>On Leave</MenuItem>
            </Select>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "1px solid #fff",
              mb: 2,
            }}
          >
            <p
              style={{
                margin: "0.2em 0",
              }}
            >
              Notes
            </p>
            <TextField
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              sx={{ width: "60%", marginBottom: "8px!important" }}
              variant="standard"
              InputProps={{ disableUnderline: true }}
            />
          </Box>
        </Box>

        {/* Total Hours & Cost */}
        <Box
          sx={{
            textAlign: "center",
            fontWeight: "bold",
            fontSize: "1rem",
            color: colors.white,
            mt: 3,
          }}
        >
          <p
            style={{
              margin: "0.2em 0",
            }}
          >
            Total Hours & Cost: {formatHoursAndMinutes(totalHours)} - $
            {totalCost.toFixed(2)}
          </p>
        </Box>

        <Box
          sx={{
            textAlign: "center",
          }}
        >
          {isEditMode ? (
            <>
              <Button
                variant="contained"
                color="primary"
                onClick={handleDeleteShift}
                sx={{ mt: 2, mr: 2, fontWeight: "bold", fontSize: "0.8rem" }}
                className="bg-danger neo-outer-glow"
              >
                Delete Shift
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleUpdateShift}
                sx={{ mt: 2, fontWeight: "bold", fontSize: "0.8rem" }}
                className="bg-primary neo-outer-glow"
              >
                Close
              </Button>
            </>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddShift}
              sx={{ mt: 2, fontWeight: "bold", fontSize: "0.8rem" }}
              className="bg-primary neo-outer-glow"
            >
              Close
            </Button>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default ShiftDetailModal;
