import React, { useState, useEffect } from "react";
import styled from "styled-components";
import widgetIcon from "../../images/booking/widget-icon.png";
import { getFullname } from "../../utils/Utility";

const HeaderText = styled.p`
  position: absolute;
  right: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #9d9d9d;
`;

const TableRow = styled.div`
  position: relative;
  display: inline-flex;
  height: 40px;
  width: 100%;
  background: #394044;
  border-top: 1px solid #222a2f;
  border-bottom: 1px solid #222a2f;

  span {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-width: 150px;
    border-right: 1px solid #9a9a9a;
    background: #394044;
  }

  h4 {
    font-size: 18px;
    font-weight: 500;
    margin-right: 10px;
  }
`;

const TimeBlock = styled.div`
  display: inline-block;
  height: 40px;
  min-width: 40px;
  background: #394044;
  border-left: 1px solid #222a2f;
  border-bottom: 2px solid #222a2f;
`;

const BookingContainer = styled.div`
  position: absolute;
  display: flex;
  left: 150px;
  align-items: center;
  padding: 5px;
  height: 35px;
  top: 1px;
  cursor: pointer;
  border: 1px solid #222a2f;

  #icon-wrapper {
    margin-right: 5px;
  }

  #guest-name {
    font-size: 16px;
    font-weight: 700;
  }

  #party-size {
    position: absolute;
    right: 5px;
    font-size: 14px;
  }
`;

function BookingItem(props) {
  const getBookingPosition = (booking) => {
    let position = ((booking.start_time - 21600) / 900) * 40 + 149;
    return position;
  };

  const getBookingWidth = (booking) => {
    let width = ((booking.end_time - booking.start_time) / 900) * 40;
    return width;
  };

  const handleClick = (booking, table) => {
    props.handleClick(booking, table);
  };

  const dragStart = (e) => {
    const target = e.target;
    e.dataTransfer.setData("booking_id", target.id);

    setTimeout(() => {
      target.style.display = "none";
    }, 0);
  };

  const dragOver = (e) => {
    e.stopPropagation();
  };

  return (
    <>
      {props.bookings.length > 0
        ? props.bookings.map((booking, key) => {
            if (props.floorId === booking.floor_id) {
              const tables = booking.table.map((table) => {
                return table.id;
              });
              if (tables.includes(props.table.id)) {
                // console.log(`${props.table.id}` + `${booking.id}`);
                return (
                  <BookingContainer
                    onClick={() => handleClick(booking, props.table)}
                    key={key}
                    id={`${props.table.id}` + "|" + `${booking.id}`}
                    draggable={props.draggable}
                    onDragStart={dragStart}
                    onDragOver={dragOver}
                    style={
                      booking.status === 0
                        ? {
                            color: "black",
                            background: "#BDFFFE",
                            width: getBookingWidth(booking),
                            left: getBookingPosition(booking),
                          }
                        : booking.status === 4
                        ? {
                            color: "#FFFFFF",
                            background: "#fe7d86",
                            width: getBookingWidth(booking),
                            left: getBookingPosition(booking),
                          }
                        : {
                            color: "#707070",
                            background: "#F7F7F7",
                            width: getBookingWidth(booking),
                            left: getBookingPosition(booking),
                          }
                    }
                  >
                    {booking.status === 0 ? (
                      <div id="icon-wrapper">
                        <img src={widgetIcon} width={16} height={16} />
                      </div>
                    ) : (
                      ""
                    )}
                    <div id="guest-name">{getFullname(booking.guest)}</div>{" "}
                    <div id="party-size">{booking.party_size}</div>
                    {props.children}
                  </BookingContainer>
                );
              }
            }
          })
        : ""}
    </>
  );
}

function TableItem(props) {
  const [bookings, setBookings] = useState(props.bookings);
  const timeBlock = [];

  for (let i = 1; i <= 100; i++) {
    if (i % 4 === 0) {
      timeBlock.push(
        <TimeBlock style={{ borderRight: "1px solid #9a9a9a" }} key={i} />
      );
    } else {
      timeBlock.push(<TimeBlock key={i} />);
    }
  }

  // const getBookingPosition = (booking) => {
  // 	let position = ((booking.start_time - 21600) / 900) * 40 + 149;
  // 	return position;
  // };

  // const getBookingWidth = (booking) => {
  // 	let width = ((booking.end_time - booking.start_time) / 900) * 40;
  // 	return width;
  // };

  const handleClick = (booking, table) => {
    props.bookingClickHandler(booking, table);
    props.toggleTableDetails(booking);
  };

  const drop = (e) => {
    e.preventDefault();
    const booking_id = e.dataTransfer.getData("booking_id");
    const booking = document.getElementById(booking_id);
    const data = booking_id.split("|");
    const currentBooking = bookings.find((e) => e.id === parseInt(data[1]));
    props.handleChangeTable(props.table, parseInt(data[0]), currentBooking);
    booking.style.display = "block";
    // e.target.appendChild(booking);

    // setTimeout(() => {
    // 	e.target.removeChild(booking);
    // }, 0);
  };

  const dragOver = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    if (props.bookings.length > 0) {
      setBookings([...props.bookings]);
    } else {
      setBookings([]);
    }
  }, [props.bookings]);

  return (
    <TableRow
      key={props.table.id}
      onDrop={drop}
      onDragOver={dragOver}
      id="tableItem"
    >
      <span
        style={{
          position: "-webkit-sticky",
          position: "sticky",
          left: "0px",
        }}
      >
        <h4>{props.table.name}</h4>
        <HeaderText>{props.table.capacity_max}</HeaderText>
      </span>

      {timeBlock}
      <BookingItem
        bookings={bookings}
        table={props.table}
        floorId={props.floorId}
        handleClick={handleClick}
        draggable={true}
      />
      {props.children}
      {/* {renderBooking(props.table.id)} */}
    </TableRow>
  );
}

export default TableItem;
