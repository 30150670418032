import React from "react";
import { Box, Typography, Modal, Button } from "@mui/material";
import ProfileAvatar from "../common/ProfileAvatar";
import colors from "../StyledComponents/ColorStyled";
import logoWabi from "../../../../Shared/images/logo-wabi.png";

const EmployeeDetailModal = ({ employee, open, onClose, onEdit }) => {
  if (!employee) return null;
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        p={4}
        style={{
          borderRadius: "10px",
          color: colors.white,
          background: colors.secondary,
          width: 600,
          margin: "100px auto",
        }}
      >
        {/* Sections */}
        <Box display="flex" justifyContent="space-between">
          {/* Section01 */}
          <Box
            p={2}
            mb={2}
            variant="contained"
            onClick={() => onEdit(employee)}
            style={{
              background: colors.primary,
              width: "48%",
              height: "180px",
              borderRadius: "10px",
              cursor: "pointer",
            }}
            className="neo-outer-glow"
          >
            <Box textAlign="right">
              <img
                src={logoWabi}
                alt="Wabi"
                style={{ width: "45px", display: "inline" }}
              />
            </Box>
            <Box
              borderRadius={2}
              color={colors.white}
              display="flex"
              alignItems="center"
              mb={2}
            >
              <Box
                style={{
                  margin: "0 10px 0 0",
                }}
              >
                <ProfileAvatar
                  profilePicUrl={employee.photo}
                  firstName={employee.first_name}
                  lastName={employee.last_name}
                  color={employee.color}
                  width={90}
                  height={90}
                />
              </Box>
              <Typography
                variant="subtitle1"
                mt={1}
                style={{
                  color: colors.white,
                  fontWeight: "bold",
                  fontSize: "14px",
                  textAlign: "center",
                }}
              >
                {employee.first_name} {employee.last_name}
              </Typography>
            </Box>
            <Typography color={colors.white} textAlign="right" fontSize="10px">
              Hired: {employee.date_hired}
            </Typography>
          </Box>
          {/* Section02 */}
          <Box
            p={2}
            mb={2}
            style={{
              background: colors.primary,
              width: "48%",
              height: "180px",
              borderRadius: "10px",
            }}
            className="neo-outer-glow"
          >
            <Typography
              variant="subtitle1"
              mb={1}
              style={{ color: colors.white }}
            >
              Section02
            </Typography>
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between">
          {/* Section03 */}
          <Box
            p={2}
            mb={2}
            style={{
              background: colors.primary,
              width: "48%",
              height: "180px",
              borderRadius: "10px",
            }}
            className="neo-outer-glow"
          >
            <Typography
              variant="subtitle1"
              mb={1}
              style={{ color: colors.white }}
            >
              Section03
            </Typography>
          </Box>
          {/* Section04 */}
          <Box
            p={2}
            mb={2}
            style={{
              background: colors.primary,
              width: "48%",
              height: "180px",
              borderRadius: "10px",
            }}
            className="neo-outer-glow"
          >
            <Typography
              variant="subtitle1"
              mb={1}
              style={{ color: colors.white }}
            >
              Section04
            </Typography>{" "}
          </Box>
        </Box>
        {/* Edit Button */}
        {/* <Button
          variant="contained"
          onClick={() => onEdit(employee)}
          style={{
            textTransform: "none",
            background: colors.tertiary,
            borderRadius: "10px",
            color: colors.white,
            marginTop: "10px",
          }}
        >
          Edit
        </Button> */}
      </Box>
    </Modal>
  );
};

export default EmployeeDetailModal;
