import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
//@mui
import { Box, TextField, Button, Typography } from "@mui/material";
//assets
import "../styles/Login.css";
import logo from "../../../Shared/images/logo.svg";
//validations
import {
  emailValidationRules,
  passwordValidationRules,
} from "../validations.ts";
//hooks
import { auth } from "../useUser.ts";
//utils
import Cookies from "js-cookie";
import AlertDialog from "../../../Shared/components/AlertDialog.jsx";
import Loading from "../../../Shared/components/Loading.jsx";

const Login = () => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    setLoading(true);
    const result = await auth(data);
    setLoading(false);
    if (!result.error) {
      Cookies.set("token", result.data.token);
      navigate("/front-of-house");
    } else {
      setMessage(result.message);
      setOpen(true);
    }
  };

  return (
    <Box className="app">
      <Box position="relative" className="form neumorphism-container">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ color: "white", margin: "auto", marginBottom: "2    0px" }}
        >
          <img src={logo} alt="WABI" width="50" height="50" />
          <Typography variant="h4" sx={{ marginLeft: "10px" }}>
            WABI
          </Typography>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            type="text"
            name="email"
            placeholder="Your email"
            fullWidth
            className="neumorphism-input wabi-input"
            {...register("email", emailValidationRules)}
          />
          {errors.email && (
            <Typography color="error">{errors.email.message}</Typography>
          )}
          <TextField
            type="password"
            name="password"
            placeholder="Password"
            fullWidth
            className="neumorphism-input wabi-input"
            {...register("password", passwordValidationRules)}
          />
          {errors.password && (
            <Typography color="error">{errors.password.message}</Typography>
          )}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className="wabi-button"
            fullWidth
            sx={{ mt: 2 }}
          >
            <Typography variant="h6">Login</Typography>
          </Button>
        </form>
        {loading && (
          <Box
            position="absolute"
            top={0}
            left={0}
            width="100%"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Loading />
          </Box>
        )}
      </Box>
      <AlertDialog
        open={open}
        handleClose={() => setOpen(false)}
        title="Something went wrong"
        message={message}
      />
    </Box>
  );
};

export default Login;
