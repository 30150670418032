import React from "react";
import { Modal, Backdrop, Fade, Box, Typography } from "@mui/material";
import PartySizeSelection from "../party-size-selection/PartySizeSelection";
import btnClose from "../../../images/booking/create-booking/button-close-icon.png";

const styles = {
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    width: "100%",
    height: "100%",
    backdropFilter: "blur(10px)",
    outline: "none",
    position: "relative",
  },
  closeButton: {
    textAlign: "center",
    margin: "10px 20px",
    cursor: "pointer",
    position: "absolute",
    top: 10,
    right: 10,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    padding: 2,
  },
};

function PartialPartySize(props) {
  const selectedPartySize = (value) => {
    props.partyPartySizeHandler(value);
    props.handleCloseSelectedBookingCalendar(false);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      sx={styles.modal}
      open={props.openPartialPartySize}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.openPartialPartySize}>
        <Box sx={styles.paper}>
          <img
            onClick={() => props.handleCloseSelectedBookingCalendar(false)}
            src={btnClose}
            width="30"
            height="30"
            style={styles.closeButton}
            alt="Close"
          />
          <Box sx={styles.container}>
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: 16,
                color: "white",
                marginBottom: 2,
              }}
            >
              PARTIAL PARTY SEATED
            </Typography>
            <PartySizeSelection
              selectedPartySize={selectedPartySize}
              partySize={12}
            />
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}

export default PartialPartySize;
