import React from "react";
import { Switch, FormControlLabel } from "@mui/material";
import colors from "../../StyledComponents/ColorStyled";

const PermissionSwitch = ({ label, checked, onChange }) => (
  <FormControlLabel
    control={<Switch checked={checked} onChange={onChange} />}
    label={label}
    labelPlacement="start"
    style={{ color: colors.white, justifyContent: "space-between" }}
  />
);

export default PermissionSwitch;
