// actions/rosterActions.js

export const requestShiftRequest = (payload, callback) => ({
  type: "REQUEST_SHIFT_REQUEST",
  payload,
  callback,
});

export const emailEmployeeRosterRequest = (payload, callback) => ({
  type: "EMAIL_EMPLOYEE_ROSTER_REQUEST",
  payload,
  callback,
});
