import React from "react";
import PermissionSwitch from "./PermissionSwitch";
import { Box, Typography } from "@mui/material";
import colors from "../../StyledComponents/ColorStyled";
import {
  STAFF_HOME_PERMISSIONS,
  STAFF_POS_PERMISSIONS,
  STAFF_TEAM_PERMISSIONS,
} from "../../../../../Shared/const";

const permissionCategories = [
  {
    title: "Home Permission",
    category: "home",
    permissions: STAFF_HOME_PERMISSIONS,
  },
  {
    title: "POS Setting Permission",
    category: "pos_setting",
    permissions: STAFF_POS_PERMISSIONS,
  },
  {
    title: "Staff Team Permissions",
    category: "team",
    permissions: STAFF_TEAM_PERMISSIONS,
  },
];

// TODO:Staffかどうかによって、表示を分ける
const StaffPermissions = ({ newEmployee, setNewEmployee }) => {
  const togglePermission = (category, permission) => {
    setNewEmployee((prevState) => ({
      ...prevState,
      permissions: {
        ...prevState.permissions,
        [category]: prevState.permissions[category] ^ permission,
      },
    }));
  };

  return (
    <Box height="500px" maxHeight="50vh" overflow="auto" p={2}>
      {permissionCategories.map(({ title, category, permissions }) => (
        <Box key={category} mb={3}>
          <Typography
            variant="body1"
            mb={2}
            style={{ background: colors.primary, padding: "10px" }}
          >
            {title}
          </Typography>
          <Box display="flex" flexDirection="column" gap={2}>
            {Object.entries(permissions).map(([label, permission]) => (
              <PermissionSwitch
                key={label}
                label={label.replace(/_/g, " ")} // Optional: Adjust label formatting
                checked={Boolean(
                  newEmployee.permissions[category] & permission
                )}
                onChange={() => togglePermission(category, permission)}
              />
            ))}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default StaffPermissions;
