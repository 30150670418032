import React, { useState } from "react";
import { Modal, Backdrop, Fade } from "@mui/material";

import btnClose from "../../images/booking/create-booking/button-close-icon.png";
import CalendarMonth from "../CreatBooking/calendar-month-navigation/CalendarMonth";
import BookingCalendar from "../CreatBooking/booking-calendar/BookingCalendar";
import moment from "moment";

const styles = {
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    width: "100%",
    height: "100%",
    backdropFilter: "blur(10px)",
    outline: "none",
  },
};

function SelectBookingCalendar(props) {
  const [bookingDate, setBookingDate] = useState(moment(props.selectedDate));
  const [month, setMonth] = useState(moment(props.selectedDate));

  const selectedDate = (date) => {
    setBookingDate(date);
    selectMonth(date);
    props.setSelectedDateHandler(moment(date).format("yyyy-MM-DD"));
    if (props.setSelectedDateChanged !== undefined) {
      props.setSelectedDateChanged(true);
      props.setDateChangeHandler(true);
    }
    props.handleCloseSelectedBookingCalendar(!props.openBookingCalendar);
  };

  const selectMonth = (selectedMonth) => {
    setMonth(selectedMonth);
  };

  // const handleCloseSelectedBookingCalendar = props.handleCloseSelectedBookingCalendar;

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      style={styles.modal}
      open={props.openBookingCalendar}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.openBookingCalendar}>
        <div style={styles.paper}>
          <div className="select-booking-calendar-container">
            <img
              onClick={() => props.handleCloseSelectedBookingCalendar(false)}
              src={btnClose}
              width="30"
              height="30"
              className="d-inline"
              style={{
                textAlign: "center",
                margin: "10px 20px",
                marginRight: 10,
                cursor: "pointer",
                position: "absolute",
                top: 10,
                right: 10,
              }}
              alt="checker"
            />
            <div className="select-booking-calendar-main-container">
              <div
                style={{
                  display: "inline-block",
                  marginBottom: 10,
                }}
              >
                <CalendarMonth month={month} selectMonth={selectMonth} />
              </div>
              <BookingCalendar
                bookingDate={bookingDate}
                month={month}
                selectedDate={selectedDate}
                disabledDays={props.disabledDays}
              />
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}

export default SelectBookingCalendar;
