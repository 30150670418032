import { styled } from "@mui/material/styles";
import { Container } from "@mui/material";

const ContainerStyled = styled(Container)(({ theme }) => ({
  backgroundColor: "#2c3e50",
  borderRadius: "15px",
  padding: "20px",
}));

export default ContainerStyled;
