import React, { useState } from "react";
import moment from "moment";
import { Box, IconButton, Typography } from "@mui/material";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

function CalendarMonth(props) {
  // Ensure the month prop is in a valid format
  const initialMonth = moment(props.month, moment.ISO_8601, true).isValid()
    ? moment(props.month)
    : moment();

  const [selectedMonth, setSelectedMonth] = useState(initialMonth);
  const [selectedMonthStr, setSelectedMonthStr] = useState(
    initialMonth.format("MMMM")
  );
  const [selectedYearStr, setSelectedYearStr] = useState(
    initialMonth.format("YYYY")
  );

  const prevMonth = () => {
    const previousMonth = moment(selectedMonth).subtract(1, "month");
    setSelectedMonth(previousMonth);
    setSelectedMonthStr(previousMonth.format("MMMM"));
    setSelectedYearStr(previousMonth.format("YYYY"));
    props.selectMonth(previousMonth);
  };

  const nextMonth = () => {
    const nextMonth = moment(selectedMonth).add(1, "month");
    setSelectedMonth(nextMonth);
    setSelectedMonthStr(nextMonth.format("MMMM"));
    setSelectedYearStr(nextMonth.format("YYYY"));
    props.selectMonth(nextMonth);
  };

  return (
    <Box
      sx={{
        width: 420,
        height: 60,
        display: "flex",
        justifyContent: "flex-end",
        userSelect: "none",
      }}
    >
      <Box
        sx={{
          background: "rgba(0, 0, 0, 0.6)",
          borderRadius: 1,
          width: 276,
          height: 60,
          marginRight: 1.5,
          position: "relative",
          display: "flex",
          alignItems: "center",
        }}
      >
        <IconButton onClick={prevMonth} sx={{ padding: "16px" }}>
          <ArrowLeftIcon sx={{ color: "white" }} />
        </IconButton>
        <Typography
          variant="h6"
          sx={{
            flexGrow: 1,
            textAlign: "center",
            color: "white",
          }}
        >
          {selectedMonthStr}
        </Typography>
        <IconButton onClick={nextMonth} sx={{ padding: "16px" }}>
          <ArrowRightIcon sx={{ color: "white" }} />
        </IconButton>
      </Box>
      <Box
        sx={{
          background: "rgba(0, 0, 0, 0.6)",
          borderRadius: 1,
          width: 132,
          height: 60,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="h6" sx={{ color: "white" }}>
          {selectedYearStr}
        </Typography>
      </Box>
    </Box>
  );
}

export default CalendarMonth;
