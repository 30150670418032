import React, { useState, useEffect, useRef } from "react";
import { Box, List, ListItem, ListItemText } from "@mui/material";
import btnUpArrowIcon from "../images/booking/up-arrow-icon.png";

const styles = {
  dropDownContainer: {
    userSelect: "none",
    WebkitTouchCallout: "none",
    WebkitUserSelect: "none",
    KhtmlUserSelect: "none",
    MozUserSelect: "none",
    MsUserSelect: "none",
  },
  dropDownHeader: {
    padding: "8px 15px",
    color: "white",
    marginRight: "10px",
    borderTopLeftRadius: "10px",
    fontSize: "14px",
    background: "rgba(0, 0, 0, 0.8)",
    height: "40px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  dropDownListContainer: {
    position: "absolute",
    bottom: "100%", // This will position the dropdown above the header
    left: 0,
    right: 0,
    zIndex: "999",
  },
  dropDownList: {
    padding: "0",
    margin: "0",
    paddingLeft: "1em",
    paddingRight: "1em",
    background: "rgba(0, 0, 0, 0.8)",
    color: "white",
    borderRadius: "10px",
    fontSize: "14px",
  },
  listItem: {
    cursor: "pointer",
    marginBottom: "0.8em",
    padding: "2px",
  },
  selectedListItem: {
    color: "#FE7D86",
  },
};

function FloorDropDown({ selectedFloor, floors, selectFloorCallBack }) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggling = () => setIsOpen(!isOpen);

  const selectFloorHandler = (floor) => {
    if (!isOpen) return;
    selectFloorCallBack(floor);
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Box sx={styles.dropDownContainer} position="relative" ref={dropdownRef}>
      <Box sx={styles.dropDownHeader} onClick={toggling}>
        {selectedFloor ? selectedFloor.floor_name : "- No Selected Floor -"}
        <img
          src={btnUpArrowIcon}
          width="9"
          height="5"
          alt="arrow"
          style={{ marginLeft: 20 }}
        />
      </Box>
      {isOpen && (
        <Box sx={styles.dropDownListContainer}>
          <List sx={styles.dropDownList}>
            {floors.map((floor) => (
              <ListItem
                key={floor.id}
                onClick={() => selectFloorHandler(floor)}
                sx={{
                  ...styles.listItem,
                  ...(floor.id === selectedFloor?.id &&
                    styles.selectedListItem),
                }}
              >
                <ListItemText primary={floor.floor_name} />
              </ListItem>
            ))}
          </List>
        </Box>
      )}
    </Box>
  );
}

export default FloorDropDown;
