import React, { useEffect, useState } from "react";
import {
  Modal,
  Backdrop,
  Fade,
  Box,
  TextField,
  Typography,
} from "@mui/material";
import exitBtnIcon from "../../../images/booking/exit-btn-table-details.png";

const styles = {
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    width: "100%",
    height: "100%",
    backdropFilter: "blur(10px)",
    outline: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 3,
    backgroundColor: "rgba(0, 0, 0, 0.6)",
  },
  contentWrapper: {
    width: "400px",
    background: "#fff",
    borderRadius: "8px",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  closeBtnWrapper: {
    alignSelf: "flex-end",
    cursor: "pointer",
  },
  icon: {
    marginTop: 5,
    marginRight: 5,
    marginBottom: 5,
  },
  inputField: {
    margin: "10px 0",
    width: "100%",
  },
  lineBreak: {
    width: "100%",
    borderBottom: "1px solid #ccc",
    margin: "10px 0",
  },
};

function EditNameModal(props) {
  const [guestName, setGuestName] = useState(props.guest);

  const handleOpenEditName = props.openEditName;

  const handleCloseEditName = () => {
    props.handleCloseEditName(guestName);
  };

  const handleOnChange = (value, type) => {
    if (type === 1) {
      setGuestName({ ...guestName, first_name: value });
    } else {
      setGuestName({ ...guestName, last_name: value });
    }
  };

  useEffect(() => {
    setGuestName(props.guest);
  }, [props.guest]);

  return (
    <Modal
      open={props.openEditName}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      sx={styles.modal}
    >
      <Fade in={handleOpenEditName}>
        <Box sx={styles.paper}>
          <Box sx={styles.contentWrapper}>
            <Box sx={styles.closeBtnWrapper} onClick={handleCloseEditName}>
              <img src={exitBtnIcon} width="12" height="12" alt="checker" />
            </Box>
            <Typography variant="h6" gutterBottom>
              <img
                src={props.personIcon}
                width="26"
                height="26"
                alt="Icon"
                style={styles.icon}
              />
              {props.modalTitle}
            </Typography>

            <TextField
              label="First Name"
              value={guestName.first_name}
              onChange={(e) => handleOnChange(e.target.value, 1)}
              placeholder="Enter text here"
              variant="outlined"
              fullWidth
              sx={styles.inputField}
            />
            <Box sx={styles.lineBreak} />
            <TextField
              label="Last Name"
              value={guestName.last_name}
              onChange={(e) => handleOnChange(e.target.value, 2)}
              placeholder="Enter text here"
              variant="outlined"
              fullWidth
              sx={styles.inputField}
            />
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}

export default EditNameModal;
