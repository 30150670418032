import React, { useState } from "react";
import {
  Modal,
  Box,
  Grid,
  Button,
  Typography,
  TextField,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
} from "@mui/material";
import colors from "../../StyledComponents/ColorStyled";
import { emailEmployeeRosterRequest } from "../../../../../actions/requestShiftActions";
import { useDispatch } from "react-redux";
const SubmitScheduleModal = ({
  open,
  handleClose,
  employees,
  onSubmit,
  scheduleDateId,
}) => {
  const [searchText, setSearchText] = useState("");
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const dispatch = useDispatch();

  // 検索フィルターの変更処理
  const handleSearchChange = (event) => {
    setSearchText(event.target.value.toLowerCase());
  };

  // 社員の選択/解除処理
  const handleToggle = (employee) => {
    const currentIndex = selectedEmployees.indexOf(employee);
    const newChecked = [...selectedEmployees];

    if (currentIndex === -1) {
      newChecked.push(employee);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setSelectedEmployees(newChecked);
  };

  // 全選択/全解除処理
  const handleSelectAll = () => {
    if (selectedEmployees.length === employees.length) {
      setSelectedEmployees([]);
    } else {
      setSelectedEmployees(employees);
    }
  };

  // 「送信」ボタンが押されたときの処理

  const handleSend = () => {
    const employeeIds = selectedEmployees.map((employee) => employee.id);
    const payload = {
      employee_schedule_date_id: scheduleDateId,
      employee_id: employeeIds,
    };
    dispatch(
      emailEmployeeRosterRequest(payload, (error, response) => {
        if (error) {
          console.error("Error:", error);
        } else {
          console.log("Response:", response);
        }
      })
    );
    onSubmit(selectedEmployees);
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{
        top: "20%",
      }}
    >
      <Box
        sx={{
          width: 400,
          bgcolor: colors.secondary,
          p: 3,
          m: "auto",
          borderRadius: 2,
          boxShadow: 24,
        }}
      >
        <TextField
          fullWidth
          variant="outlined"
          value={searchText}
          onChange={handleSearchChange}
          sx={{
            bgcolor: colors.primary,
            borderRadius: "8px",
          }}
          InputProps={{
            style: {
              color: colors.white,
            },
          }}
          placeholder="Search..."
          inputProps={{
            style: {
              color: colors.white,
            },
          }}
        />
        <List sx={{ mt: 2, maxHeight: 300, overflowY: "auto" }}>
          {employees
            .filter((employee) =>
              `${employee.first_name} ${employee.last_name}`
                .toLowerCase()
                .includes(searchText)
            )
            .map((employee) => (
              <ListItem
                key={employee.id}
                button
                onClick={() => handleToggle(employee)}
              >
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={selectedEmployees.indexOf(employee) !== -1}
                    tabIndex={-1}
                    disableRipple
                    sx={{
                      color: "white",
                      "&.Mui-checked": {
                        color: "white",
                      },
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={`${employee.first_name} ${employee.last_name}`}
                  sx={{
                    color: colors.white,
                  }}
                />
              </ListItem>
            ))}
        </List>
        <Grid container justifyContent="space-between" sx={{ mt: 2 }}>
          <Button
            onClick={handleSelectAll}
            className="bg-primary neo-outer-glow"
            sx={{
              color: colors.white,
              borderRadius: "25px",
              px: 2,
              fontWeight: "bold",
            }}
          >
            {selectedEmployees.length === employees.length
              ? "Deselect All"
              : "Sellect All"}
          </Button>

          <Button
            onClick={handleClose}
            className="bg-primary neo-outer-glow"
            sx={{
              color: colors.white,
              borderRadius: "25px",
              px: 2,
              fontWeight: "bold",
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{
              color: colors.white,
              borderRadius: "25px",
              px: 2,
              fontWeight: "bold",
            }}
            onClick={handleSend}
            className="bg-primary neo-outer-glow"
          >
            Send
          </Button>
        </Grid>
      </Box>
    </Modal>
  );
};

export default SubmitScheduleModal;
