// actions/rosterActions.js

export const getGroupScheduleRequest = (callback) => ({
  type: "GET_GROUP_SCHEDULE_REQUEST",
  callback,
});

export const createGroupScheduleRequest = (payload, callback) => ({
  type: "CREATE_GROUP_SCHEDULE_REQUEST",
  payload,
  callback,
});

export const getScheduleDateRequest = (params, callback) => ({
  type: "GET_SCHEDULE_DATE_REQUEST",
  params,
  callback,
});

export const createScheduleDateRequest = (payload, callback) => ({
  type: "CREATE_SCHEDULE_DATE_REQUEST",
  payload,
  callback,
});

export const updateScheduleDateRequest = (payload, callback) => ({
  type: "UPDATE_SCHEDULE_DATE_REQUEST",
  payload,
  callback,
});

export const removeScheduleDateRequest = (params, callback) => ({
  type: "REMOVE_SCHEDULE_DATE_REQUEST",
  params,
  callback,
});

export const getDeletedScheduleRequest = (params, callback) => ({
  type: "GET_DELETED_SCHEDULE_REQUEST",
  params,
  callback,
});

export const getEmployeeScheduleRequest = (params, callback) => ({
  type: "GET_EMPLOYEE_SCHEDULE_REQUEST",
  params,
  callback,
});

export const createEmployeeScheduleRequest = (payload, callback) => ({
  type: "CREATE_EMPLOYEE_SCHEDULE_REQUEST",
  payload,
  callback,
});
