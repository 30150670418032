import { call, put, takeLatest, all } from "redux-saga/effects";
import axios from "axios";
import cookie from "js-cookie";
import { fetchTimeslotsFailure } from "./timeslotActions";
import { take } from "lodash";

const apiUrl = process.env.REACT_APP_API_URL;
const token = cookie.get("token");

const api = axios.create({
  baseURL: apiUrl,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
});

function* getMeData(action) {
  let remoteData;
  yield api.get("https://httpbin.org/json").then((resp) => {
    remoteData = resp.data;
  });

  yield put({ type: "SET_ME_DATA", payload: remoteData });
}

function* getUser({ payload }) {
  // const { token } = payload;

  try {
    const response = yield api({
      url: "/api/token_checker",
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
    });

    if (response.status === 200) {
      const { is_subscribe, on_trial, user } = response.data.data;

      yield put({
        type: "SET_USER",
        payload: {
          token,
          user: {
            email: user.email,
            userId: user.id,
            stripeId: user.stripe_id,
            planId: user.plan_id,
            widgetToken: user.widget_token,
          },
          isSubscribed: is_subscribe,
          onTrial: on_trial,
        },
      });
    }
  } catch (err) {
    yield put({ type: "SET_LOADING" });
  }
}
const fetchTimeslotsApi = (bookingDate) => {
  return api({
    method: "GET",
    url: `/api/timeslots?date=${bookingDate}`,
  });
};

function* fetchTimeslots(action) {
  try {
    const response = yield call(fetchTimeslotsApi, action.bookingDate);
    action.callback(response.data.data.availableTimeSlots);
    // yield put(fetchTimeslotsSuccess(response.data.data.availableTimeSlots));
  } catch (error) {
    yield put(fetchTimeslotsFailure(error.message));
  }
}

const checkAvailabilityApi = (bookingDetails) => {
  return api({
    method: "POST",
    url: "/api/table/check",
    data: {
      date: bookingDetails.date,
      table_id: bookingDetails.table_id,
      floor_id: bookingDetails.floor_id,
      timeslot: bookingDetails.timeslot,
      partySize: bookingDetails.partySize,
    },
  });
};

function* checkAvailability(action) {
  try {
    const response = yield call(checkAvailabilityApi, action.bookingDetails);
    action.callback(null, response.data.data.message);
  } catch (error) {
    action.callback(error.message);
  }
}

const getFloorApi = () => {
  return api({
    url: "/api/get_floors",
    method: "POST",
    data: {},
  });
};

function* getFloor(action) {
  try {
    const response = yield call(getFloorApi);
    action.callback(null, response.data.data.floors);
  } catch (error) {
    action.callback(error.message, null);
  }
}

const getEmployeesApi = () => {
  return api({
    url: "/api/get_employees",
    method: "POST",
    data: {},
  });
};

function* getEmployees(action) {
  try {
    const response = yield call(getEmployeesApi);
    action.callback(null, response.data.data.employees);
  } catch (error) {
    action.callback(error.message, null);
  }
}

const getBusinessProfileApi = () => {
  return api({
    url: "/api/get_business_profile",
    method: "POST",
    data: {},
  });
};
function* getBusinessProfile(action) {
  try {
    const response = yield call(getBusinessProfileApi);
    if (action.callback) {
      action.callback(null, response.data.data.business_profile);
    } else {
      console.error("callback function is not provided");
    }
  } catch (error) {
    if (action.callback) {
      action.callback(error.message, null);
    } else {
      console.error("callback function is not provided");
    }
  }
}

const addEmployeeApi = (employee) => {
  return api({
    url: "/api/set_staffs",
    method: "POST",
    data: employee,
  });
};

function* addEmployee(action) {
  try {
    const response = yield call(addEmployeeApi, action.employee);
    action.callback(null, response.data);
  } catch (error) {
    action.callback(error.message, null);
  }
}

const deleteEmployeeApi = (employee) => {
  return api({
    url: "/api/delete_staffs",
    method: "POST",
    data: employee,
  });
};

function* deleteEmployee(action) {
  try {
    const response = yield call(deleteEmployeeApi, action.employee);
    action.callback(null, response.data);
  } catch (error) {
    action.callback(error.message, null);
  }
}

const updateEmployeeApi = (employee) => {
  return api({
    url: "/api/update_employee_details",
    method: "POST",
    data: employee,
  });
};

function* updateEmployee(action) {
  try {
    const response = yield call(updateEmployeeApi, action.employee);
    action.callback(null, response.data);
  } catch (error) {
    action.callback(error.message, null);
  }
}
const uploadEmployeePhotoApi = ({ id, fileObject }) => {
  const formData = new FormData();
  formData.append("id", id);
  formData.append("image", fileObject);

  console.log("PHOTOAPI FormData:", formData);

  return api({
    url: "/api/employee_upload_photo",
    method: "POST",
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

function* uploadEmployeePhoto(action) {
  try {
    const response = yield call(uploadEmployeePhotoApi, {
      id: action.params.id,
      fileObject: action.fileObject,
    });
    if (action.callback && typeof action.callback === "function") {
      action.callback(null, response.data);
    }
  } catch (error) {
    if (action.callback && typeof action.callback === "function") {
      action.callback(error.message, null);
    }
  }
}

const getEmployeementContractApi = () => {
  return api({
    url: "/api/employment_contract",
    method: "POST",
    data: {},
  });
};

function* getEmployeementContract(action) {
  try {
    const response = yield call(getEmployeementContractApi);
    action.callback(null, response.data.data.employment_contract);
  } catch (error) {
    action.callback(error.message, null);
  }
}

const addEmployeeContractApi = (contract) => {
  return api({
    url: "/api/employment_contract/add",
    method: "POST",
    data: contract,
  });
};

function* addEmployeeContract(action) {
  try {
    const response = yield call(addEmployeeContractApi, action.contract);
    action.callback(null, response.data);
  } catch (error) {
    action.callback(error.message, null);
  }
}

const getEmployeeRolesApi = () => {
  return api({
    url: "/api/employment_role",
    method: "POST",
    data: {},
  });
};

function* getEmployeeRoles(action) {
  try {
    const response = yield call(getEmployeeRolesApi);
    action.callback(null, response.data.data.employment_role);
  } catch (error) {
    action.callback(error.message, null);
  }
}

const addEmployeeRolesApi = (role) => {
  console.log(role, "role1");
  return api({
    url: "/api/employment_role/add",
    method: "POST",
    data: role,
  });
};
function* addEmployeeRoles(action) {
  try {
    const response = yield call(addEmployeeRolesApi, action.role);
    action.callback(null, response.data);
  } catch (error) {
    action.callback(error.message, null);
  }
}

const getGroupScheduleApi = () => {
  return api({
    url: "/api/get_group_schedule",
    method: "POST",
    data: {},
  });
};

function* getGroupSchedule(action) {
  try {
    const response = yield call(getGroupScheduleApi);
    action.callback(null, response.data.data);
  } catch (error) {
    action.callback(error.message, null);
  }
}

const createGroupScheduleApi = (data) => {
  return api({
    url: "/api/create_group_schedule",
    method: "POST",
    data: data,
  });
};

function* createGroupSchedule(action) {
  try {
    const response = yield call(createGroupScheduleApi, action.payload);
    action.callback(null, response.data.data);
  } catch (error) {
    action.callback(error.message, null);
  }
}

const getScheduleDateApi = (params) => {
  return api({
    url: "/api/get_schedule_date",
    method: "POST",
    data: params,
  });
};
function* getScheduleDate(action) {
  try {
    const response = yield call(getScheduleDateApi, action.params);
    action.callback(null, response.data);
  } catch (error) {
    action.callback(error.message, null);
  }
}

const createScheduleDateApi = (data) => {
  return api({
    url: "/api/create_schedule_date",
    method: "POST",
    data: data,
  });
};

function* createScheduleDate(action) {
  try {
    const response = yield call(createScheduleDateApi, action.payload);
    action.callback(null, response.data.data);
  } catch (error) {
    action.callback(error.message, null);
  }
}

const updateScheduleDateApi = (data) => {
  return api({
    url: "/api/update_schedule_date",
    method: "POST",
    data: data,
  });
};

function* updateScheduleDate(action) {
  try {
    const response = yield call(updateScheduleDateApi, action.payload);
    action.callback(null, response.data.data);
  } catch (error) {
    action.callback(error.message, null);
  }
}

const removeScheduleDateApi = (params) => {
  return api({
    url: "/api/remove_schedule_date",
    method: "POST",
    data: params,
  });
};

function* removeScheduleDate(action) {
  try {
    const response = yield call(removeScheduleDateApi, action.params);
    action.callback(null, response.data);
  } catch (error) {
    action.callback(error.message, null);
  }
}

const getDeletedScheduleApi = (params) => {
  return api({
    url: "/api/get_deleted_schedules",
    method: "POST",
    data: params,
  });
};

function* getDeletedSchedule(action) {
  try {
    const response = yield call(getDeletedScheduleApi, action.params);
    action.callback(null, response.data.data);
  } catch (error) {
    action.callback(error.message, null);
  }
}

const getEmployeeScheduleApi = (params) => {
  return api({
    url: "/api/get_employee_schedule",
    method: "POST",
    data: params,
  });
};

function* getEmployeeSchedule(action) {
  try {
    const response = yield call(getEmployeeScheduleApi, action.params);
    action.callback(null, response.data.data);
  } catch (error) {
    action.callback(error.message, null);
  }
}

const createEmployeeScheduleApi = (data) => {
  return api({
    url: "/api/create_employee_schedule",
    method: "POST",
    data: data,
  });
};

function* createEmployeeSchedule(action) {
  try {
    const response = yield call(createEmployeeScheduleApi, action.payload);
    action.callback(null, response.data.data);
  } catch (error) {
    action.callback(error.message, null);
  }
}

const requestShiftApi = (payload) => {
  return api({
    url: "/api/employee/request_shifts",
    method: "POST",
    data: payload,
  });
};

function* requestShift(action) {
  try {
    const response = yield call(requestShiftApi, action.payload);
    action.callback(null, response.data);
  } catch (error) {
    action.callback(error.message, null);
  }
}

const emailEmployeeRosterApi = (payload) => {
  return api({
    url: "/api/email_employee_roster",
    method: "POST",
    data: payload,
  });
};

function* emailEmployeeRoster(action) {
  try {
    const response = yield call(emailEmployeeRosterApi, action.payload);
    action.callback(null, response.data);
  } catch (error) {
    action.callback(error.message, null);
  }
}

const getShiftApi = () => {
  return api({
    url: "/api/get_shifts",
    method: "POST",
    data: {},
  });
};

function* getShift(action) {
  try {
    const response = yield call(getShiftApi);
    action.callback(null, response.data.data.shifts);
  } catch (error) {
    action.callback(error.message, null);
  }
}

const getExperienceListApi = () => {
  return api({
    url: "/api/experience/list",
    method: "POST",
    data: {},
  });
};
const getExperienceAssignApi = (param) => {
  return api({
    url: "/api/experience/get_assign",
    method: "POST",
    data: param,
  });
};
function* getExperienceAssign(action) {
  try {
    const response = yield call(getExperienceAssignApi, action.param);
    action.callback(null, response.data.data);
  } catch (error) {
    action.callback(error.message, null);
  }
}

function* getExperienceList(action) {
  try {
    const response = yield call(getExperienceListApi);
    action.callback(null, response.data.data.experiences);
  } catch (error) {
    action.callback(error.message, null);
  }
}

const getBookingsApi = (selectedDate, shiftId) => {
  return api({
    url: "/api/bookings",
    method: "POST",
    data: {
      date: selectedDate,
      shift_id: shiftId,
    },
  });
};

function* getBookings(action) {
  try {
    const response = yield call(
      getBookingsApi,
      action.selectedDate,
      action.shiftId
    );
    action.callback(null, response.data.data.bookings);
  } catch (error) {
    action.callback(error.message, null);
  }
}
const getPartySizesApi = () => {
  return api({
    method: "GET",
    url: "/api/partysizes",
  });
};

function* getPartySizes(action) {
  try {
    const response = yield call(getPartySizesApi);
    action.callback(null, response.data.data.partySizes);
  } catch (error) {
    action.callback(error.message, null);
  }
}
const getOrderListApi = (selectedFormatDate) => {
  return api({
    method: "POST",
    url: "/api/pos/order/list",
    data: {
      date: selectedFormatDate,
    },
  });
};

function* getOrderLists(action) {
  try {
    const response = yield call(getOrderListApi, action.selectedFormatDate);
    action.callback(null, response.data.data.orders);
  } catch (error) {
    action.callback(error.message, null);
  }
}

const updateBookingApi = (booking) => {
  return api({
    method: "POST",
    url: "/api/booking/update",
    data: booking,
  });
};
function* updateBooking(action) {
  try {
    const response = yield call(updateBookingApi, action.booking);
    action.callback(null, response.data);
  } catch (error) {
    action.callback(error.message, null);
  }
}
const serachTableApi = (params) => {
  return api({
    method: "POST",
    url: "/api/search_tables",
    data: params,
  });
};
function* searchTables(action) {
  try {
    const response = yield call(serachTableApi, action.params);
    action.callback(null, response.data.data.tables);
  } catch (error) {
    action.callback(error.message, null);
  }
}
const bookingStoreApi = (params) => {
  return api({
    method: "POST",
    url: "/api/booking/store",
    data: params,
  });
};

function* bookingStore(action) {
  try {
    const response = yield call(bookingStoreApi, action.params);
    action.callback(null, response.data.data.booking);
  } catch (error) {
    action.callback(error.message, null);
  }
}

const getBookingHistoryApi = (bookingId) => {
  return api({
    method: "POST",
    url: "/api/guest/bookings/history",
    data: {
      id: bookingId,
    },
  });
};

function* getBookingHistory(action) {
  try {
    const response = yield call(getBookingHistoryApi, action.bookingId);
    action.callback(null, response.data);
  } catch (error) {
    action.callback(error.message, null);
  }
}
const getProductsApi = () => {
  return api({
    method: "POST",
    url: "/api/pos/product/menu",
    data: {},
  });
};
function* getProducts(action) {
  try {
    const response = yield call(getProductsApi);
    action.callback(null, response.data.data.menu);
  } catch (error) {
    action.callback(error.message, null);
  }
}
const addOrderProductApi = (params) => {
  return api({
    method: "POST",
    url: "/api/pos/order",
    data: params,
  });
};
function* addOrderProduct(action) {
  try {
    const response = yield call(addOrderProductApi, action.params);
    action.callback(null, response.data.data.order);
  } catch (error) {
    action.callback(error.message, null);
  }
}
const createProductApi = (params) => {
  return api({
    method: "POST",
    url: "/api/pos/product",
    data: params,
  });
};
function* createProduct(action) {
  try {
    const response = yield call(createProductApi, action.params);
    action.callback(null, response.data.data.product);
  } catch (error) {
    action.callback(error.message, null);
  }
}
const addPhotoToProductApi = (params) => {
  return api({
    method: "POST",
    url: "/api/pos/product/photo/upload",
    data: params,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
function* addPhotoToProduct(action) {
  try {
    const response = yield call(addPhotoToProductApi, action.params);
    action.callback(null, response.data.data);
  } catch (error) {
    action.callback(error.message, null);
  }
}

const createAddonApi = (params) => {
  return api({
    method: "POST",
    url: "/api/pos/product/addon",
    data: params,
  });
};
function* createAddon(action) {
  try {
    const response = yield call(createAddonApi, action.params);
    action.callback(null, response.data.data.addon);
  } catch (error) {
    action.callback(error.message, null);
  }
}

function* getAsyncDataWatcher() {
  // businessProfileActions
  yield takeLatest("GET_BUSINESS_PROFILE_REQUEST", getBusinessProfile);
  // employeeActions
  yield takeLatest("ADD_EMPLOYEE_REQUEST", addEmployee);
  yield takeLatest("DELETE_EMPLOYEE_REQUEST", deleteEmployee);
  yield takeLatest("UPDATE_EMPLOYEE_REQUEST", updateEmployee);
  yield takeLatest("UPLOAD_EMPLOYEE_PHOTO_REQUEST", uploadEmployeePhoto);
  yield takeLatest(
    "GET_EMPLOYEEMENT_CONTRACT_REQUEST",
    getEmployeementContract
  );
  yield takeLatest("GET_EMPLOYEE_ROLES_REQUEST", getEmployeeRoles);
  yield takeLatest("ADD_EMPLOYEE_ROLE_REQUEST", addEmployeeRoles);
  yield takeLatest("ADD_EMPLOYEE_CONTRACT_REQUEST", addEmployeeContract);
  // rosterActions
  yield takeLatest("GET_GROUP_SCHEDULE_REQUEST", getGroupSchedule);
  yield takeLatest("CREATE_GROUP_SCHEDULE_REQUEST", createGroupSchedule);
  yield takeLatest("GET_SCHEDULE_DATE_REQUEST", getScheduleDate);
  yield takeLatest("CREATE_SCHEDULE_DATE_REQUEST", createScheduleDate);
  yield takeLatest("UPDATE_SCHEDULE_DATE_REQUEST", updateScheduleDate);
  yield takeLatest("REMOVE_SCHEDULE_DATE_REQUEST", removeScheduleDate);
  yield takeLatest("GET_DELETED_SCHEDULE_REQUEST", getDeletedSchedule);
  yield takeLatest("GET_EMPLOYEE_SCHEDULE_REQUEST", getEmployeeSchedule);
  yield takeLatest("CREATE_EMPLOYEE_SCHEDULE_REQUEST", createEmployeeSchedule);
  // requestShiftActions
  yield takeLatest("REQUEST_SHIFT_REQUEST", requestShift);
  yield takeLatest("EMAIL_EMPLOYEE_ROSTER_REQUEST", emailEmployeeRoster);

  yield takeLatest("GET_ME_DATA", getMeData);
  yield takeLatest("GET_USER", getUser);
  yield takeLatest("FETCH_TIMESLOTS_REQUEST", fetchTimeslots);
  yield takeLatest("CHECK_AVAILABILITY_REQUEST", checkAvailability);
  yield takeLatest("GET_FLOOR_REQUEST", getFloor);
  yield takeLatest("GET_EMPLOYEES_REQUEST", getEmployees);
  yield takeLatest("GET_SHIFT_REQUEST", getShift);
  yield takeLatest("GET_EXPERIENCE_LIST_REQUEST", getExperienceList);
  yield takeLatest("GET_BOOKINGS_REQUEST", getBookings);
  yield takeLatest("GET_PARTY_SIZES_REQUEST", getPartySizes);
  yield takeLatest("GET_ORDER_LISTS_REQUEST", getOrderLists);
  yield takeLatest("BOOKING_UPDATE_REQUEST", updateBooking);
  yield takeLatest("SEARCH_TABLES_REQUEST", searchTables);
  yield takeLatest("BOOKING_STORE_REQUEST", bookingStore);
  yield takeLatest("GET_EXPERIENCE_ASSIGN_REQUEST", getExperienceAssign);
  yield takeLatest("GET_BOOKING_HISTORY_REQUEST", getBookingHistory);
  yield takeLatest("GET_PRODUCTS_REQUEST", getProducts);
  yield takeLatest("ADD_ORDER_REQUEST", addOrderProduct);
  yield takeLatest("CREATE_PRODUCT_REQUEST", createProduct);
  yield takeLatest("ADD_PHOTO_TO_PRODUCT_REQUEST", addPhotoToProduct);
  yield takeLatest("CREATE_ADDON_REQUEST", createAddon);
}

export default function* rootSaga() {
  yield all([getAsyncDataWatcher()]);
}
