import React, { useState } from "react";
import { Box, List, ListItem, Typography, Button } from "@mui/material";
import {
  getTimeOnly,
  getFullname,
  getGuestPhoneNum,
  getTableSize,
  getFloorName,
  getBookingStatusColor,
} from "../../utils/Utility";
import { BOOKINGSTATUS, BOOKINGTYPE } from "../../constants/enum";
import { NumericFormat } from "react-number-format";
// Icons
import ExitBtn from "../../images/booking/exit-btn-table-details.png";
import noteIcon from "../../images/booking/create-booking/note-icon.png";
import billedIcon from "../../images/booking/billed-icon.png";
import widgetIcon from "../../images/booking/widget-icon.png";
import experienceIcon from "../../images/booking/create-booking/booking-option-experience-icon.png";
import phoneIcon from "../../images/booking/create-booking/booking-status-phone.png";
import TimeGraph from "./TimeGraph";
import { updateBookingRequest } from "../../../../actions/bookingActions";
import { useDispatch } from "react-redux";

const TableDetails = ({
  floorList,
  handleOpenCreateBooking,
  isChangingTable,
  saveBookingHandler,
  selectedBooking,
  selectedBookingHandler,
  selectedShift,
  selectedTableData,
  selectedTableId,
  setIsChangingTable,
  setSelectedTableId,
  setShowTableDetails,
  showTableDetails,
  showTableDetailsHandler,
  tableDetailsData,
}) => {
  const dispatch = useDispatch();
  const [isSaving, setIsSaving] = useState(false);

  const toggleShow = (booking) => {
    if (selectedBookingHandler) {
      if (!selectedBooking) {
        selectedBookingHandler(booking);
      } else if (selectedBooking.id === booking.id) {
        selectedBookingHandler(null);
      } else {
        selectedBookingHandler(booking);
      }
    }
  };

  const changeTableHandler = () => {
    if (isChangingTable) {
      setIsChangingTable(!isChangingTable);
    } else {
      let table_ids = selectedBooking.table.map((e) => e.id);
      setSelectedTableId(table_ids);

      setIsChangingTable(!isChangingTable);
    }
  };

  const changeTableSubmit = (item) => {
    let booking = selectedBooking;
    if (!booking.table_ids) {
      booking["table_ids"] = booking.table.map((e) => e.id);
    }

    booking = {
      ...booking,
      table_ids: item.length > 0 ? item : booking.table_ids,
    };

    updateBooking(booking);
  };

  const toggleCancel = () => {
    if (!isSaving) {
      setIsChangingTable(false);
      setSelectedTableId([0]);
    }
  };

  const updateBooking = async (booking) => {
    if (!isSaving) {
      setIsSaving(true);
      const callback = (err, data) => {
        if (err) {
          console.log(err);
        } else {
          saveBookingHandler();
          setShowTableDetails(false);
          selectedBookingHandler(null);
          setIsChangingTable(false);
          setSelectedTableId(0);
        }
        setIsSaving(false);
      };
      dispatch(updateBookingRequest(booking, callback));
    }
  };
  // Utility Component
  const Icon = ({ src, alt }) => (
    <img src={src} width="18" height="18" className="d-inline" alt={alt} />
  );
  return (
    <Box
      className={`table-details-container ${
        showTableDetails ? "table-details-container--active" : ""
      }`}
    >
      <Box className="close-btn-wrapper" onClick={showTableDetailsHandler}>
        <img src={ExitBtn} width="15" height="15" alt="close" />
      </Box>
      <Box className="col1">
        <Box display="flex" alignItems="center">
          <Box
            className={
              selectedTableData.some((e) => e.status === 4)
                ? "dot dot--seated"
                : "dot"
            }
          />
          <Typography className="tableDetails-table-name">
            {selectedTableData.length > 0
              ? selectedTableData[0].table[0].name
              : tableDetailsData.name}
          </Typography>
        </Box>
      </Box>

      <Box className="col2">
        <Box className="graph-wrapper">
          <Typography>SCHEDULE</Typography>
          <TimeGraph
            shift={selectedShift}
            bookingsOnTable={selectedTableData}
          />
        </Box>
      </Box>

      <Box
        className={`tableDetail-contentWrapper ${
          isChangingTable ? "tableDetail-contentWrapper-isChangingTable" : ""
        }`}
      >
        {selectedTableData.length > 0 ? (
          <List>
            {selectedTableData.map((item, key) => (
              <ListItem
                key={key}
                className={
                  selectedBooking?.id === item.id
                    ? "col3 col3--selected"
                    : "col3 timeDisplay span1 cxInfo span-name"
                }
                onClick={() => toggleShow(item)}
              >
                <Box className="timeDisplay">
                  <Typography style={{ color: "#fff" }} className="span1">
                    {getTimeOnly(item.start_date)}
                  </Typography>
                </Box>

                <Box className="cxInfo">
                  <Typography className="span-name">
                    {getFullname(item.guest)}
                  </Typography>
                  <NumericFormat
                    value={getGuestPhoneNum(item.guest)}
                    displayType={"text"}
                    format="#### #### ####"
                    className="span-no"
                  />
                  <Typography className="span-guest">
                    {getTableSize(item) +
                      " / " +
                      getFloorName(item.floor_id, floorList)}
                  </Typography>
                </Box>

                <Box className="table-no-info">
                  <Typography
                    style={{
                      color: getBookingStatusColor(
                        item.status,
                        item.start_date
                      ),
                      border:
                        "1px solid " +
                        getBookingStatusColor(item.status, item.start_date),
                      textAlign: "center",
                    }}
                  >
                    {item.table[0].name}
                  </Typography>
                </Box>

                <Box className="noteIcon-wrapper">
                  {item.reservation_note && <Icon src={noteIcon} alt="note" />}
                  {item.status === BOOKINGSTATUS.billed && (
                    <Icon src={billedIcon} alt="billed" />
                  )}
                  {item.type === BOOKINGTYPE.widget && (
                    <Icon src={widgetIcon} alt="widget" />
                  )}
                  {item.type === BOOKINGTYPE.experience && (
                    <Icon src={experienceIcon} alt="experience" />
                  )}
                  {item.type === BOOKINGTYPE.phone && (
                    <Icon src={phoneIcon} alt="phone" />
                  )}
                </Box>
              </ListItem>
            ))}
          </List>
        ) : (
          <Box className="btn-walk-in-wrapper"></Box>
        )}
      </Box>

      <Box className="footer">
        {selectedBooking ? (
          <Box
            className="tableDetails-footer"
            sx={{
              margin: "0 8px",
              display: "flex",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            {isChangingTable ? (
              <>
                <Button
                  variant="contained"
                  className="tableDetails-footer-btn"
                  onClick={() => changeTableSubmit(selectedTableId)}
                >
                  Change Assignment
                </Button>
                <Button
                  variant="contained"
                  className="tableDetails-footer-btn"
                  onClick={toggleCancel}
                >
                  Cancel
                </Button>
              </>
            ) : (
              <Button
                variant="contained"
                className="tableDetails-footer-btn"
                onClick={changeTableHandler}
              >
                Change Table
              </Button>
            )}
          </Box>
        ) : (
          <Box
            className="tableDetails-footer"
            sx={{
              margin: "0 8px",
              display: "flex",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            <Button
              variant="contained"
              onClick={() => handleOpenCreateBooking()}
              className="tableDetails-footer-btn"
            >
              Make Reservations
            </Button>
            <Button variant="contained" className="tableDetails-footer-btn">
              Block Table by Time
            </Button>
            <Button variant="contained" className="tableDetails-footer-btn">
              Block Table Manually
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default TableDetails;
