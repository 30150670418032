import moment from "moment";
import React from "react";

function ShiftIndicator(props) {
  const goToCurrentDate = () => {
    props.setSelectedDate(moment().format("yyyy-MM-DD"));
    props.setSelectedDateChanged(true);
  };

  return (
    <div className="bg-shift-indicator" onClick={() => goToCurrentDate()}>
      <div
        className={
          props.selectedShift !== undefined
            ? "shift-indicator"
            : "shift-indicator no-shift"
        }
      ></div>
    </div>
  );
}

export default ShiftIndicator;
