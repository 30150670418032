import React from "react";
import { Box } from "@mui/material";

const styles = {
  wrapper: {
    width: "100%",
    height: "100%",
    position: "absolute",
    display: "inline-flex",
    justifyContent: "center",
    zIndex: 5,
  },
  content: {
    display: "inline-flex",
    width: "auto",
    height: "auto",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "10px",
    padding: "20px",
    color: "white",
  },
  loading: {
    width: "24px",
    height: "24px",
    margin: "0 5px",
    backgroundColor: "#fe7d86",
    borderRadius: "50%",
    display: "inline-block",
    animationName: "dots",
    animationDuration: "700ms",
    animationIterationCount: "infinite",
    animationTimingFunction: "ease",
  },
  loading2: {
    animationDelay: "750ms",
  },
  loading3: {
    animationDelay: "800ms",
  },
  "@keyframes dots": {
    "50%": {
      opacity: 1,
      transform: "scale(0.1) translateY(10px)",
    },
  },
};

function DotLoader() {
  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.content}>
        <Box sx={styles.loading}></Box>
        <Box sx={{ ...styles.loading, ...styles.loading2 }}></Box>
        <Box sx={{ ...styles.loading, ...styles.loading3 }}></Box>
      </Box>
    </Box>
  );
}

export default DotLoader;
