import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import moment from "moment";
import colors from "../../StyledComponents/ColorStyled";
import {
  ShiftCell,
  WeekViewContainer,
  DateHeader,
  EmployeeRow,
  ShiftBlock,
  DateHeaderTypography,
  EmployeeName,
} from "../../StyledComponents/ScheduleViewModalStyles";
import ShiftDetailModal from "./ShiftDetailModal";
import _, { set } from "lodash";
const generateDateRange = (startDate, endDate) => {
  const dates = [];
  let currentDate = new Date(startDate);
  const lastDate = new Date(endDate);
  while (currentDate <= lastDate) {
    dates.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return dates.map((date) => date.toISOString().split("T")[0]);
};

const formatTime = (seconds) => {
  return moment().startOf("day").add(seconds, "seconds").format("hh:mm A");
};

const WeekView = ({
  setEmployeeShifts,
  schedule,
  roles,
  employeesSchedule = { employee_shifts: [] },
  selectedEmployees = [],
  onDisplayedEmployeesChange,
  employees,
  onTotalHoursChange,
  onTotalCostsChange,
  employeeTable,
  setEmployeeTable,
  handlePasteShift,
}) => {
  const [employeesState, setEmployeesState] = useState([]);
  const [selectedShift, setSelectedShift] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState("edit");
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [updatedShift, setUpdatedShift] = useState([]);
  const [copiedShift, setCopiedShift] = useState(null);
  const [copiedShiftCalendar, setCopiedShiftCalendar] = useState(null);
  const [isShiftDetailUpdated, setIsShiftDetailUpdated] = useState(false);

  useEffect(() => {
    const transformEmployeeSchedule = (employeesSchedule) => {
      if (!employeesSchedule || !employeesSchedule.employee_shifts) {
        return {};
      }

      const employeeShiftsArray = Array.isArray(
        employeesSchedule.employee_shifts
      )
        ? employeesSchedule.employee_shifts
        : Object.values(employeesSchedule.employee_shifts);

      const result = {};

      employeeShiftsArray.forEach((employee) => {
        const employeeId = employee.id;

        const originalEmployee = employees.find(
          (originalEmp) => originalEmp.id === employeeId
        );

        result[employeeId] = employee?.shifts?.map((shift) => {
          const startSeconds = shift.start_time;
          const endSeconds = shift.end_time;
          const breakTimeSeconds = shift.break_time;

          let totalSeconds = 0;
          if (shift.type !== 2 && shift.type !== 3) {
            totalSeconds = endSeconds - startSeconds - breakTimeSeconds;
          }

          const dayNumber = moment(shift.start_date).day() + 1;

          const rate =
            originalEmployee?.pay_rates?.find(
              (rate) => rate.day_number === dayNumber
            )?.rate || 0;

          const totalCost = (totalSeconds / 3600) * rate;

          return {
            break_time: shift.break_time || 0,
            employee_id: shift.employee_id,
            end_time: shift.end_time || 0,
            floor_id: shift.floor_id || "",
            id: shift.id,
            note: shift.note || "",
            start_date: shift.start_date,
            start_time: shift.start_time || 0,
            station_id: shift.station_id || "",
            type: shift.type || "",
            totalHours: totalSeconds > 0 ? totalSeconds : 0,
            totalCost: totalCost > 0 ? totalCost : 0,
            deleted: shift.deleted || false,
            pay_rates: originalEmployee?.pay_rates || [],
          };
        });
      });

      return result;
    };

    const formattedSchedule = transformEmployeeSchedule(employeesSchedule);

    if (!isShiftDetailUpdated && !_.isEqual(formattedSchedule, employeeTable)) {
      setEmployeeTable(formattedSchedule);
      setEmployeeShifts(formattedSchedule);
    }

    console.log("formattedSchedule", formattedSchedule);
  }, [employeesSchedule, employeesState]);

  useEffect(() => {
    if (employeesSchedule.employee_shifts) {
      const employeeArray = Array.isArray(employeesSchedule.employee_shifts)
        ? employeesSchedule.employee_shifts
        : Object.values(employeesSchedule.employee_shifts);

      const employeeArrayWithPayRates = employeeArray.map((employee) => {
        const originalEmployee = employees.find(
          (originalEmp) => originalEmp.id === employee.id
        );

        return {
          ...employee,
          pay_rates: originalEmployee ? originalEmployee.pay_rates || [] : [],
          employment_role_id: originalEmployee
            ? originalEmployee.employment_role_id || null
            : null,
          photo: originalEmployee ? originalEmployee.photo || null : null,
        };
      });

      setEmployeesState(employeeArrayWithPayRates);
      onDisplayedEmployeesChange(employeeArrayWithPayRates);
    }
  }, [employeesSchedule, employees]);

  useEffect(() => {
    console.log("111employeeTable", employeeTable);
  }, [employeeTable]);

  const handleShiftClick = (shift, mode, event, employeeId, date) => {
    const rect = event.target.getBoundingClientRect();
    setSelectedShift(shift || { start_date: date });
    setSelectedEmployee(employeeId);
    setModalMode(mode);
    setModalPosition({ top: rect.top, left: rect.left + rect.width / 2 });
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedShift(null);
  };

  const handleCopyShift = (shift) => {
    setCopiedShift(shift);
    setCopiedShiftCalendar(moment());
  };

  useEffect(() => {
    if (employeesSchedule.employee_shifts) {
      onDisplayedEmployeesChange(employeesSchedule.employee_shifts);
    }
  }, [employeesSchedule, onDisplayedEmployeesChange]);

  const dates = generateDateRange(schedule.start_date, schedule.end_date);

  const handleShiftChange = (newShift) => {
    const updatedEmployees = { ...employeeTable };

    if (!updatedEmployees[newShift.employee_id]) {
      updatedEmployees[newShift.employee_id] = [];
    }

    const existingShiftIndex = updatedEmployees[newShift.employee_id].findIndex(
      (shift) => shift.id === newShift.id
    );

    if (newShift.deleted) {
      if (typeof newShift.id === "string" && newShift.id.startsWith("new-")) {
        if (existingShiftIndex > -1) {
          updatedEmployees[newShift.employee_id].splice(existingShiftIndex, 1);
        }
      } else if (existingShiftIndex > -1) {
        updatedEmployees[newShift.employee_id][existingShiftIndex] = {
          ...updatedEmployees[newShift.employee_id][existingShiftIndex],
          deleted: true,
        };
      }
    } else {
      if (existingShiftIndex > -1) {
        updatedEmployees[newShift.employee_id][existingShiftIndex] = newShift;
      } else {
        if (typeof newShift.id === "string" && newShift.id.startsWith("new-")) {
          newShift.id = `new-${Date.now()}-${Math.random()}`;
        }
        updatedEmployees[newShift.employee_id].push(newShift);
      }
    }

    setEmployeeTable(updatedEmployees);
    setEmployeeShifts(updatedEmployees);
    setIsModalOpen(false);
  };

  useEffect(() => {
    const totalHoursByEmployee = {};
    Object.keys(employeeTable).forEach((employeeId) => {
      const shifts = employeeTable[employeeId] || [];
      const totalHours = shifts
        .filter((shift) => !shift.deleted)
        .reduce((acc, shift) => acc + shift.totalHours, 0);
      totalHoursByEmployee[employeeId] = totalHours;
    });

    const totalHoursAllEmployees = Object.values(totalHoursByEmployee).reduce(
      (acc, totalHours) => acc + totalHours,
      0
    );
    if (onTotalHoursChange) {
      onTotalHoursChange(totalHoursAllEmployees);
    }
  }, [employeeTable, setEmployeeShifts]);

  useEffect(() => {
    const totalCostsByEmployee = {};
    Object.keys(employeeTable).forEach((employeeId) => {
      const shifts = employeeTable[employeeId] || [];
      const totalCosts = shifts
        .filter((shift) => !shift.deleted)
        .reduce((acc, shift) => acc + shift.totalCost, 0);
      totalCostsByEmployee[employeeId] = totalCosts;
    });

    const totalCostsAllEmployees = Object.values(totalCostsByEmployee).reduce(
      (acc, totalCosts) => acc + totalCosts,
      0
    );

    console.log("totalCostsAllEmployees", totalCostsAllEmployees);
    if (onTotalCostsChange) {
      onTotalCostsChange(totalCostsAllEmployees);
    }
  }, [employeesState, employeeTable, setEmployeeShifts, onTotalCostsChange]);

  const onPasteShiftClick = () => {
    handlePasteShift();
    setEmployeeShifts();
  };

  return (
    <WeekViewContainer>
      <DateHeader
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 1,
          backgroundColor: colors.darkPrimary,
        }}
      >
        <Box sx={{ flex: 1 }} />
        {dates.map((date) => (
          <Box key={date} sx={{ flex: 1, textAlign: "center" }}>
            <DateHeaderTypography>
              {new Date(date).toLocaleDateString("en-US", {
                weekday: "short",
                month: "short",
                day: "numeric",
              })}
            </DateHeaderTypography>
          </Box>
        ))}
      </DateHeader>
      <Box sx={{ overflowY: "auto", maxHeight: "calc(100vh - 100px)" }}>
        {Object.keys(employeeTable).map((employeeId) => {
          const employeeShifts = employeeTable[employeeId];
          const employeeArray = Array.isArray(
            employeesSchedule?.employee_shifts
          )
            ? employeesSchedule.employee_shifts
            : Object.values(employeesSchedule?.employee_shifts || {});

          const employeeData = employeeArray.find(
            (emp) => emp.id.toString() === employeeId.toString()
          );

          return (
            <EmployeeRow key={employeeId}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flex: 1,
                  color: colors.white,
                  padding: "0 16px",
                }}
              >
                <EmployeeName>
                  {employeeData?.first_name} {employeeData?.last_name}
                </EmployeeName>
              </Box>
              {dates.map((date) => {
                const shiftsForDate = employeeShifts?.filter(
                  (shift) => shift.start_date === date && !shift.deleted
                );

                return (
                  <ShiftCell key={date}>
                    <Box
                      onClick={(e) =>
                        handleShiftClick(null, "add", e, employeeId, date)
                      }
                      style={{
                        width: "100%",
                        height: "100%",
                        padding: "15px 0",
                      }}
                    >
                      {shiftsForDate?.length > 0 ? (
                        shiftsForDate.map((displayShift) => (
                          <ShiftBlock
                            key={displayShift.id}
                            shifttype={displayShift.type}
                            onClick={(e) => {
                              e.stopPropagation();
                              const employeeShifts =
                                employeeTable[employeeId] || [];
                              const foundShift = employeeShifts.find(
                                (shift) => shift.id === displayShift.id
                              );
                              handleShiftClick(
                                foundShift || displayShift,
                                "edit",
                                e,
                                employeeId,
                                date
                              );
                            }}
                          >
                            {displayShift.type === 1 ? (
                              <>
                                {formatTime(displayShift.start_time)} -{" "}
                                {formatTime(displayShift.end_time)}
                              </>
                            ) : displayShift.type === 2 ? (
                              <>Requested Off</>
                            ) : displayShift.type === 3 ? (
                              <>On Leave</>
                            ) : null}
                          </ShiftBlock>
                        ))
                      ) : (
                        <ShiftBlock
                          empty="true"
                          onClick={(e) =>
                            handleShiftClick(null, "add", e, employeeId, date)
                          }
                        />
                      )}
                    </Box>
                  </ShiftCell>
                );
              })}
            </EmployeeRow>
          );
        })}
      </Box>

      {isModalOpen && (
        <>
          <ShiftDetailModal
            open={isModalOpen}
            setEmployeeShifts={setEmployeeShifts}
            onClose={handleCloseModal}
            shift={selectedShift}
            mode={modalMode}
            position={modalPosition}
            setEmployeesState={setEmployeesState}
            selectedEmployees={selectedEmployees}
            employee={selectedEmployee}
            onShiftChange={handleShiftChange}
            handleUpdateShift={handleShiftChange}
            handleAddShift={handleShiftChange}
            handleDeleteShift={handleShiftChange}
            employeeId={selectedEmployee}
            employeesState={employeesState}
            onCopyShift={handleCopyShift}
            onPasteShift={onPasteShiftClick}
            roles={roles}
          />
        </>
      )}
    </WeekViewContainer>
  );
};

export default WeekView;
