import React from "react";
import { TABLETYPE } from "../../constants/enum";

const SemiCircularProgressBar = ({
  percentage,
  background,
  progressbarColor,
  tableType,
}) => {
  const radius = 32;
  const stroke = 3;
  const normalizedRadius = radius - stroke / 2;
  const circumference = normalizedRadius * Math.PI;
  const strokeDashoffset = circumference - (percentage / 100) * circumference;
  const baseStyle = {
    position: "absolute",
    zIndex: 1,
    left: "7px",
    bottom: "2px",
    transform: "scale(1,-1)",
  };
  if (tableType === TABLETYPE.halfSeatRoundTable) {
    baseStyle.left = "12px";
  }
  return (
    <svg height={radius + stroke} width={radius * 2 + stroke} style={baseStyle}>
      <path
        d={`
          M ${stroke / 2},${radius + stroke / 2}
          a ${normalizedRadius},${normalizedRadius} 0 0,1 ${
          normalizedRadius * 2
        },0
        `}
        fill="none"
        stroke={background}
        strokeWidth={stroke}
        strokeLinecap="round"
      />
      <path
        d={`
          M ${stroke / 2},${radius + stroke / 2}
          a ${normalizedRadius},${normalizedRadius} 0 0,1 ${
          normalizedRadius * 2
        },0
        `}
        fill="none"
        stroke={progressbarColor}
        strokeWidth={stroke}
        strokeDasharray={circumference}
        strokeDashoffset={strokeDashoffset}
        strokeLinecap="round"
      />
    </svg>
  );
};

export default SemiCircularProgressBar;
