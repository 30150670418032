import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Card,
  CardContent,
  Switch,
  Modal,
  Select,
  MenuItem,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { createAddonRequest } from "../../../../actions/productsActions";
import ColorPickerModal from "./ColorPickerModal";
import { getRelativeLuminance } from "../../utils/Utility";

const CustomTextField = ({ label, value, onChange }) => (
  <Box className="p-6 flex items-center mb-2 border-t border-solid border-gray">
    <Typography className="text-white text-sm min-w-72">{label}</Typography>
    <TextField
      fullWidth
      placeholder={label}
      variant="outlined"
      value={value}
      onChange={onChange}
      className="text-white rounded border border-solid border-gray"
      sx={{
        "& .MuiOutlinedInput-root": {
          color: "#fff",
          "& fieldset": {
            border: "none",
          },
          "& input": {
            color: "#fff",
          },
          "& ::placeholder": {
            color: "#a9a9a9",
          },
        },
      }}
    />
  </Box>
);

const ModifierModal = ({
  open,
  setOpenModifierModal,
  editAddon = null,
  setEditAddon = null,
  fetchData,
  registeredProducts,
}) => {
  const dispatch = useDispatch();
  const {
    name,
    price: editPrice,
    color,
    id: editId,
    status,
    pos_product_category_id,
  } = editAddon || {};
  const [modifierName, setModifierName] = useState(name || "");
  const [price, setPrice] = useState(editPrice || "");
  const [active, setActive] = useState(
    status ? (status === 0 ? false : true) : false
  );
  const [modifierModalColor, setModifierModalColor] = useState(color || "");
  const [colorPickerModalOpen, setOpenColorPickerModal] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState(
    pos_product_category_id || 0
  );
  const [ingredients, setIngredients] = useState("");
  const handleChange = (event) => {
    setSelectedCategoryId(event.target.value);
  };
  const handleCreateAddon = () => {
    const newAddon = {
      type: Number(price) !== 0 ? 0 : 1,
      name: modifierName,
      price: Number(price),
      quantity: 0,
      status: 1,
      category_id: selectedCategoryId,
      color: modifierModalColor,
    };
    console.log(newAddon);
    const callback = (error, product) => {
      if (!error) {
        fetchData();
        handleClose();
      } else {
        console.error(error);
      }
    };

    if (editId) {
      newAddon.id = editId;
    }

    dispatch(createAddonRequest(newAddon, callback));
  };

  const handleClose = () => {
    setOpenModifierModal(false);
    if (editAddon) setEditAddon(null);
  };

  return (
    <Modal
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      open={open}
      onClose={handleClose}
    >
      <Box
        display="flex"
        flexDirection="column"
        bgcolor="#25292d"
        color="#fff"
        width="90%"
        height="90%"
        maxHeight="90vh"
        margin="auto"
        borderRadius={1}
        overflow="auto"
      >
        <Card className="w-full h-full bg-grayDark text-white">
          <CardContent className="relative">
            <Box className="pb-2">
              <Typography variant="h5">Create Modifier</Typography>
              <Typography variant="body2">
                Create Modifier details here.
              </Typography>
            </Box>
            <Box
              sx={{ height: "calc( 90vh - 70px)" }}
              className="overflow-auto"
            >
              <CustomTextField
                label="Modifier Name"
                value={modifierName}
                onChange={(e) => setModifierName(e.target.value)}
              />
              <CustomTextField
                label="Price"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              />
              <Box className="p-6 flex items-center mb-2 border-t border-solid border-gray">
                <Typography className="text-white text-sm min-w-72">
                  Active
                </Typography>
                <Switch
                  checked={active}
                  onChange={() => setActive(!active)}
                  color="secondary"
                  sx={{
                    "& .MuiSwitch-switchBase": {
                      color: "default",
                    },
                    "& .MuiSwitch-track": {
                      backgroundColor: "default",
                    },
                    "& .MuiSwitch-switchBase.Mui-checked": {
                      color: "#7af8c8",
                    },
                    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                      backgroundColor: "#7af8c8",
                    },
                  }}
                />
              </Box>
              <Box className="p-6 flex items-center mb-2 border-t border-solid border-gray">
                <Typography className="text-white text-sm min-w-72">
                  Color
                </Typography>
                <Button
                  variant="outlined"
                  className="text-white border-white w-48 font-bold normal-case"
                  sx={{
                    backgroundColor: modifierModalColor
                      ? modifierModalColor + " !important"
                      : undefined,
                    color: modifierModalColor
                      ? getRelativeLuminance(modifierModalColor)
                      : "#fff",
                  }}
                  onClick={() =>
                    modifierModalColor
                      ? setModifierModalColor("")
                      : setOpenColorPickerModal(true)
                  }
                >
                  {modifierModalColor ? "Remove color" : "Set Color"}
                </Button>
              </Box>
              <Box className="p-6 flex items-center mb-2 border-t border-solid border-gray">
                <Typography className="text-white text-sm min-w-72">
                  Categories
                </Typography>
                <Select
                  labelId="category-select-label"
                  id="category-select"
                  value={selectedCategoryId}
                  onChange={handleChange}
                  className="text-white rounded border border-solid border-gray min-w-72"
                >
                  <MenuItem value={0} disabled>
                    <span className="text-gray-400">Next</span>
                  </MenuItem>
                  {registeredProducts?.map((category) => (
                    <MenuItem key={category.id} value={category.id}>
                      {category.name}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <Box className="p-6 flex items-center mb-2 border-t border-solid border-gray">
                <Typography className="text-white text-sm min-w-72">
                  Ingredients
                </Typography>
                <Button
                  variant="outlined"
                  className="text-white border-white"
                  onClick={() => setIngredients("some ingredients")}
                >
                  Add Ingredients
                </Button>
              </Box>
            </Box>
            <Box className="flex justify-between gap-4 absolute top-4 right-4 right-0">
              <Button
                variant="outlined"
                className="text-white border-white"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                className="bg-purple text-white"
                onClick={handleCreateAddon}
              >
                Save
              </Button>
            </Box>
          </CardContent>
        </Card>
        {colorPickerModalOpen && (
          <ColorPickerModal
            open={colorPickerModalOpen}
            setOpenColorPickerModal={setOpenColorPickerModal}
            sendColor={setModifierModalColor}
          />
        )}
      </Box>
    </Modal>
  );
};

export default ModifierModal;
