import React, { useState, useRef, useEffect } from "react";
import {
  Modal,
  Box,
  Button,
  Checkbox,
  IconButton,
  TextField,
  List,
  ListItem,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import DoDisturbAltIcon from "@mui/icons-material/DoDisturbAlt";
import { DragIndicator, Close } from "@mui/icons-material";
import _ from "lodash";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  useSortable,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import ModifierModal from "./ModifierModal";

const AllModsModal = ({
  open,
  registeredProducts,
  handleClose,
  handleAddModifier,
  fetchData,
  propSelectedCategory,
}) => {
  const [selectedCategory, setSelectedCategory] =
    useState(propSelectedCategory);
  const [selectedAddOns, setSelectedAddOns] = useState([]);
  const [pageContentHeight, setPageContentHeight] = useState(0);
  const [openModifierModal, setOpenModifierModal] = useState(false);

  const headerRef = useRef(null);
  const footerRef = useRef(null);
  const contentRef = useRef(null);
  const middleRef = useRef(null);
  const rightRef = useRef(null);
  useEffect(() => {
    if (open) {
      requestAnimationFrame(() => {
        if (headerRef.current && footerRef.current && contentRef.current) {
          const headerHeight = headerRef.current.offsetHeight;
          const footerHeight = footerRef.current.offsetHeight;
          const cardHeight = contentRef.current.offsetHeight;
          setPageContentHeight(cardHeight - headerHeight - footerHeight);
        }
      });
    }
  }, [open]);

  useEffect(() => {
    getAddOns();
  }, [registeredProducts]);
  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  const save = () => {
    handleAddModifier(selectedAddOns);
    handleClose();
  };

  const getAddOns = () => {
    if (!selectedCategory) return [];
    return selectedCategory.add_ons;
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (!active || !over) return;
    if (active?.id !== over?.id) {
      setSelectedAddOns((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredAddOns = searchQuery
    ? registeredProducts.flatMap((category) =>
        category.add_ons.filter((addOn) =>
          addOn.name.toLowerCase().includes(searchQuery.toLowerCase())
        )
      )
    : getAddOns();

  return (
    <Modal
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      open={open}
      onClose={handleClose}
    >
      <Box
        display="flex"
        flexDirection="column"
        bgcolor="#25292d"
        color="#fff"
        width="95%"
        height="90%"
        maxHeight="90vh"
        margin="auto"
        borderRadius={1}
        overflow="auto"
      >
        <Card className="w-full h-full bg-grayDark text-white" ref={contentRef}>
          <CardContent className="flex flex-col h-full p-0">
            <Box
              className="p-4 flex justify-between items-center border-b border-solid border-gray"
              ref={headerRef}
            >
              <h2 className="text-lg font-semibold">All Modifiers</h2>
              <IconButton onClick={handleClose} className="text-white">
                <Close />
              </IconButton>
            </Box>

            <Box
              className="pageContent flex p-4"
              sx={{ height: `${pageContentHeight}px` }}
            >
              <Box>
                <Typography className="text-md font-semibold pb-4">
                  Selected Modifier Group
                </Typography>
                <Box
                  className="min-h-max overflow-auto flex flex-col gap-y-3"
                  sx={{ height: "calc( 100% - 40px )" }}
                >
                  {registeredProducts.map((category) => (
                    <Button
                      key={category.id}
                      sx={{ marginRight: "8px", color: "#fff" }}
                      onClick={() => handleCategoryClick(category)}
                      className={[
                        selectedCategory?.id === category?.id
                          ? "bg-purple"
                          : "bg-grayDarker",
                        "w-48",
                        "min-h-20",
                      ].join(" ")}
                    >
                      {category.name}
                    </Button>
                  ))}
                </Box>
              </Box>

              <div className="w-2/4 px-4 overflow-auto">
                <Box ref={middleRef}>
                  <Typography className="text-md font-semibold pb-4">
                    Search Modifiers
                  </Typography>
                  <TextField
                    placeholder="Filter menu items"
                    fullWidth
                    onChange={handleSearchChange}
                    variant="outlined"
                    className="text-white rounded border border-solid border-gray mb-4"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        color: "#fff",
                        "& fieldset": {
                          border: "none",
                        },
                        "& input": {
                          color: "#fff",
                        },
                        "& ::placeholder": {
                          color: "#a9a9a9",
                        },
                      },
                    }}
                  />
                  <Button
                    onClick={() => setOpenModifierModal(true)}
                    className="bg-purple py-2 px-8 text-customGray"
                  >
                    + Add Item
                  </Button>

                  <div className="flex items-center py-4">
                    <Checkbox
                      sx={{ transform: "scale(0.8)", color: "#c4c4c4" }}
                      className="p-1"
                      checked={
                        searchQuery === "" &&
                        _.differenceBy(getAddOns(), selectedAddOns, "id")
                          .length === 0
                      }
                      disabled={searchQuery !== ""}
                      onChange={() => {
                        if (
                          _.differenceBy(getAddOns(), selectedAddOns, "id")
                            .length === 0
                        ) {
                          setSelectedAddOns((prevSelected) =>
                            _.differenceBy(prevSelected, getAddOns(), "id")
                          );
                        } else {
                          setSelectedAddOns((prevSelected) =>
                            _.unionBy(prevSelected, getAddOns(), "id")
                          );
                        }
                      }}
                    />
                    <Typography className="font-bold text-xs">
                      Select All
                    </Typography>
                  </div>
                </Box>
                <Box
                  className="overflow-y-auto"
                  sx={{
                    height: `calc( 100% - ${middleRef?.current?.offsetHeight}px )`,
                    minWidth: "520px",
                  }}
                >
                  <div className="flex flex-wrap">
                    {filteredAddOns.map((addOn) => (
                      <ModifierItem
                        addOn={addOn}
                        key={addOn.id}
                        name={addOn.name}
                        setSelectedAddOns={setSelectedAddOns}
                        selectedAddOns={selectedAddOns}
                        price={addOn.price > 0 ? `$${addOn.price}` : ""}
                      />
                    ))}
                  </div>
                </Box>
              </div>

              <div className="grow px-4">
                <Box ref={rightRef}>
                  <div className="flex justify-between items-center mb-4">
                    <Typography className="text-md font-semibold">
                      Selected Items
                    </Typography>
                    <IconButton
                      className="text-red normal-case flex gap-1 items-center text-sm"
                      onClick={() => setSelectedAddOns([])}
                    >
                      <DoDisturbAltIcon className="text-base" />
                      <Typography className="text-sm font-semibold">
                        Remove All
                      </Typography>
                    </IconButton>
                  </div>
                  <Typography className="text-xs">
                    You can drag to re-order selected items
                  </Typography>
                </Box>
                <DndContext
                  sensors={sensors}
                  collisionDetection={closestCenter}
                  onDragEnd={handleDragEnd}
                >
                  <SortableContext
                    items={selectedAddOns.map((item) => item.id)}
                    strategy={verticalListSortingStrategy}
                  >
                    <List
                      sx={{
                        height: `calc(100% - ${rightRef?.current?.offsetHeight}px)`,
                      }}
                      className="overflow-auto"
                    >
                      {selectedAddOns.map((addOn) => (
                        <SortableItem
                          key={addOn.id}
                          id={addOn.id}
                          name={addOn.name}
                          price={addOn.price > 0 ? `$${addOn.price}` : ""}
                        />
                      ))}
                    </List>
                  </SortableContext>
                </DndContext>
              </div>
            </Box>

            <Box
              className="flex justify-end mt-auto p-4 border-t border-solid border-gray"
              ref={footerRef}
            >
              <Button className="bg-purple text-white" onClick={() => save()}>
                Save
              </Button>
            </Box>
          </CardContent>
        </Card>
        {openModifierModal && (
          <ModifierModal
            open={openModifierModal}
            setOpenModifierModal={setOpenModifierModal}
            fetchData={fetchData}
            registeredProducts={registeredProducts}
          />
        )}
      </Box>
    </Modal>
  );
};

const ModifierItem = ({
  name,
  price,
  addOn,
  setSelectedAddOns,
  selectedAddOns,
}) => {
  const [checked, setChecked] = useState(
    selectedAddOns.some((item) => item.id === addOn.id)
  );
  useEffect(() => {
    setChecked(selectedAddOns.some((item) => item.id === addOn.id));
  }, [selectedAddOns]);

  const handleCheckboxChange = (addOn) => {
    setChecked(!checked);
    if (!checked) {
      const newAddOn = {
        category_id: addOn.pos_product_category_id,
        id: addOn.id,
        name: addOn.name,
        price: addOn.price,
        quantity: 1,
        status: addOn.status,
        type: addOn.type,
      };
      setSelectedAddOns((prev) => {
        if (prev.find((item) => item.id === addOn.id)) {
          return prev.filter((item) => item.id !== addOn.id);
        } else {
          return [...prev, newAddOn];
        }
      });
    } else {
      setSelectedAddOns((prev) => {
        return prev.filter((item) => item.id !== addOn.id);
      });
    }
  };

  return (
    <div className="flex items-center mb-2 w-1/3">
      <DragIndicator className="text-gray-400" />
      <Box
        className={`h-full flex grow items-center border boder-solid cursor-pointer ${
          checked ? "border-purple" : "border-gray"
        } py-3 px-1 rounded-lg text-sm font-bold`}
        onClick={() => handleCheckboxChange(addOn)}
      >
        <Checkbox
          className="text-blue-700 p-0 rounded"
          sx={{ transform: "scale(0.8)", color: "#c4c4c4" }}
          checked={checked}
        />
        <Box className="flex grow items-center justify-between">
          <Typography className="font-bold text-xs">{name}</Typography>
          {price && (
            <span className="px-1 py-1.5 bg-customGrayDark rounded-lg">
              {price}
            </span>
          )}
        </Box>
      </Box>
    </div>
  );
};

const SortableItem = ({ id, name, price }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <ListItem
      ref={setNodeRef}
      style={style}
      className="flex items-center p-0 mb-2"
    >
      <DragIndicator {...attributes} {...listeners} className="border-0" />
      <Box
        className={`flex grow items-center border boder-solid border-purple py-2 px-1 rounded-lg text-sm font-bold`}
      >
        <Checkbox
          className="text-blue-700 p-0 rounded"
          sx={{ transform: "scale(0.8)", color: "#c4c4c4" }}
          defaultChecked
        />
        <Box className="flex grow items-center justify-between">
          <Typography className="font-bold text-sm">{name}</Typography>
          <span className="p-1">{price}</span>
        </Box>
      </Box>
    </ListItem>
  );
};

export default AllModsModal;
