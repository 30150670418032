import React, { useState } from "react";
import { getTimeStr } from "../../../utils/Utility";

function TimeTable(props) {
  const [selectedTime, setSelectedTime] = useState(null);

  const selectTime = (time) => {
    setSelectedTime(time);
    props.selectedTimeHandler(time);
  };

  return (
    <div className="time-table">
      <div className="time-table-item-container">
        {
          /* <div className='time-table-item'>12:00 PM</div>
              <div className='time-table-item'>12:00 PM</div>
              <div className='time-table-item'>12:00 PM</div>
              <div className='time-table-item'>12:00 PM</div>
              <div className='time-table-item'>12:00 PM</div>
              <div className='time-table-item'>12:00 PM</div>
              <div className='time-table-item'>12:00 PM</div>
              <div className='time-table-item'>12:00 PM</div>
              <div className='time-table-item'>12:00 PM</div> */
          props.timeList.length > 0
            ? props.timeList.map((time) => {
                return (
                  <div
                    key={Math.random()}
                    onClick={() => selectTime(time)}
                    className={
                      selectedTime === time
                        ? "time-table-item time-table-item--selected"
                        : "time-table-item"
                    }
                  >
                    {getTimeStr(time)}
                  </div>
                );
              })
            : ""
        }
      </div>
    </div>
  );
}

export default TimeTable;
