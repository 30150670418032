import React from "react";

//@mui
import { Box, Container } from "@mui/material";
//components
import Booking from "./Booking.tsx";
const FrontOfHouse = () => {
  return (
    <Container maxWidth={false} style={{ padding: 0 }}>
      <Box className="wabi-container neumorphism-container">
        <Booking />
      </Box>
    </Container>
  );
};

export default FrontOfHouse;
