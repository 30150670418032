import React from "react";
import { Modal, Backdrop, Fade, List, ListItem } from "@mui/material";
import exitBtnTableIcon from "../../../images/booking/exit-btn-table-details.png";
import CachedIcon from "@mui/icons-material/Cached";
const styles = {
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    width: "100%",
    height: "100%",
    backdropFilter: "blur(10px)",
    outline: "none",
  },
  listStyles: {
    width: "49%",
    display: "block",
    textAlign: "center",
    borderRadius: "10px",
    background: "#111",
    cursor: "pointer",
  },
};
const sessionTimeList = [1, 1.5, 2, 2.5, 3, 3.5, 4];

const EditSessionTimeModal = ({
  editSessionTimeOpen,
  setEditSessionTimeOpen,
  selectedSessionTimeHandler,
  modalTitle,
}) => {
  return (
    <div>
      <Modal
        style={styles.modal}
        open={editSessionTimeOpen}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={editSessionTimeOpen}>
          <div style={styles.paper}>
            <div className="modal-content-wrapper">
              <div className="modal-content-container">
                <div
                  className="img-wrapper"
                  onClick={() => setEditSessionTimeOpen(false)}
                >
                  <img
                    src={exitBtnTableIcon}
                    width="12"
                    height="12"
                    alt="checker"
                  />
                </div>
                <label
                  style={{
                    fontSize: 16,
                    fontWeight: "bold",
                    color: "white",
                    display: "inline-flex",
                    flexDirection: "row",
                    alignItems: "center",
                    padding: "17px",
                  }}
                >
                  <CachedIcon style={{ fontSize: 26, margin: 10 }} />
                  {modalTitle}
                </label>
                <div className="scrollable-container">
                  <List
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "8px",
                      textAlign: "center",
                    }}
                  >
                    {sessionTimeList.map((sessionTime) => {
                      return (
                        <ListItem
                          sx={styles.listStyles}
                          key={Math.random()}
                          onClick={() =>
                            selectedSessionTimeHandler(sessionTime)
                          }
                        >
                          {sessionTime} Hr
                        </ListItem>
                      );
                    })}
                    <ListItem
                      sx={styles.listStyles}
                      onClick={() => selectedSessionTimeHandler()}
                    >
                      No Limit
                    </ListItem>
                  </List>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};
export default EditSessionTimeModal;
