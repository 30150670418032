import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Modal,
  TextField,
  Button,
} from "@mui/material";
import moment from "moment";
import { ButtonStyled } from "../StyledComponents";
import colors from "../StyledComponents/ColorStyled.jsx";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ScheduleViewModal from "./ScheduleViewModal";
import {
  removeScheduleDateRequest,
  updateScheduleDateRequest,
  getDeletedScheduleRequest,
} from "../../../../actions/rosterActions.js";
import { useDispatch } from "react-redux";
import { set } from "lodash";

const RosterGroupDetails = ({
  selectedGroup,
  schedules,
  setSchedules,
  handleCloseDetails,
  handleOpenCreateScheduleModal,
  employeeGroupScheduleId,
  copiedShift,
  setCopiedShift,
  copiedShiftCalendar,
  setCopiedShiftCalendar,
}) => {
  const dispatch = useDispatch();

  const [openScheduleModal, setOpenScheduleModal] = useState(false);
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [selectedEmployeeScheduleDateId, setSelectedEmployeeScheduleDateId] =
    useState(null);

  const [openEditModal, setOpenEditModal] = useState(false);
  const [newScheduleName, setNewScheduleName] = useState("");
  const [deletedSchedules, setDeletedSchedules] = useState([]);
  const [showDeleted, setShowDeleted] = useState(false);

  const handleCopyShift = (shift) => {
    setCopiedShift(shift);
    setCopiedShiftCalendar(shift);
  };

  const handleEditSchedule = (schedule) => {
    setSelectedSchedule(schedule);
    setNewScheduleName(schedule.name);
    setOpenEditModal(true);
  };

  const handleRowClick = (schedule) => {
    setSelectedSchedule(schedule);
    setOpenScheduleModal(true);
  };

  const handleCloseScheduleModal = () => {
    setOpenScheduleModal(false);
    setSelectedSchedule(null);
  };
  const handleSaveScheduleName = () => {
    const payload = {
      employee_group_schedule_id: employeeGroupScheduleId,
      id: selectedSchedule.id,
      name: newScheduleName,
    };
    dispatch(
      updateScheduleDateRequest(payload, (error, data) => {
        if (error) {
          console.error("Failed to update schedule:", error);
        } else {
          const updatedSchedules = schedules.map((schedule) =>
            schedule.id === selectedSchedule.id
              ? { ...schedule, name: newScheduleName }
              : schedule
          );
          setSchedules(updatedSchedules);
          setOpenEditModal(false);
        }
      })
    );
  };
  const handleDeleteScheduleFromTable = (id) => {
    dispatch(
      removeScheduleDateRequest({ id }, (error, data) => {
        if (error) {
          console.error("Failed to remove schedule:", error);
        } else {
          const updatedSchedules = schedules.filter(
            (schedule) => schedule.id !== id
          );
          setSchedules(updatedSchedules);
        }
      })
    );
  };

  const handleShowDeleteSchedule = () => {
    setShowDeleted(true);

    const params = {
      roster_id: selectedGroup.id,
    };
    dispatch(
      getDeletedScheduleRequest(params, (error, data) => {
        if (error) {
          console.error("Failed to get deleted schedules:", error);
        } else {
          setDeletedSchedules(data);
          console.log("deleted schedules", data);
        }
      })
    );
  };
  const handleShowCurrentSchedule = () => {
    setShowDeleted(false);
  };

  return (
    <>
      <Box
        sx={{
          position: "fixed",
          top: 0,
          right: 0,
          width: "80%",
          height: "100%",
          color: colors.white,
          bgcolor: colors.secondary,
          p: 4,
          boxShadow: 24,
          zIndex: 99,
        }}
      >
        <Grid display={"flex"} justifyContent={"space-between"} mb={2}>
          <Box>
            <ButtonStyled
              variant="contained"
              onClick={handleCloseDetails}
              sx={{ mb: 2, mr: 2 }}
              className="bg-primary neo-outer-glow"
            >
              BACK TO GROUP
            </ButtonStyled>
            {showDeleted ? (
              <ButtonStyled
                variant="contained"
                onClick={handleShowCurrentSchedule}
                sx={{ mb: 2 }}
                className="bg-primary neo-outer-glow"
              >
                CURRENT SCHEDULE
              </ButtonStyled>
            ) : (
              <ButtonStyled
                variant="contained"
                onClick={handleShowDeleteSchedule}
                sx={{ mb: 2 }}
                className="bg-primary neo-outer-glow"
              >
                DELETED SCHEDULE
              </ButtonStyled>
            )}
          </Box>
          <Box>
            <ButtonStyled
              variant="contained"
              sx={{ mb: 2 }}
              className="bg-primary neo-outer-glow"
              onClick={handleOpenCreateScheduleModal}
            >
              CREATE SCHEDULE
            </ButtonStyled>
          </Box>
        </Grid>
        <Grid
          className="bg-primary"
          p={3}
          borderRadius={2}
          height={"92%"}
          sx={{ overflow: "hidden" }}
        >
          <Typography variant="h4" mb={2} color="white">
            {selectedGroup?.group_name}
          </Typography>
          <TableContainer
            sx={{
              maxHeight: "94%",
              overflow: "auto",
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ textAlign: "center", color: colors.white }}>
                    Schedule Name
                  </TableCell>
                  <TableCell sx={{ textAlign: "center", color: colors.white }}>
                    Date Range
                  </TableCell>
                  <TableCell sx={{ textAlign: "center", color: colors.white }}>
                    No. of Employees
                  </TableCell>
                  <TableCell
                    sx={{ textAlign: "center", color: colors.white }}
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(showDeleted ? deletedSchedules : schedules).map(
                  (schedule) => {
                    const formattedStartDate = moment(
                      schedule.start_date
                    ).format("ddd, MMM DD");
                    const formattedEndDate = moment(schedule.end_date).format(
                      "ddd, MMM DD"
                    );
                    const formattedDateRange = `${formattedStartDate} - ${formattedEndDate}`;

                    return (
                      <TableRow
                        key={schedule.id}
                        onClick={() => handleRowClick(schedule)}
                        sx={{ cursor: "pointer" }}
                      >
                        <TableCell
                          sx={{ textAlign: "center", color: colors.white }}
                        >
                          {schedule.name}
                        </TableCell>
                        <TableCell
                          sx={{ textAlign: "center", color: colors.white }}
                        >
                          {formattedDateRange}
                        </TableCell>
                        <TableCell
                          sx={{ textAlign: "center", color: colors.white }}
                        >
                          {schedule.employees}
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              handleEditSchedule(schedule);
                            }}
                            sx={{ color: colors.white }}
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDeleteScheduleFromTable(schedule.id);
                            }}
                            sx={{ color: colors.white }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  }
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Box>

      {selectedSchedule && (
        <ScheduleViewModal
          open={openScheduleModal}
          handleClose={handleCloseScheduleModal}
          schedule={selectedSchedule}
          schedules={schedules}
          setSchedules={setSchedules}
          employeeScheduleDateId={selectedEmployeeScheduleDateId}
          copiedShift={copiedShift}
          handleCopyShift={handleCopyShift}
          setCopiedShift={setCopiedShift}
          copiedShiftCalendar={copiedShiftCalendar}
          setCopiedShiftCalendar={setCopiedShiftCalendar}
        />
      )}

      {/* Edit Schedule Modal */}
      <Modal
        open={openEditModal}
        onClose={() => setOpenEditModal(false)}
        aria-labelledby="edit-schedule-modal"
        aria-describedby="edit-schedule-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: colors.tertiary,
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography
            variant="h6"
            mb={2}
            color={colors.white}
            fontWeight="bold"
          >
            Edit Schedule
          </Typography>
          <TextField
            fullWidth
            label="Schedule Name"
            value={newScheduleName}
            onChange={(e) => setNewScheduleName(e.target.value)}
            sx={{
              mb: 3,
              input: {
                color: "white",
                backgroundColor: colors.primary,
                borderRadius: "20px",
                padding: "10px 20px",
                textAlign: "right",
              },
              "& .MuiInputBase-root": {
                backgroundColor: colors.primary,
                borderRadius: "20px",
              },
              "& label": {
                color: "white",
                top: "-5px",
                left: "20px",
              },
              "& .MuiInputLabel-shrink": {
                top: "0",
                left: "10px",
              },
            }}
            InputLabelProps={{
              style: {
                color: "white",
                left: "20px",
                top: "50%",
              },
              shrink: true,
            }}
          />

          <Button
            variant="contained"
            onClick={handleSaveScheduleName}
            sx={{ bgcolor: colors.primary }}
            fullWidth
          >
            Save
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default RosterGroupDetails;
