import React from "react";
import IconButton from "@mui/material/IconButton";
import BusinessIcon from "@mui/icons-material/Business";

const ProfileIcon = ({ profilePicUrl, onClick }) => {
  const s3Bucket = "https://wabi-staging.s3.ap-southeast-2.amazonaws.com/";
  const imgSrc = profilePicUrl ? `${s3Bucket}${profilePicUrl}` : null;

  return (
    <>
      <IconButton
        className="profile-icon background-color neo-outer-glow"
        color="inherit"
        onClick={onClick}
      >
        {imgSrc ? (
          <img src={imgSrc} alt="Business Profile" className="profile-image" />
        ) : (
          <BusinessIcon />
        )}
      </IconButton>
    </>
  );
};

export default ProfileIcon;
