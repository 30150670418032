import axios from "axios";

//types
import { AuthParams } from "./types";

export const auth = async (params: AuthParams) => {
  const data = { ...params, device_name: "web" };
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/auth`,
      data
    );
    return {
      data: response.data.data,
      status: response.status,
      error: false,
      message: "Success",
    };
  } catch (error) {
    return {
      data: null,
      status: error.response ? error.response.status : null,
      error: true,
      message: error.response ? error.response.data.message : error.message,
    };
  }
};
