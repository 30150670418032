import React, { useState } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import moment from "moment";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { getBookingStatusColor } from "../../utils/Utility";

function TimeGraph({ shift, bookingsOnTable }) {
  const [expanded, setExpanded] = useState(false);
  const divideInto15mins = 900;
  const divideInto60mins = 3600;
  const end = shift
    ? moment(new Date()).startOf("day").second(shift.end_time)
    : "";
  const start = shift
    ? moment(new Date()).startOf("day").second(shift.start_time)
    : "";
  const diff = shift
    ? Math.floor((shift.end_time - shift.start_time) / divideInto60mins) + 1
    : 0;
  let time = shift ? start.format("h") : "";

  const returnValue = [];
  for (let i = 0; i <= diff; i++) {
    if (i === 0) {
      returnValue.push(
        <Box key={i} sx={expanded ? styles.graphVertical : styles.graph}>
          <Box sx={expanded ? styles.expandedLine1 : styles.line1} />
          <Box sx={expanded ? styles.expandedLine : styles.line} />
          <Box sx={expanded ? styles.expandedLine : styles.line} />
          <Box sx={expanded ? styles.expandedLine : styles.line} />
          <Typography variant="body2" sx={styles.timeLabel}>
            {time}
          </Typography>
        </Box>
      );
    } else if (i === diff) {
      time = start ? start.add(1, "h").format("h") : "";
      let endTime = end ? end.add(1, "h").format("h") : "";
      if (time === endTime) {
        endTime = end ? end.add(1, "h").format("h") : "";
      }
      returnValue.push(
        <Box key={i} sx={expanded ? styles.graphVertical : styles.graph}>
          <Box sx={expanded ? styles.expandedLine1 : styles.line1} />
          <Box sx={expanded ? styles.expandedLine : styles.line} />
          <Box sx={expanded ? styles.expandedLine : styles.line} />
          <Box sx={expanded ? styles.expandedLine : styles.line} />
          <Box
            sx={{
              ...styles.line,
              ...(expanded
                ? { width: "100%", height: "2px", backgroundColor: "white" }
                : {
                    background:
                      "linear-gradient(to right, #ffffff 90%, transparent 10%)",
                  }),
            }}
          />
          <Typography variant="body2" sx={styles.timeLabel}>
            {time}
          </Typography>
          <Typography variant="body2" sx={styles.endTimeLabel}>
            {endTime}
          </Typography>
        </Box>
      );
    } else {
      time = start ? start.add(1, "h").format("h") : "";
      returnValue.push(
        <Box key={i} sx={expanded ? styles.graphVertical : styles.graph}>
          <Box sx={expanded ? styles.expandedLine1 : styles.line1} />
          <Box sx={expanded ? styles.expandedLine : styles.line} />
          <Box sx={expanded ? styles.expandedLine : styles.line} />
          <Box sx={expanded ? styles.expandedLine : styles.line} />
          <Typography
            variant="body2"
            sx={expanded ? styles.expandedTimeLabel : styles.timeLabel}
          >
            {time}
          </Typography>
        </Box>
      );
    }
  }

  const getBookingPosition = (booking) => {
    if (diff > 9) {
      return ((booking.start_time - shift.start_time) / divideInto15mins) * 4.8;
    } else {
      return (
        ((booking.start_time - shift.start_time) / divideInto15mins + 2) * 9
      );
    }
  };

  const getBookingWidth = (booking) => {
    return `calc( 100% / ${diff > 9 ? 4.8 : 9} * ${
      (booking.end_time - booking.start_time) / divideInto60mins
    })`;
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const gap = expanded ? "12px" : "0px";
  return (
    <Box sx={styles.container}>
      <Box>
        <Box
          id="graph-wrapper"
          sx={{
            ...styles.graphWrapper,
            flexDirection: expanded ? "column" : "row",
            width: expanded ? "100%" : "100%",
            gap: gap,
          }}
        >
          {returnValue}
        </Box>

        {bookingsOnTable.length > 0 &&
          bookingsOnTable.map((booking, key) => (
            <Box
              key={key}
              sx={{
                ...styles.bookingIndicator,
                background: getBookingStatusColor(
                  booking.status,
                  booking.start_date
                ),
                left: expanded ? 0 : getBookingPosition(booking),
                top: expanded ? getBookingPosition(booking) : 0,
                width: expanded ? "100%" : getBookingWidth(booking),
                height: expanded ? getBookingWidth(booking) : "21px",
              }}
              className="booking-on-table"
            />
          ))}
      </Box>

      <Box sx={styles.imgContainer}>
        <IconButton onClick={handleExpandClick}>
          {expanded ? (
            <KeyboardArrowUpIcon style={{ color: "#fff", fontSize: "34px" }} />
          ) : (
            <KeyboardArrowDownIcon
              style={{ color: "#fff", fontSize: "34px" }}
            />
          )}
        </IconButton>
      </Box>
    </Box>
  );
}

const styles = {
  container: {
    position: "relative",
    height: "85%",
    width: "100%",
  },
  graphWrapper: {
    position: "relative",
    display: "inline-flex",
    justifyContent: "center",
    transition: "all 0.3s ease",
  },
  graph: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  graphVertical: {
    position: "relative",
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: "12px",
  },
  spacer: {
    width: "1px",
    height: "40px",
    borderLeft: "4px solid white",
  },
  line: {
    width: "1px",
    height: "40px",
    marginRight: "7px",
    backgroundColor: "#fff",
  },
  line1: {
    width: "2px",
    height: "40px",
    marginRight: "7px",
    backgroundColor: "#fff",
  },
  expandedLine: {
    width: "100%",
    height: "1px",
    backgroundColor: "white",
  },
  expandedLine1: {
    width: "100%",
    height: "2px",
    backgroundColor: "white",
  },
  expandedTimeLabel: {
    position: "absolute",
    fontSize: "12px",
    left: "-15px",
    bottom: "-21px",
    width: "13px",
    textAlign: "right",
  },
  timeLabel: {
    position: "absolute",
    fontSize: "12px",
    left: "-9px",
    bottom: "-21px",
    width: "13px",
    textAlign: "right",
  },
  endTimeLabel: {
    fontSize: "12px",
    position: "absolute",
    right: "0px",
    bottom: "-21px",
  },
  imgContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "12px",
  },
  bookingIndicator: {
    position: "absolute",
    left: 0,
    top: 0,
    height: "21px",
  },
};

export default TimeGraph;
