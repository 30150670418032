// actions/experienceActions.js
export const getExperienceListRequest = (callback) => ({
  type: "GET_EXPERIENCE_LIST_REQUEST",
  callback,
});
export const getExperienceAssignRequest = (param, callback) => ({
  type: "GET_EXPERIENCE_ASSIGN_REQUEST",
  param,
  callback,
});
