import { styled } from "@mui/material/styles";
import { Avatar } from "@mui/material";

const AvatarStyled = styled(Avatar)(({ theme }) => ({
  width: 80,
  height: 80,
  fontSize: "32px",
  fontWeight: "bold",
}));

export default AvatarStyled;
