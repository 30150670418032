const initState = {
	isAuth: false,
	isSubscribed: false,
	onTrial: false,
	token: '',
	user: {
		userId: '',
		email: '',
		stripeId: '',
		planId: '',
		// paymentMethod: '',
		// subscription: [],
	},
	billingPayment: {
		name: '',
		email: '',
		address: '',
		city: '',
		state: '',
		zip: '',
		country: '',
	},
	shippingAddress: {},
	loading: true,
};

const userReducers = (state = initState, action) => {
	switch (action.type) {
		// case 'SAVE_USER':
		// 	return {
		// 		...state,
		// 		user: {
		// 			email: action.data,
		// 			customerId: '',
		// 			password: '',
		// 			paymentMethod: '',
		// 		},
		// 		billingPayment: {},
		// 		shippingAddress: {},
		// 		isAuth: true,
		// 	};
		// case 'SIGNUP_SUCCESS':
		// 	localStorage.setItem('code-black-token', action.data.access_token);
		// 	return {
		// 		...state,
		// 		token: action.data.access_token,
		// 		isAuth: true,
		// 	};
		// case 'LOGIN_SUCCESS':
		// 	localStorage.setItem('code-black-token', action.data.access_token);
		// 	return {
		// 		...state,
		// 		token: action.data.access_token,
		// 		isAuth: true,
		// 	};
		// case 'LOGOUT_SUCCESS':
		// 	localStorage.removeItem('code-black-token');
		// 	return {
		// 		...state,
		// 		token: '',
		// 		isAuth: false,
		// 	};
		// case 'SAVE_BILLING':
		// 	return {
		// 		...state,
		// 		user: {
		// 			email: state.user.email,
		// 			customerId: '',
		// 			password: '',
		// 			paymentMethod: '',
		// 		},
		// 		billingPayment: action.data.billingPayment,
		// 		shippingAddress: action.data.shippingAddress,
		// 	};
		// case 'GET_PROFILE_SUCCESS':
		// 	return {
		// 		...state,
		// 		user: {
		// 			email: action.data.email,
		// 			subscription: action.data.subscription,
		// 			stripeId: action.data.stripe_id,
		// 			paymentMethod: '',
		// 		},
		// 	};
		case 'SET_USER':
			return {
				...state,
				user: action.payload.user,
				isSubscribed: action.payload.isSubscribed,
				onTrial: action.payload.onTrial,
				token: action.payload.token,
				loading: false,
				isAuth: true,
			};
		case 'SET_IS_SUBSCRIBED':
			return {
				...state,
				isSubscribed: true,
				onTrial: true,
			};
		case 'UPDATE_USER':
			return {
				...state,
				user: action.payload,
			};
		case 'SET_LOADING':
			return {
				...state,
				loading: false,
			};
		case 'LOGOUT':
			return initState;
		default:
			return state;
	}
};

export default userReducers;
