// actions/timeslotActions.js
export const fetchTimeslotsRequest = (bookingDate, callback) => ({
  type: "FETCH_TIMESLOTS_REQUEST",
  bookingDate,
  callback,
});

export const fetchTimeslotsSuccess = (timeslots) => ({
  type: "FETCH_TIMESLOTS_SUCCESS",
  timeslots,
});

export const fetchTimeslotsFailure = (error) => ({
  type: "FETCH_TIMESLOTS_FAILURE",
  error,
});
