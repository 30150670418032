import React from "react";
import { List, ListItem } from "@mui/material";
import {
  getTimeOnly,
  getNameStatusColor,
  getBookingStatusColor,
  getFullname,
  getGuestPhoneNum,
  getTableSize,
  getFloorName,
  sortBookingBySection,
  hasConflictBooking,
  getAllTablesOnFloors,
} from "../utils/Utility";
import { BOOKINGSTATUS, BOOKINGTYPE } from "../constants/enum";
import { NumericFormat } from "react-number-format";

import sectionTableIcon from "../images/booking/section-table-icon.png";
import sectionPartySizeIcon from "../images/booking/section-party-size-icon.png";
import billedIcon from "../images/booking/billed-icon.png";
import noteIcon from "../images/booking/note-icon.png";
import experienceIcon from "../images/booking/create-booking/booking-option-experience-icon.png";
import widgetIcon from "../images/booking/widget-icon.png";
import conflictIcon from "../images/booking/conflict-icon.png";

const iconStyle = {
  display: "flex",
  alignItems: "center",
  gap: 8,
};
const IconItem = ({ width, totalPartySize, tableLength }) => (
  <div
    style={{
      width: width,
      display: "flex",
      justifyContent: "flex-end",
      gap: 16,
    }}
  >
    <div style={iconStyle} className="partySizeIcon">
      <img
        src={sectionPartySizeIcon}
        width="24"
        height="24"
        className="d-inline"
        alt="checker"
      />
      <span style={{ fontSize: 18 }}>{totalPartySize}</span>
    </div>
    <div style={iconStyle} className="tableIcon">
      <img
        src={sectionTableIcon}
        width="24"
        height="24"
        className="d-inline"
        alt="checker"
      />
      <span style={{ fontSize: 18 }}>{tableLength}</span>
    </div>
  </div>
);

const BookingItem = ({
  item,
  selectedBooking,
  handleBookingSelect,
  floorList,
  bookingsList,
}) => (
  <ListItem
    onClick={() => handleBookingSelect(item)}
    className={
      selectedBooking !== null
        ? selectedBooking.id === item.id
          ? "booking-list-item booking-list-item--selected"
          : "booking-list-item"
        : "booking-list-item"
    }
    key={item.id}
    alignItems="flex-start"
  >
    <div className="booking-list-item-container">
      <div
        className={
          item.status === 8
            ? "booking-list-item-time booking-list-item-time--cancelled"
            : "booking-list-item-time"
        }
      >
        <span>{getTimeOnly(item.start_date)}</span>
      </div>
      <div className="booking-list-item-guest-name">
        <div>
          <span style={{ color: getNameStatusColor(item.status) }}>
            {getFullname(item.guest)}
          </span>
        </div>
        {getGuestPhoneNum(item.guest) !== "" && (
          <div className="booking-list-item-guest-bottom">
            <NumericFormat
              value={getGuestPhoneNum(item.guest)}
              displayType={"text"}
              format="#### #### ####"
            />
          </div>
        )}
        <div className="booking-list-item-guest-bottom">
          {item.status === BOOKINGSTATUS.partiallySeated && (
            <span style={{ color: getBookingStatusColor(item.status) }}>
              ( {item.partial_seated} ) &nbsp;
            </span>
          )}
          <span>
            {getTableSize(item) +
              " / " +
              getFloorName(item.floor_id, floorList)}
          </span>
        </div>
      </div>
      <div className="booking-list-item-table">
        <div>
          <span
            style={{
              color: getBookingStatusColor(item.status, item.start_date),
              border:
                "1px solid " +
                getBookingStatusColor(item.status, item.start_date),
            }}
          >
            {item.table.length > 1
              ? item.table[0].name + "+"
              : item.table.length > 0
              ? item.table[0].name
              : ""}
          </span>
        </div>
        <div className="booking-list-item-icons">
          {hasConflictBooking(
            null,
            getAllTablesOnFloors(floorList),
            bookingsList
          ) && (
            <img
              src={conflictIcon}
              width="18"
              height="18"
              className="d-inline"
              alt="checker"
            />
          )}
          {item.reservation_note !== null && (
            <img
              src={noteIcon}
              width="18"
              height="18"
              className="d-inline"
              alt="checker"
            />
          )}
          {item.status === BOOKINGSTATUS.billed && (
            <img
              src={billedIcon}
              width="18"
              height="18"
              className="d-inline"
              alt="checker"
            />
          )}
          {item.type === BOOKINGTYPE.widget && (
            <img
              src={widgetIcon}
              width="18"
              height="18"
              className="d-inline"
              alt="checker"
            />
          )}
          {item.type === BOOKINGTYPE.experience && (
            <img
              src={experienceIcon}
              width="18"
              height="18"
              className="d-inline"
              alt="checker"
            />
          )}
        </div>
      </div>
    </div>
  </ListItem>
);

function BookingList(props) {
  const handleBookingSelect = (booking) => {
    if (props.selectedBooking === null) {
      props.selectedBookingHandler(booking);
    } else {
      if (props.selectedBooking.id === booking.id) {
        props.selectedBookingHandler(null);
      } else {
        props.selectedBookingHandler(booking);
      }
    }
  };

  const renderBookingItems = (bookingList) => (
    <List style={{ paddingTop: 0 }}>
      <div className="booking-list-item-section">
        <IconItem
          width="100%"
          totalPartySize={
            bookingList.length &&
            bookingList.reduce((total, item) => total + item.party_size, 0)
          }
          tableLength={
            bookingList.length &&
            bookingList.reduce((total, item) => total + item.table.length, 0)
          }
        />
      </div>
      {bookingList.length > 0 &&
        bookingList.map((item) => (
          <BookingItem
            key={item.id}
            item={item}
            selectedBooking={props.selectedBooking}
            handleBookingSelect={handleBookingSelect}
            floorList={props.floorList}
            bookingsList={props.filteredBookingList}
          />
        ))}
    </List>
  );

  return (
    <div className="booking-list">
      {parseInt(props.selectedOption) !== 3 ? (
        renderBookingItems(props.filteredBookingList)
      ) : (
        <List style={{ paddingTop: 0 }}>
          {sortBookingBySection(props.bookingsList, props.keyword).length > 0 &&
            sortBookingBySection(props.bookingsList, props.keyword).map(
              (section) => (
                <div key={section.sectionName}>
                  <div className="booking-list-item-section">
                    <div style={{ width: "50%" }}>
                      <div>
                        <span>{section.sectionName}</span>
                      </div>
                      <div
                        style={{ fontSize: 10, marginTop: 4, marginLeft: 1 }}
                      >
                        <span>By schedule time</span>
                      </div>
                    </div>
                    <IconItem
                      width="50%"
                      totalPartySize={section.totalPartySize}
                      tableLength={section.totalTable}
                    />
                  </div>
                  {section.bookings.map((item) => (
                    <BookingItem
                      key={item.id}
                      item={item}
                      selectedBooking={props.selectedBooking}
                      handleBookingSelect={handleBookingSelect}
                      floorList={props.floorList}
                      bookingsList={props.bookingsList}
                    />
                  ))}
                </div>
              )
            )}
        </List>
      )}
    </div>
  );
}

export default BookingList;
