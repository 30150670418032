import React, { useEffect, useState, useRef } from "react";
import { Modal, Grid, Box, Button } from "@mui/material";
import { useDispatch } from "react-redux";
import WeekView from "./scheduleView/WeekView";
import DayView from "./scheduleView/DayView";
import TeamView from "./scheduleView/TeamView";
import FloorView from "./scheduleView/FloorView";
import moment from "moment";
import {
  Header,
  StyledModalBox,
  HeaderTypography,
  ScheduleDetailTypography,
  ScheduleDateRange,
  EmployeesCarouselWrapper,
  EmployeesSortContainer,
  EmployeeCapsule,
  EmployeeIcon,
  ArrowButton,
  FilterSection,
  FilterAll,
  FilterLabel,
  FilterOption,
  ViewSwitchTabsContainer,
  ViewSwitchTabs,
  ViewSwitchButton,
  RightActionButtons,
  TabSeparator,
  FooterActionButtons,
  SelectRoleBox,
} from "../StyledComponents/ScheduleViewModalStyles";
import {
  getEmployeeScheduleRequest,
  createEmployeeScheduleRequest,
} from "../../../../actions/rosterActions";
import {
  getEmployeesRequest,
  getEmployeeRolesRequest,
} from "../../../../actions/employeeActions";
import SubmitScheduleModal from "./scheduleView/SubmitScheduleModal";
import RequestAvailabilityModal from "./scheduleView/RequestAvailabilityModal";

const ScheduleViewModal = ({
  open,
  handleClose,
  schedule,
  schedules,
  setSchedules,
  selectedShift,
  copiedShift,
  setCopiedShift,
  copiedShiftCalendar,
  setCopiedShiftCalendar,
}) => {
  const [employeeTable, setEmployeeTable] = useState({});
  const dispatch = useDispatch();
  const [employees, setEmployees] = useState([]);
  const [employeesSchedule, setEmployeesSchedule] = useState([]);
  const [activeView, setActiveView] = useState("Week View");
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const carouselRef = useRef(null);
  const [updatedShifts, setUpdatedShifts] = useState([]);
  const [employeeShifts, setEmployeeShifts] = useState([]);
  const [selectModalOpen, setSelectModalOpen] = useState(false);
  const [requestAvailabilityModalOpen, setRequestAvailabilityModalOpen] =
    useState(false);
  const [totalHoursAllEmployees, setTotalHoursAllEmployees] = useState(0);
  const [totalCostsAllEmployees, setTotalCostsAllEmployees] = useState(0);
  const [roles, setRoles] = useState([]);
  const [filteredEmployees, setFilteredEmployees] = useState(employees);
  const [showSelectBox, setShowSelectBox] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const [displayedEmployees, setDisplayedEmployees] = useState([]);

  const formatHoursAndMinutes = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.round((totalSeconds % 3600) / 60);
    return `${hours}h ${minutes}m`;
  };

  const handleOpenSubmitShiftModal = () => {
    setSelectModalOpen(true);
  };

  const handleOpenRequestShiftModal = () => {
    setRequestAvailabilityModalOpen(true);
  };

  const handleCloseSelectModal = () => {
    setSelectModalOpen(false);
  };

  const handleCloseRequestAvailabilityModal = () => {
    setRequestAvailabilityModalOpen(false);
  };

  const handleEmployeeSelect = (employees) => {
    setSelectedEmployees(employees);
  };

  const handleUpdateShift = (newShift) => {
    setUpdatedShifts((prevShifts) => [
      ...prevShifts.filter((shift) => shift.id !== newShift.id),
      newShift,
    ]);
  };

  const getEmployeeColor = (employee) => {
    return employee.color;
  };

  const scrollLeft = () => {
    carouselRef.current.scrollLeft -= 300;
  };

  const scrollRight = () => {
    carouselRef.current.scrollLeft += 300;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // getEmployeesRequest
        dispatch(
          getEmployeesRequest((err, employeesData) => {
            if (err) {
              console.log("Error", err);
            } else {
              setEmployees(employeesData);
              setFilteredEmployees(employeesData);
            }
          })
        );

        // getEmployeeScheduleRequest
        dispatch(
          getEmployeeScheduleRequest(
            { employee_schedule_date_id: schedule.id },
            (err, scheduleData) => {
              if (err) {
                console.log("Error", err);
              } else {
                setEmployeesSchedule(scheduleData);
              }
            }
          )
        );
        // getEmployeeRolesRequest
        dispatch(
          getEmployeeRolesRequest((error, fetchedRoles) => {
            if (!error) {
              setRoles(fetchedRoles);
            } else {
              console.error("error message:", error);
            }
          })
        );
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchData();
  }, [dispatch, schedule.id]);

  useEffect(() => {
    if (!selectedRole) {
      const filtered = employees.filter(
        (employee) =>
          !(
            Array.isArray(displayedEmployees) &&
            displayedEmployees.some(
              (displayedEmployee) => displayedEmployee.id === employee.id
            )
          )
      );
      setFilteredEmployees(filtered);
    }
  }, [employees, displayedEmployees, selectedRole]);

  const handleEmployeeClick = (employee) => {
    setDisplayedEmployees((prev) => [...prev, employee]);

    let newFilteredEmployees = filteredEmployees.filter(
      (e) => e.id !== employee.id
    );

    if (selectedRole) {
      newFilteredEmployees = newFilteredEmployees.filter(
        (e) => e.employment_role_id === selectedRole
      );
    }

    setFilteredEmployees(newFilteredEmployees);

    setSelectedEmployees((prev) => [...prev, employee]);

    setEmployeesSchedule((prevSchedule) => {
      const isEmployeeInSchedule = prevSchedule.employee_shifts.some(
        (emp) => emp.id === employee.id
      );

      if (!isEmployeeInSchedule) {
        const newEmployeeWithShifts = {
          ...employee,
          shifts: [],
          pay_rates: employee.pay_rates || [],
        };

        return {
          ...prevSchedule,
          employee_shifts: [
            ...prevSchedule.employee_shifts,
            newEmployeeWithShifts,
          ],
        };
      }

      return prevSchedule;
    });
  };

  const handleAllEmployeeClick = () => {
    setShowSelectBox((prev) => !prev);
  };
  const resetToAllEmployees = () => {
    setSelectedRole(null);
    setFilteredEmployees(employees);
  };

  const handleRoleSelect = (roleId) => {
    setSelectedRole(roleId);
    setShowSelectBox(false);

    const filtered = employees.filter(
      (employee) =>
        employee.employment_role_id === roleId &&
        !displayedEmployees.some(
          (displayedEmployee) => displayedEmployee.id === employee.id
        )
    );
    setFilteredEmployees(filtered);
  };

  const handleTotalHoursChange = (totalHours) => {
    setTotalHoursAllEmployees(totalHours);
  };

  const handleTotalCostsChange = (totalCosts) => {
    setTotalCostsAllEmployees(totalCosts);
  };

  const handleCopyShift = () => {
    if (employeesSchedule && employeesSchedule.employee_shifts) {
      setCopiedShift(employeesSchedule.employee_shifts);
      setCopiedShiftCalendar(employeesSchedule.calendar);
    }
  };
  const convertEmployeeShiftsToArray = (employeeShifts, employees) => {
    return Object.entries(employeeShifts).map(([employeeId, shifts]) => {
      const employee =
        employees.find((emp) => emp.id === parseInt(employeeId, 10)) || {};

      return {
        id: parseInt(employeeId, 10),
        shifts: shifts,
        first_name: employee.first_name || "",
        last_name: employee.last_name || "",
        color: employee.color || "",
        role: employee.role || "",
        mobile_no: employee.mobile_no || "",
        myob_uid: employee.myob_uid || null,
        pin: employee.pin || "",
        quick_pin: employee.quick_pin || "",
        reckon_employee_uid: employee.reckon_employee_uid || null,
        reckon_rha_employee_list_id:
          employee.reckon_rha_employee_list_id || null,
        branch_id: employee.branch_id || null,
        created_at: employee.created_at || "",
        deleted_at: employee.deleted_at || null,
      };
    });
  };

  const unifiedFormattedSchedule = (formattedSchedule, employees) => {
    return {
      ...formattedSchedule,
      employee_shifts: convertEmployeeShiftsToArray(
        formattedSchedule.employee_shifts,
        employees
      ),
    };
  };

  const handlePasteShift = () => {
    if (!employees || employees.length === 0) {
      console.error("Employee data is not yet available");
      return;
    }

    if (!copiedShift || !Array.isArray(copiedShift)) {
      console.error("No copied shift data available");
      return;
    }

    const pasteCalendarStartDate = moment(schedule.start_date);
    const copyCalendarStartDate = moment(copiedShiftCalendar.start_date);

    const updatedShifts = copiedShift.reduce((acc, shift) => {
      const shiftShifts = Array.isArray(shift.shifts) ? shift.shifts : [];

      shiftShifts.forEach((s, shiftIndex) => {
        const shiftDate = moment(s.start_date);
        const dayDifference = shiftDate.diff(copyCalendarStartDate, "days");
        const newStartDate = pasteCalendarStartDate
          .clone()
          .add(dayDifference, "days");

        const newShift = {
          ...s,
          id: `new-${shiftIndex}${Date.now()}`,
          employee_schedule_date_id: schedule.id,
          start_date: newStartDate.format("YYYY-MM-DD"),
          end_date: newStartDate.format("YYYY-MM-DD"),
          date: newStartDate.format("YYYY-MM-DD"),
        };

        if (!acc[newShift.employee_id]) {
          acc[newShift.employee_id] = [];
        }

        acc[newShift.employee_id].push(newShift);
      });

      return acc;
    }, {});

    const unifiedSchedule = unifiedFormattedSchedule(
      {
        calendar: copiedShiftCalendar,
        employee_shifts: updatedShifts,
      },
      employees
    );

    setEmployeesSchedule(unifiedSchedule);
    setEmployeeTable(updatedShifts);
    setEmployeeShifts(updatedShifts);
  };

  const handleSave = () => {
    const formattedEmployeeData = Object.values(employeeShifts).flatMap(
      (shifts) =>
        shifts.map((shift) => ({
          id:
            typeof shift.id === "string" && shift.id.startsWith("new-")
              ? null
              : shift.id,
          start_time: shift.start_time,
          floor_id: shift.floor_id || 0,
          break_time: shift.break_time || 0,
          type: shift.type,
          end_date: shift.end_date || shift.start_date,
          station_id: shift.station_id || 0,
          day_of_week: moment(shift.start_date).day(),
          employee_id: shift.employee_id,
          note: shift.note || "",
          start_date: shift.start_date,
          end_time: shift.end_time,
          deleted: shift.deleted || false,
        }))
    );

    dispatch(
      createEmployeeScheduleRequest(
        {
          employee_schedule_date_id: schedule.id,
          employee: formattedEmployeeData,
        },
        (err, data) => {
          if (err) {
            console.log("Error", err);
          } else {
            if (data && data.length > 0) {
              handleClose();
            }
          }
        }
      )
    );
  };

  const handleDisplayedEmployees = (employees) => {
    setDisplayedEmployees(employees);
  };

  const renderActiveView = (employeesSchedule) => {
    switch (activeView) {
      case "Week View":
        return (
          <WeekView
            setEmployeeShifts={setEmployeeShifts}
            schedule={schedule}
            employeesSchedule={employeesSchedule}
            schedules={schedules}
            roles={roles}
            setSchedules={setSchedules}
            selectedEmployees={selectedEmployees}
            employees={employees}
            onUpdateShift={handleUpdateShift}
            onDisplayedEmployeesChange={handleDisplayedEmployees}
            onTotalHoursChange={handleTotalHoursChange}
            onTotalCostsChange={handleTotalCostsChange}
            setEmployeeTable={setEmployeeTable}
            handlePasteShift={handlePasteShift}
            employeeTable={employeeTable}
          />
        );
      case "Day View":
        return (
          <DayView schedule={schedule} employeesSchedule={employeesSchedule} />
        );
      case "Team View":
        return (
          <TeamView schedule={schedule} employeesSchedule={employeesSchedule} />
        );
      case "Floor View":
        return (
          <FloorView
            schedule={schedule}
            employeesSchedule={employeesSchedule}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <StyledModalBox>
        {/* Header Section */}
        <Header>
          <HeaderTypography>Weekly Total</HeaderTypography>
          <ScheduleDetailTypography>
            Hours: {formatHoursAndMinutes(totalHoursAllEmployees)}
          </ScheduleDetailTypography>
          <ScheduleDetailTypography>
            Estimated Total Wages: ${totalCostsAllEmployees.toFixed(2)}
          </ScheduleDetailTypography>
        </Header>

        {/* Change View Section */}
        <ScheduleDateRange sx={{ marginBottom: 1 }}>
          {schedule.dateRange}
        </ScheduleDateRange>

        {/* View Switch Tabs and Buttons */}
        <ViewSwitchTabsContainer>
          <ViewSwitchTabs>
            <ViewSwitchButton
              active={activeView === "Week View" ? "true" : undefined}
              onClick={() => setActiveView("Week View")}
            >
              Week View
            </ViewSwitchButton>
            <TabSeparator />
            <ViewSwitchButton
              active={activeView === "Day View" ? "true" : undefined}
              onClick={() => setActiveView("Day View")}
            >
              Day View
            </ViewSwitchButton>
            <TabSeparator />
            <ViewSwitchButton
              active={activeView === "Team View" ? "true" : undefined}
              onClick={() => setActiveView("Team View")}
            >
              Team View
            </ViewSwitchButton>
            <TabSeparator />
            <ViewSwitchButton
              active={activeView === "Floor View" ? "true" : undefined}
              onClick={() => setActiveView("Floor View")}
            >
              Floor View
            </ViewSwitchButton>
          </ViewSwitchTabs>

          <RightActionButtons>
            <Button variant="contained" onClick={handleOpenRequestShiftModal}>
              Request Availability
            </Button>
            {requestAvailabilityModalOpen && (
              <RequestAvailabilityModal
                open={requestAvailabilityModalOpen}
                handleClose={handleCloseRequestAvailabilityModal}
                onSubmit={handleEmployeeSelect}
                employees={displayedEmployees}
                scheduleDateId={schedule.id}
              />
            )}
            <Button variant="contained" onClick={handleOpenSubmitShiftModal}>
              Submit Schedule
            </Button>
            {selectModalOpen && (
              <SubmitScheduleModal
                open={selectModalOpen}
                handleClose={handleCloseSelectModal}
                onSubmit={handleEmployeeSelect}
                employees={displayedEmployees}
                scheduleDateId={schedule.id}
              />
            )}
          </RightActionButtons>
        </ViewSwitchTabsContainer>

        {/* Filter Section */}
        <FilterSection>
          <FilterAll>
            <FilterLabel>Filter:</FilterLabel>
            <FilterOption onClick={handleAllEmployeeClick}>
              {selectedRole
                ? roles.find((role) => role.id === selectedRole)?.title
                : "All"}
            </FilterOption>

            <SelectRoleBox
              onChange={(e) => {
                const selectedValue = Number(e.target.value);
                if (selectedValue === 0) {
                  resetToAllEmployees();
                } else {
                  handleRoleSelect(selectedValue);
                }
              }}
              value={selectedRole || 0}
              className={showSelectBox ? "show" : ""}
            >
              <option value={0}>All</option>
              {roles.map((role) => (
                <option key={role.id} value={role.id}>
                  {role.title}
                </option>
              ))}
            </SelectRoleBox>
          </FilterAll>

          {/* Employees Carousel */}
          <EmployeesCarouselWrapper>
            <ArrowButton onClick={scrollLeft}>{"<"}</ArrowButton>
            <EmployeesSortContainer ref={carouselRef}>
              {filteredEmployees.map((employee) => (
                <EmployeeCapsule
                  key={employee.id}
                  onClick={() => {
                    handleEmployeeClick(employee);
                  }}
                >
                  <EmployeeIcon color={getEmployeeColor(employee)}>
                    {employee.initials}
                  </EmployeeIcon>
                  {employee.first_name} {employee.last_name}
                </EmployeeCapsule>
              ))}
            </EmployeesSortContainer>
            <ArrowButton onClick={scrollRight}>{">"}</ArrowButton>
          </EmployeesCarouselWrapper>
        </FilterSection>

        {/* Active View Section */}
        <Box
          mt={1}
          sx={{
            overflowY: "auto",
            maxHeight: "calc(100vh - 100px)",
            position: "relative",
          }}
        >
          {renderActiveView(employeesSchedule)}
        </Box>

        {/* Footer Section */}
        <Grid container justifyContent="flex-end" mt={4}>
          <FooterActionButtons>
            {displayedEmployees.length > 0 ? (
              <Button
                variant="contained"
                onClick={() => handleCopyShift(selectedShift)}
              >
                COPY
              </Button>
            ) : (
              <Button variant="contained" onClick={handlePasteShift}>
                PASTE
              </Button>
            )}

            <Button variant="contained" onClick={handleSave}>
              SAVE
            </Button>
          </FooterActionButtons>
        </Grid>
      </StyledModalBox>
    </Modal>
  );
};

export default ScheduleViewModal;
