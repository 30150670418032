import React from "react";
import { BOOKINGSTATUS } from "../../../constants/enum";

function ReservationButton(props) {
  return (
    <div
      onClick={() => props.onClickHandler(props.selectedBookingStatus)}
      className={
        props.selectedBookingStatus.value === BOOKINGSTATUS.seated
          ? "btn-control btn-control--seated"
          : props.selectedBookingStatus.value === BOOKINGSTATUS.upcoming
          ? "btn-control btn-control--revert"
          : props.selectedBookingStatus.value === BOOKINGSTATUS.finished
          ? "btn-control btn-control--finished"
          : "btn-control"
      }
    >
      {props.selectedBookingStatus.title}
    </div>
  );
}
export default ReservationButton;
