import React from "react";

const Timesheet = () => {
  return (
    <div>
      <p>Time</p>
      {/* Your component content goes here */}
    </div>
  );
};

export default Timesheet;
