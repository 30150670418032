// actions/bookingActions.js
export const getOrderListsRequest = (selectedFormatDate, callback) => ({
  type: "GET_ORDER_LISTS_REQUEST",
  selectedFormatDate,
  callback,
});
export const addOrderRequest = (params, callback) => ({
  type: "ADD_ORDER_REQUEST",
  params,
  callback,
});
