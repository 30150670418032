import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./Modules/User/pages/Login.jsx";
import FrontOfHouse from "./Modules/FrontOfHouse/pages/FrontOfHouse.jsx";
import TeamIndex from "./Modules/Team/TeamIndex.jsx";
import store from "./store/configStore";
import { Provider as ReduxProvider } from "react-redux";

const App = () => {
  return (
    <Router>
      <ReduxProvider store={store}>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/front-of-house" element={<FrontOfHouse />} />
          <Route path="/team/*" element={<TeamIndex />} />
        </Routes>
      </ReduxProvider>
    </Router>
  );
};

export default App;
