// actions/employeeActions.js
export const getEmployeesRequest = (callback) => ({
  type: "GET_EMPLOYEES_REQUEST",
  callback,
});

export const addEmployeeRequest = (employee, callback) => ({
  type: "ADD_EMPLOYEE_REQUEST",
  employee,
  callback,
});

export const deleteEmployeeRequest = (employee, callback) => ({
  type: "DELETE_EMPLOYEE_REQUEST",
  employee,
  callback,
});

export const updateEmployeeRequest = (employee, callback) => ({
  type: "UPDATE_EMPLOYEE_REQUEST",
  employee,
  callback,
});

export const uploadEmployeePhotoRequest = (params, fileObject, callback) => ({
  type: "UPLOAD_EMPLOYEE_PHOTO_REQUEST",
  params,
  fileObject,
  callback,
});

export const getEmployeementContractRequest = (callback) => ({
  type: "GET_EMPLOYEEMENT_CONTRACT_REQUEST",
  callback,
});

export const addEmployeeContractRequest = (contract, callback) => ({
  type: "ADD_EMPLOYEE_CONTRACT_REQUEST",
  contract,
  callback,
});

export const getEmployeeRolesRequest = (callback) => ({
  type: "GET_EMPLOYEE_ROLES_REQUEST",
  callback,
});
export const addEmployeeRoleRequest = (role, callback) => ({
  type: "ADD_EMPLOYEE_ROLE_REQUEST",
  role,
  callback,
});
