import moment from "moment";
import React from "react";
import { Box, IconButton } from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
const commonStyle = {
  fontSize: 16,
};
function BookingCalendarArrow(props) {
  const nextDate = () => {
    props.setSelectedDate(
      moment(props.selectedDate).add(1, "day").format("yyyy-MM-DD"),
      "next"
    );
    if (
      props.setDateChangeHandler &&
      props.setSelectedDateChanged !== undefined
    ) {
      props.setSelectedDateChanged(true);
      props.setDateChangeHandler(true);
    }
  };

  const prevDate = () => {
    props.setSelectedDate(
      moment(props.selectedDate).subtract(1, "day").format("yyyy-MM-DD"),
      "prev"
    );
    if (
      props.setDateChangeHandler &&
      props.setSelectedDateChanged !== undefined
    ) {
      props.setSelectedDateChanged(true);
      props.setDateChangeHandler(true);
    }
  };

  return (
    <Box>
      {props.arrowType === "left" ? (
        <IconButton onClick={prevDate} style={{ cursor: "pointer" }}>
          <ArrowBackIos
            sx={{ color: "#bcbcbc", fontSize: commonStyle.fontSize }}
          />
        </IconButton>
      ) : (
        <IconButton
          onClick={nextDate}
          style={{ commonStyle, cursor: "pointer" }}
        >
          <ArrowForwardIos
            sx={{ color: "#bcbcbc", fontSize: commonStyle.fontSize }}
          />
        </IconButton>
      )}
    </Box>
  );
}

export default BookingCalendarArrow;
